import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

type props = {
  control: Control<any>
  name: string
  label: string
}
const CheckboxController = ({control, name, label}: props) => {
  const {t} = useTranslation()
  const checked = useWatch({
    control: control,
    name: name
  })
  return (
    <Controller control={control}
                name={name}
                render={({field: {onChange}}) => {
                  return (
                    <FormControlLabel control={<Checkbox checked={!!checked} onChange={onChange}/>} label={`${t(label)}`}/>
                  )
                }}
    />

  );
}

export default CheckboxController;