import { useMutation, useQueryClient } from 'react-query'
import { putMemberDetails, putUpdateMemberRole } from 'api/private/members'
import { UpdateMemberRoleType, UpdateMemberType } from 'types/Members'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUpdateMemberRole = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {mutate: UpdateMemberRole} = useMutation((payload: UpdateMemberRoleType) => (putUpdateMemberRole(payload)),
    {
      onSuccess: async (data, payload) => {
        await queryClient.resetQueries(['members']).then(() => {
          queryClient.resetQueries(['member', payload.id])
        })
        toast.success(`${t('member_updated_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('member_updated_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {UpdateMemberRole}
}