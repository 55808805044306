import React, {useState} from 'react'
import {
    Alert,
    Button,
    Rating,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material'
import {useChannels} from 'hooks/serviceProvider/useChannels'
import googleLogo from 'images/google.png'
import {useTranslation} from 'react-i18next'
import {InfoOutlined} from '@mui/icons-material'
import {DisableGoogleChannelModal} from "../DisableGoogleChannelModal";
import {v4 as uuidv4} from "uuid";


export const NetworkStatus = () => {
    const {t} = useTranslation();
    const {Channels} = useChannels()
    const [openDisableGoogleDialog, setOpenDisableGoogleDialog] = useState<boolean>(false)

    const getSeverityIconByStatus = (status: string) => {
        if (status === 'ACTIVE') {
            return 'success'
        } else if (status == 'REVIEW') {
            return 'info'
        }
        return 'error'
    }
    return (

        <TableContainer>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('network')}</TableCell>
                        <TableCell>{t('status')}</TableCell>
                        <TableCell>{t('rating')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Channels?.map(channel => <TableRow key={uuidv4()}>
                            <TableCell>
                                {channel.channel === 'GOOGLE' ?
                                    <Stack direction={'row'}>
                                        <img src={googleLogo} width={85} style={{marginLeft: 10}} alt="Google Logo"/>
                                        <Tooltip title={
                                            <Typography padding={1} variant={'body2'} dangerouslySetInnerHTML={
                                                {__html: t('google_tooltip_text_one', {interpolation: {escapeValue: false}})}
                                            }/>
                                        } placement="top" enterTouchDelay={0}>
                                            <InfoOutlined color={'info'} sx={{marginLeft: 2, cursor: 'pointer'}}/>
                                        </Tooltip>
                                    </Stack> :
                                    <img src={'/textlogo.png'} width={100} alt="Skiper Marketplace Logo"/>}
                            </TableCell>
                            <TableCell>
                                <Alert severity={getSeverityIconByStatus(channel.status)}>
                                    {t(channel.status)}
                                </Alert>
                            </TableCell>
                            <TableCell>{channel.channel === 'SKIPER_MARKETPLACE' &&
                                <Rating value={channel.rating} readOnly/>}</TableCell>
                            <TableCell>
                                {channel.channel === 'GOOGLE' && channel.status === 'ACTIVE' &&
                                    <Button onClick={() => setOpenDisableGoogleDialog(true)} variant={'alarmButton'}
                                            color={'error'} fullWidth>{t('disable')}</Button>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <DisableGoogleChannelModal open={openDisableGoogleDialog} setOpen={setOpenDisableGoogleDialog}/>
        </TableContainer>
    )
}