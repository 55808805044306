import { privateAxiosClient } from 'config/AxiosClients'
import {
  CreateMemberType,
  UpdateBankingDetailsType,
  MembersStateType,
  UpdateMemberConsentDetails,
  UpdateMemberRoleType,
  UpdateMemberType,
  UpdatePasswordType
} from 'types/Members'


export const getMembers = async (serviceProviderId: number | null, membersFilter: MembersStateType) => {
  const params = {
    serviceProviderId: serviceProviderId,
    page: membersFilter.page,
    size: membersFilter.perPage
  }

  return await privateAxiosClient.get(`/members`, {params}).then(response => response.data)
}

export const getMember = async (id = 1) => await (
  privateAxiosClient.get(`/members/${id}`).then(response => response.data)
)

export const postMember = (payload: CreateMemberType) => (
  privateAxiosClient.post(`/members`, payload)
)

export const putMemberDetails = (payload: UpdateMemberType) => {
  return privateAxiosClient.put(`/members/${payload.id}/update-details`, payload)
}


export const createStripeSession = (memberId: number | undefined) => {
  return privateAxiosClient.post(`/stripe/${memberId}/create-session`)
}

export const createStripeCustomerPortalSession = (memberId: number | undefined) => {
  return privateAxiosClient.post(`/stripe/${memberId}/create-customer-portal-session`)
}

export const putMemberConsentDetails = (payload: UpdateMemberConsentDetails) => {
  return privateAxiosClient.put(`/members/${payload.id}/consent-details`, payload)
}

export const putUpdateMemberRole = (payload: UpdateMemberRoleType) => {
  return privateAxiosClient.put(`/members/${payload.id}/change-role`, payload)
}

export const putUpdatePassword = (payload: UpdatePasswordType) => {
  return privateAxiosClient.put(`/members/${payload.id}/change-password`, payload)
}

export const deleteMember = (id: number) => (
  privateAxiosClient.delete(`/members/${id}/delete`)
)

export const getBankingDetails = (memberId: number | undefined) => {
  return privateAxiosClient.get(`/members/${memberId}/banking-details`).then(response => response.data)
}

export const getStripeBookingPriceConfiguration = (memberId: number | undefined) => {
  return privateAxiosClient.get(`/members/${memberId}/stripe-booking-price-configuration`).then(response => response.data)
}

export const postUpdateBankingDetails = (payload: UpdateBankingDetailsType, memberId: number | undefined) => {
  return privateAxiosClient.post(`/members/${memberId}/banking-details`, payload)
}