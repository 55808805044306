import React, {useEffect, useState} from "react"
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    Table, TableBody, TableCell, TableHead, TableRow,
    TextField, Tooltip,
    Typography
} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next'
import {BookingType} from 'types/Bookings'
import {useUpdateBookingDetails} from 'hooks/bookings/useUpdateBookingDetails'
import {DATE_TIME_FORMAT, ISO_DATE_FORMAT, TIME_FORMAT} from "config/const";
import moment from "moment";
import {bookingSeats, bookingsTablesType, defaultTimeOptions, tableVipOptions} from "config/Options";
import {useServiceProvider} from "hooks/useServiceProvider";
import {useAvailableTimeslots} from "plugin/useAvailableTimeslots";
import {OptionsType} from "types/General";
import {getDynamicTimeOptionsByDate} from "config/Utils";
import {CustomDatePicker} from 'components/CustomDatePicker'
import {useFindAvailableTables} from "../../hooks/restaurant/useFindAvailableTables";
import {RestaurantTables} from "../../types/Restaurant";
import {DoneOutlined} from "@mui/icons-material";
import {toast} from "react-toastify";


type props = {
    openEditBookingDetails: any
    setOpenEditBookingDetails: any
    booking: BookingType
}

export const EditBookingDetailsDrawer = ({openEditBookingDetails, setOpenEditBookingDetails, booking}: props) => {
    const {t} = useTranslation();

    const {EditBookingDetails} = useUpdateBookingDetails()

    const toggleDrawer = () => {
        setComment(booking?.comment)
        setNumberOfGuests({value: booking.numberOfGuests, label: booking.numberOfGuests + ''})
        setStartDate(moment(booking?.bookingTime).format(ISO_DATE_FORMAT))
        setTableType({value: booking.tableType, label: booking.tableType})
        setDynamicTimeOptions(defaultTimeOptions)
        setSelectedTimes({
            value: moment(booking?.bookingTime).format(TIME_FORMAT),
            label: moment(booking?.bookingTime).format(TIME_FORMAT)
        })
        setOpenEditBookingDetails(!openEditBookingDetails)
    }

    const submitHandler = () => {
        if (selectedTableId == undefined) {
            toast.error(`${t('table_not_found')}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            EditBookingDetails({
                id: booking?.id,
                comment: comment,
                bookingTime: `${startDate}T${selectedTimes.value}`,
                numberOfGuests: numberOfGuests.value as number,
                tableType: tableType.value as string,
                vip: vip && vip.value ? vip.value as string : undefined,
                tableId: selectedTableId.value as number
            })
            setOpenEditBookingDetails(!openEditBookingDetails)
        }
    }

    const {serviceProvider} = useServiceProvider()

    const {AvailableTimeslots} = useAvailableTimeslots(serviceProvider?.id)
    const [startDate, setStartDate] = useState(moment(booking?.bookingTime).format(ISO_DATE_FORMAT))
    const [tableType, setTableType] = useState<OptionsType>({value: booking.tableType, label: booking.tableType})
    const [vip, setVip] = useState<OptionsType | null>({
        value: booking.vip ? 'VIP' : 'STANDARD',
        label: booking.vip ? 'VIP' : 'STANDARD'
    })
    const [numberOfGuests, setNumberOfGuests] = useState<OptionsType>({
        value: booking.numberOfGuests,
        label: booking.numberOfGuests + ''
    })
    const [comment, setComment] = useState(booking?.comment)
    const [dynamicTimeOptions, setDynamicTimeOptions] = useState<OptionsType[]>(defaultTimeOptions)
    const [selectedTimes, setSelectedTimes] = useState<OptionsType>({
        value: moment(booking?.bookingTime).format(TIME_FORMAT),
        label: moment(booking?.bookingTime).format(TIME_FORMAT)
    })

    useEffect(() => {
        if (startDate && AvailableTimeslots) {
            const timeOptions = getDynamicTimeOptionsByDate(startDate, AvailableTimeslots)
            setDynamicTimeOptions(timeOptions)
        }
    }, [startDate, AvailableTimeslots])

    const {availableTables} = useFindAvailableTables(`${startDate}T${selectedTimes.value}`, numberOfGuests.value as number, tableType.value as string, 'DIRECT', vip?.value as string, booking.id)

    const convertAvailableTablesToOptions = (availableTables: RestaurantTables | undefined) => {
        const availableTableOptions: OptionsType[] = []
        if (availableTables && availableTables.content && availableTables.content.length > 0) {
            availableTables.content.forEach(a => {
                if (a.number) {
                    availableTableOptions.push(
                        {value: a.id, label: a.number + ' - ' + t(a.type)}
                    )
                }
            })
        }
        return availableTableOptions
    }

    const [availableTableOptions, setAvailableTableOptions] = useState<OptionsType[]>(convertAvailableTablesToOptions(availableTables))
    const defaultSelectedTableId = {value: booking.tableId, label: booking.tableNumber + ' - ' + t(booking.tableType)}
    const [selectedTableId, setSelectedTableId] = useState<OptionsType | null>(defaultSelectedTableId)

    useEffect(() => {
        setAvailableTableOptions(convertAvailableTablesToOptions(availableTables))
        if (availableTables && availableTables.content && availableTables.content.length > 0) {
            // If currently selected TableId does not exist in newly fetched availableTables we will just pick first one.
            if (!availableTables.content.find(a => a.id == booking.tableId)) {
                setSelectedTableId({
                    value: availableTables.content[0].id,
                    label: availableTables.content[0].number + ' - ' + t(availableTables.content[0].type)
                })
            } else {
                setSelectedTableId({
                    value: booking.tableId,
                    label: booking.tableNumber + ' - ' + t(booking.tableType)
                })
            }
        } else {
            setSelectedTableId(null)
        }
    }, [availableTables])

    return (
        <Drawer open={openEditBookingDetails} onClose={toggleDrawer}>
            <Container maxWidth={'sm'} sx={{paddingY: 2}}>
                <Table size={'medium'}>
                    <TableHead>
                        <TableRow color={'primary'}>
                            <TableCell padding={"checkbox"} colSpan={2}>
                                <Typography variant={'h6'}>{t(`your_booking_details`)}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{paddingLeft: 0}}>
                                <Typography variant={'body2'}><strong>{t('booking_start_time')}:</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant={'body2'}> {moment(booking.bookingTime).format(DATE_TIME_FORMAT)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{paddingLeft: 0}}>
                                <Typography variant={'body2'}><strong>{t('number_of_guests')}:</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'body2'}> {booking.numberOfGuests}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{paddingLeft: 0}}>
                                <Typography variant={'body2'}><strong>{t('table_number')}:</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'body2'}> {booking.tableNumber}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{paddingLeft: 0}}>
                                <Typography variant={'body2'}><strong>{t('type')}:</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'body2'}> {t(booking.tableType)}</Typography>
                            </TableCell>
                        </TableRow>
                        {booking.vip &&
                            <TableRow>
                                <TableCell sx={{paddingLeft: 0}}>
                                    <Typography variant={'body2'}><strong>{t('vip')}:</strong></Typography>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={`${t('vip_tooltip')}`} placement="top">
                                        <DoneOutlined color={'success'}/>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                <Stack direction={'column'} my={2}>
                    <Typography variant={'h6'} my={2}>{t('update_booking')}</Typography>
                    <Grid container spacing={2} mb={2}>
                        <Grid item>
                            <CustomDatePicker label={t('date')}
                                              date={startDate}
                                              disablePast={true}
                                              onChange={(newValue) => setStartDate(moment(newValue).format(ISO_DATE_FORMAT))}
                            />
                        </Grid>
                        <Grid item>
                            <Box display={'inline-block'} mr={2}>
                                {selectedTimes && <Autocomplete
                                    options={dynamicTimeOptions}
                                    defaultValue={selectedTimes}
                                    value={selectedTimes}
                                    onChange={(_, data) => {
                                        setSelectedTimes(data)
                                    }}
                                    disableClearable={true}
                                    renderInput={(params =>
                                        <TextField
                                            {...params}
                                            variant={'outlined'}
                                            size={'small'}
                                            sx={{width: 200}}
                                            label={t(`${'time'}`)}
                                            autoComplete={'off'}
                                        />)}/>}

                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <Grid item>
                            <Autocomplete
                                options={bookingSeats}
                                defaultValue={numberOfGuests || null}
                                value={numberOfGuests || null}
                                onChange={(_, data) => {
                                    if (data) {
                                        setNumberOfGuests(data)
                                    }
                                }}
                                disableClearable={true}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={option => option.value === numberOfGuests?.value}
                                renderInput={(params =>
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        size={'small'}
                                        sx={{width: 200}}
                                        label={t('number_of_guests')}
                                        autoComplete={'off'}
                                    />)}/>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={bookingsTablesType}
                                defaultValue={tableType || null}
                                value={tableType || null}
                                onChange={(_, data) => {
                                    if (data) {
                                        setTableType(data)
                                    }
                                }}
                                disableClearable={true}
                                getOptionLabel={(option) => t(option.label)}
                                isOptionEqualToValue={option => option.value === tableType?.value}
                                renderInput={(params =>
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        size={'small'}
                                        sx={{width: 200}}
                                        label={t('type')}
                                        autoComplete={'off'}
                                    />)}/>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={tableVipOptions}
                                defaultValue={vip || null}
                                value={vip || null}
                                onChange={(_, data) => {
                                    setVip(data)
                                }}
                                disableClearable={false}
                                getOptionLabel={(option) => t(option.label)}
                                isOptionEqualToValue={option => option.value === vip?.value}
                                renderInput={(params =>
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        size={'small'}
                                        sx={{width: 200}}
                                        label={t('vip')}
                                        autoComplete={'off'}
                                    />)}/>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={availableTableOptions}
                                defaultValue={selectedTableId || undefined}
                                value={selectedTableId || undefined}
                                onChange={(_, data) => {
                                    if (data) {
                                        setSelectedTableId(data)
                                    }
                                }}
                                disableClearable={true}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={option => option.value === selectedTableId?.value}
                                renderInput={(params =>
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        size={'small'}
                                        sx={{width: 200}}
                                        label={t('table_number')}
                                        autoComplete={'off'}
                                    />)}/>
                        </Grid>
                    </Grid>
                    <TextField
                        style={{width: '100%'}}
                        variant={'outlined'}
                        size={'medium'}
                        value={t(comment)}
                        multiline={true}
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        rows={3}
                        onChange={(data) => setComment(data.target.value)}
                        type={'text'}
                        defaultValue={comment ? t(comment) : ''}
                        label={t('comment_optional')}
                        fullWidth
                    />
                    <Button onClick={submitHandler} variant={'contained'} sx={{mt: 2}}
                            type={'submit'}>{t('update')}</Button>
                </Stack>
            </Container>
        </Drawer>
    )
}