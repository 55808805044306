import { useMutation, useQueryClient } from 'react-query'
import { putPublicHolidays } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { PUBLIC_HOLIDAYS } from 'context/PublicHolidaysReducer'
import PublicHolidaysContext from 'context/PublicHolidaysContext'
import moment from 'moment/moment'
import { DATE_FORMAT } from 'config/const'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const useUpdatePublicHolidays = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const {dispatch} = useContext(PublicHolidaysContext)
  const {mutate: UpdatePublicHolidays} = useMutation((payload: number[]) => (putPublicHolidays(defaultProvider?.id, payload)), {
    onSuccess: data => {
      data.data.sort((a: any, b: any) =>
        Date.parse(moment(a.date).format(DATE_FORMAT).split("-").reverse().join("-"))
        -
        Date.parse(moment(b.date).format(DATE_FORMAT).split("-").reverse().join("-")))
      dispatch({type: PUBLIC_HOLIDAYS, payload: data.data})
      queryClient.resetQueries(['public-holidays'])
      toast.success(`${t('public_holidays_update_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdatePublicHolidays}
}