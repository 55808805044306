import {useQuery} from 'react-query'
import {getServiceProviderPluginDetails} from 'api/public/publicapi'
import {ServiceProviderPluginDetails} from "../types/ServiceProvider";
import {ISO_DATE_FORMAT} from "../config/const";
import {Moment} from "moment";


export const useServiceProviderPluginDetails = (serviceProviderId: number | undefined, startDate: Moment,
                                                channel: string) => {

    const payload = {
        startDate: startDate.startOf('month').format(ISO_DATE_FORMAT),
        endDate: startDate.endOf('month').format(ISO_DATE_FORMAT),
        channel: channel
    }
    const {
        data: ServiceProviderPluginDetails,
        refetch,
        error
    } = useQuery<ServiceProviderPluginDetails>(['service-provider-plugin-details', serviceProviderId, payload],
        async () => await getServiceProviderPluginDetails(serviceProviderId, payload),
        {
            enabled: !!serviceProviderId,
            onSuccess: (data => {
                return data
            })
        }
    )

    return {ServiceProviderPluginDetails, error, refetch}
}