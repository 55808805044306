import React, {useMemo, useState} from "react"
import {Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Stack, Typography} from '@mui/material'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {RegisterInputType} from 'types/Auth'
import {registrationStyles} from "theme/Registration.styles";
import {useRegistration} from 'hooks/useRegistration'
import {useTranslation} from 'react-i18next'
import {registrationFormSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {TextFieldController} from 'components/form/TextFieldController'
import {Link} from 'react-router-dom'
// @ts-ignore
import portalLogo from "../portal_logo.svg";
import ReCaptchaV3 from "../components/ReCaptchaV3";


export const Registration = () => {
    const classes = registrationStyles()
    const schema = useMemo(() => registrationFormSchema(), [i18n.language]);

    const {handleSubmit, control, formState: {errors}} = useForm<RegisterInputType>({resolver: yupResolver(schema)})

    const {Registration} = useRegistration()
    const {t} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const [values, setValues] = useState<RegisterInputType | null>(null)

    const registerSubmitHandler: SubmitHandler<RegisterInputType> = (values: RegisterInputType) => {
        values.languageCode = getCurrentLng().toUpperCase()
        values.email = values.email.toLowerCase();
        setValues({...values})
    }

    return (

        <Grid container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              minHeight="100vh">

            <Box className={classes.registrationWrapper}>
                <Box component="img" alt="Skiper Portal Logo" src={portalLogo} style={{width: 200, marginBottom: 20}}/>
                <Typography variant={'h4'} className={classes.registrationText} m={3}><b>{t('registration')}</b></Typography>
                <Box component="form" onSubmit={handleSubmit(registerSubmitHandler)} noValidate className={classes.formWrapper}>
                    <Stack direction={'column'}>
                        <TextFieldController control={control} name={'firstName'} label={'first_name'}/>

                        <TextFieldController control={control} name={'lastName'} label={'last_name'}/>

                        <TextFieldController control={control} name={'email'} label={'email'}/>

                        <TextFieldController control={control} name={'password'} label={'password'} type="password"/>

                        <Controller name={'legalAgreement'} defaultValue={false} control={control}
                                    render={({field: {onChange}, fieldState: {error}}) =>
                                        <FormControl error={!!error}>
                                            <FormControlLabel className={classes.legalAgreement}
                                                              control={<Checkbox onChange={onChange} color="primary"/>}
                                                              label={
                                                                  <>
                                                                      <span>{t('agree_on_legal_terms_and_conditions_1')}</span>
                                                                      <Link target="_blank"
                                                                            to={'/data-privacy?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_2')}</Link>
                                                                      <span>{t('agree_on_legal_terms_and_conditions_3')}</span>
                                                                      <Link target="_blank"
                                                                            to={'/terms-and-conditions-service-provider?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_4')}</Link>
                                                                  </>
                                                              }/>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    }
                        />
                        <ReCaptchaV3 values={values} mutationFN={Registration}/>
                        <Button type="submit" fullWidth variant="contained" className={classes.registrationButton}>
                            {t('register')}
                        </Button>
                    </Stack>
                </Box>
            </Box>

        </Grid>
    )
}