import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {postDisableServiceProviderChannel, putDesignTemplate} from "../../api/private/serviceProviders";
import {useServiceProvider} from "../useServiceProvider";

export const useDisableServiceProviderChannel = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {serviceProvider} = useServiceProvider()
    const {mutate: DisableServiceProviderChannel} = useMutation((payload: any) => (postDisableServiceProviderChannel(serviceProvider?.id, payload)),
        {
            onSuccess: async (data, payload) => {
                queryClient.resetQueries(['service-provider', serviceProvider?.id])
                toast.success(`${t('service_provider_disable_channel_success')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            },
            onError: async () => {
                toast.error(`${t('service_provider_disable_channel_error')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })

    return {DisableServiceProviderChannel}
}