import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const minWidth = '64px'

export const navigationStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'transparent linear-gradient(180deg, hsla(216, 40%, 65%, 1) 0%, hsla(180, 48%, 72%, 1) 100%) 0% 0% no-repeat padding-box',
  },
  drawer: {},
  link: {
    textDecoration: 'none',
    color: '#242B43',
  },
  active: {
    borderLeft: '6px solid #FFE981 !important',
  },
  hover: {
    '&:hover': {
      borderLeft: '6px solid #FFE981',
    }
  },
  logoLg: {
    height: 64,
    position: 'relative'
  },
  logoSm: {
    height: 64,
    display: 'block'
  }

}))
