import {useMutation} from 'react-query'
import {feedbackUnauthorized} from "../api/public/publicapi";
import {FeedbackType} from "../types/General";

export const useFeedbackUnauthorized = (bookingId: number | undefined) => {
    const {mutate: UseFeedback, isSuccess} = useMutation((payload: FeedbackType) => {
            return feedbackUnauthorized(payload, bookingId);
        }, {
            // onSuccess: () => {
            //      window.location.reload();
            // }
        }
    )

    return {UseFeedback, isSuccess}
}