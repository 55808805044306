import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Alert, Box, Button, Grid, Stack, TextField, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {ColorPickerField} from 'components/form/ColorPickerField'
import {PluginDemo} from 'components/settings/PluginDemo'
import {useServiceProviderStyles} from 'hooks/useServiceProviderStyles'
import {useUpdateServiceProviderStyle} from 'hooks/useUpdateServiceProviderStyle'
import {PLUGIN_ICON_COLOR, PLUGIN_MAIN_COLOR, PLUGIN_SECONDARY_COLOR} from 'theme/Plugin.styles'
import {TextFieldController} from 'components/form/TextFieldController'
import {serviceProviderWebsiteSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {useUpdateServiceProvider} from 'hooks/useUpdateServiceProvider'
import {HorizontalPluginDemo} from "./HorizontalPluginDemo";

export const GeneratePlugin = () => {
    const {t} = useTranslation();
    const {defaultProvider} = useContext(ServiceProviderContext)
    const {serviceProviderStyles} = useServiceProviderStyles()
    const {UpdateServiceProviderStyle} = useUpdateServiceProviderStyle()
    const [mainColor, setMainColor] = useState<string>(PLUGIN_MAIN_COLOR)
    const [secondaryColor, setSecondaryColor] = useState<string>(PLUGIN_SECONDARY_COLOR)
    const [iconColor, setIconColor] = useState<string>(PLUGIN_ICON_COLOR)
    const [showGeneratedCode, setShowGeneratedCode] = useState<boolean>(false)
    const [copyText, setCopyText] = useState<string>(t("copy"))
    const copiedText = t("copied")
    const {UpdateServiceProvider} = useUpdateServiceProvider()

    useEffect(() => {
        handleResetClick()
    }, [serviceProviderStyles])

    const handleOnClick = (values: any) => {
        UpdateServiceProviderStyle({
            serviceProviderId: defaultProvider?.id,
            mainColor: mainColor,
            secondaryColor: secondaryColor,
            iconColor: iconColor
        }, {
            onSuccess: (data) => {
                UpdateServiceProvider({...defaultProvider, websiteUrl: values.websiteUrl})
            }
        })
        setShowGeneratedCode(true)
    }

    const handleResetClick = () => {
        if (serviceProviderStyles && serviceProviderStyles.mainColor) {
            setMainColor(serviceProviderStyles.mainColor)
            setSecondaryColor(serviceProviderStyles.secondaryColor)
            setIconColor(serviceProviderStyles.iconColor)
        } else {
            setMainColor(PLUGIN_MAIN_COLOR)
            setSecondaryColor(PLUGIN_SECONDARY_COLOR)
            setIconColor(PLUGIN_ICON_COLOR)
        }
    }

    const generatedCode = `<iframe style="border: 0;" height="570px" width="370px" src="https://www.portal.skiper.io/booking-plugin/${defaultProvider?.id}"></iframe>`
    const generatedHorizontalCode = `<style>.skiper_iframe {height: 185px;}@media(max-width: 600px){.skiper_iframe{height: 260px;}}</style>
<iframe style="border: 0;" width="100%" class="skiper_iframe" src="https://www.portal.skiper.io/booking-plugin-2/${defaultProvider?.id}"></iframe>`
    const handleOnClickCopy = () => {
        navigator.clipboard.writeText(generatedCode)
        setCopyText(copiedText)
    }

    const handleOnClickCopyHorizontal = () => {
        navigator.clipboard.writeText(generatedHorizontalCode)
        setCopyText(copiedText)
    }

    const schema = useMemo(() => serviceProviderWebsiteSchema(), [i18n.language]);

    const {handleSubmit, control, reset} = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {websiteUrl: defaultProvider?.websiteUrl}
    })

    useEffect(() => {
        reset({websiteUrl: defaultProvider?.websiteUrl});
        setShowGeneratedCode(false)
        setCopyText(t("copy"))
    }, [defaultProvider]);

    return (
        <>
            <Typography variant={'h5'} textAlign={"center"} mt={3} mb={3}>{t("plugin_customization")}</Typography>
            {defaultProvider ? (
                <Grid container>
                    <Grid item xs={6}>

                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Stack direction="column" alignItems={"center"} spacing={2}>
                                    <ColorPickerField color={mainColor} setColor={setMainColor} label={"Main"}/>
                                    <ColorPickerField color={secondaryColor} setColor={setSecondaryColor}
                                                      label={"Secondary"}/>
                                    <ColorPickerField color={iconColor} setColor={setIconColor} label={"Icons"}/>
                                    <form onSubmit={handleSubmit(handleOnClick)}>
                                        <Box style={{width: 300}}>
                                            <TextFieldController control={control} name={'websiteUrl'}
                                                                 label={'service_provider_website'}/>
                                        </Box>
                                        <Stack style={{marginTop: 20, marginBottom: 10}} direction="row" spacing={2}>
                                            <Button type="submit" fullWidth variant="contained">
                                                {t("submit")}
                                            </Button>
                                            <Button onClick={handleResetClick} fullWidth variant="contained">
                                                {t("reset")}
                                            </Button>
                                        </Stack>
                                    </form>
                                    {showGeneratedCode &&
                                        <>
                                            <Alert sx={{mt: 2}} severity={'info'}>{t('copy_to_iframe_1')}</Alert>
                                            <Button style={{marginBottom: 10, width: 150}}
                                                    type="submit" variant="contained"
                                                    onClick={handleOnClickCopy}>
                                                {copyText}
                                            </Button>
                                        </>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12} rowSpacing={10}>
                                {showGeneratedCode &&
                                    <TextField
                                        variant={'outlined'}
                                        size={'medium'}
                                        disabled={true}
                                        multiline={true}
                                        rows={3}
                                        defaultValue={generatedCode}
                                        label={t("generated_code")}
                                        fullWidth
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={{padding: "10px"}}>

                        <Stack direction="column" justifyContent="center">
                            <Typography variant={'h6'} textAlign={"left"} mt={3}
                                        mb={3}>{t("plugin_vertical")}</Typography>
                            <PluginDemo mainColor={mainColor} secondaryColor={secondaryColor} iconColor={iconColor}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 10}}>
                        <Stack direction="column" justifyContent="center">
                            <Typography variant={'h6'} textAlign={"center"} mt={3}
                                        mb={3}>{t("plugin_horizontal")}</Typography>
                            <HorizontalPluginDemo mainColor={mainColor} secondaryColor={secondaryColor}
                                                  iconColor={iconColor}/>
                        </Stack>
                        <Stack direction="column" alignItems={"center"} spacing={2}>
                            {showGeneratedCode &&
                                <>
                                    <Alert sx={{mt: 2}} severity={'info'}>{t('copy_to_iframe_2')}</Alert>
                                    <Button style={{marginBottom: 10, width: 150}}
                                            type="submit" variant="contained"
                                            onClick={handleOnClickCopyHorizontal}>
                                        {copyText}
                                    </Button>
                                    <TextField
                                        variant={'outlined'}
                                        size={'medium'}
                                        disabled={true}
                                        multiline={true}
                                        rows={3}
                                        defaultValue={generatedHorizontalCode}
                                        label={t("generated_code")}
                                        fullWidth
                                    />
                                </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </>
    )
}