import { RestaurantTablesAction, RestaurantTablesStateType } from 'types/Restaurant'


export const SELECT_STATUS_FILTER_TABLES = 'SELECT_STATUS_FILTER_TABLES'
export const SELECT_TYPE_FILTER_TABLES = 'SELECT_TYPE_FILTER_TABLES'
export const SELECT_VIP_FILTER_TABLES = 'SELECT_VIP_FILTER_TABLES'
export const SELECT_PAGE_FILTER_TABLES = 'SELECT_PAGE_FILTER_TABLES'
export const SELECT_PAGE_SIZE_FILTER_TABLES = 'SELECT_PAGE_SIZE_FILTER_TABLES'


export const INITIAL_RESTAURANT_TABLES_STATE = {
  status: 'ACTIVATED',
  type: undefined,
  vip: undefined,
  page: 0,
  perPage: 10
}

export const RestaurantTablesReducer = (state: RestaurantTablesStateType = INITIAL_RESTAURANT_TABLES_STATE, action: RestaurantTablesAction) => {
  switch (action.type) {
    case SELECT_STATUS_FILTER_TABLES:
      return {
        ...state,
        status: action.payload
      }
    case SELECT_TYPE_FILTER_TABLES:
      return {
        ...state,
        type: action.payload
      }
    case SELECT_VIP_FILTER_TABLES:
      return {
        ...state,
        vip: action.payload
      }
    case SELECT_PAGE_FILTER_TABLES:
      return {
        ...state,
        page: action.payload
      }
    case SELECT_PAGE_SIZE_FILTER_TABLES:
      return {
        ...state,
        perPage: action.payload
      }
    default:
      return state;
  }
}