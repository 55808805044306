import { OpeningHoursAction, OpeningHoursDayType } from 'types/ServiceProvider'


export const OPENING_HOURS = 'OPENING_HOURS'


export const INITIAL_OPENING_HOURS_STATE = {
  days: [
    {dayOfWeek: 'MONDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'TUESDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'WEDNESDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'THURSDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'FRIDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'SATURDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'},
    {dayOfWeek: 'SUNDAY', startTime: '10:00', endTime: '23:00', breakStartTime: '14:00', breakEndTime: '18:00'}
  ] as OpeningHoursDayType[]
}

export const OpeningHoursReducer = (state = INITIAL_OPENING_HOURS_STATE, action: OpeningHoursAction) => {
  switch (action.type) {
    case OPENING_HOURS:
      return {
        ...state,
        days: action.payload
      }
    default:
      return state;
  }
}