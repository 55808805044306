import { useMutation, useQueryClient } from 'react-query'
import { deleteMember } from 'api/private/members'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useDeleteMember = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {mutate: deactivateMember} = useMutation((id: number) => (deleteMember(id)),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(['members'])
        toast.success(`${t('member_delete_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('member_delete_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {deactivateMember: deactivateMember}
}