import {makeStyles} from '@mui/styles'

export const topBarUnauthorizedStyles = makeStyles({
    toolBar: {
        backgroundColor: 'white'
    },
    languageSwitch: {
        height: '38px'
    },
    divider: {
        color: '#A4AFB7',
        flexGrow: 2
    },
    logo: {
        height: 95,
        cursor: 'pointer'
    }
})
