import React, { createContext, useReducer } from 'react'
import { INITIAL_MEMBERS_STATE, MembersReducer } from 'context/members/MembersReducer'
import { MembersAction, MembersStateType } from 'types/Members'

type MembersContextType = {
  membersFilter: MembersStateType
  dispatch: (action: MembersAction) => void
}


const MembersContext = createContext<MembersContextType>({} as MembersContextType)

export const Members = ({children}: any) => {
  const [membersFilter, dispatch] = useReducer(MembersReducer, INITIAL_MEMBERS_STATE)


  return (
    <MembersContext.Provider value={{membersFilter, dispatch}}>
      {children}
    </MembersContext.Provider>
  )
}

export default MembersContext