import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {putDesignTemplate} from "../../api/private/serviceProviders";
import {useServiceProvider} from "../useServiceProvider";

export const useUpdateDesignTemplate = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {serviceProvider} = useServiceProvider()
    const {mutate: UpdateDesignTemplate} = useMutation((payload: any) => (putDesignTemplate(serviceProvider?.id, payload)),
        {
            onSuccess: async (data, payload) => {
                queryClient.resetQueries(['service-provider', serviceProvider?.id])
                toast.success(`${t('service_provider_design_template_update_success')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            },
            onError: async () => {
                toast.error(`${t('service_provider_design_template_update_error')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })

    return {UpdateDesignTemplate}
}