import React, {useState} from "react"
import {Button, Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import moment from 'moment/moment'
import {StatisticsWidget} from 'components/StatisticsWidget'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useTotalBookings} from 'hooks/bookings/useTotalBookings'
import {useTotalGuests} from 'hooks/bookings/useTotalGuests'
import {StatisticsChart} from 'components/StatisticsChart'
import {ArrowDatePicker} from "components/ArrowDatePicker";
import {arrowDatePickerStyles} from "../theme/ArrowDatePicker.style";
import {useMember} from "../hooks/members/useMember";
import {toast} from "react-toastify";

export const Main = () => {
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const {member} = useMember()
  const isServiceProvider = !!member?.defaultServiceProviderId
  const {totalBookings} = useTotalBookings()
  const {totalGuests} = useTotalGuests()
  const [startDate, setStartDate] = useState<Date>(new Date())
  const classes = arrowDatePickerStyles()

  const openSpError = () => {
    toast.error(`${t('add_service_provider_first')}`, {
      position: toast.POSITION.TOP_RIGHT
    })
  }
  return (
    <>
      <Grid container spacing={2}
            direction="column"
            justifyContent="center">
        <Grid item lg={12} md={12} sm={12} xs={12} m={2}>
          <Typography>
            {`${t(moment().format('MMMM').toLowerCase())} ${moment().format('D, YYYY')}`}
          </Typography>
        </Grid>
        <Grid container spacing={2} style={{minHeight: '100%'}}
              justifyContent="center">
          <StatisticsWidget mainText={'total_bookings'} secondaryText={`${totalBookings?.count} ${t('tables')}`}/>
          <StatisticsWidget mainText={'total_guests'} secondaryText={`${totalGuests?.count} ${t('guests')}`}/>

          <Grid container item xs={4} alignItems="center"
                spacing={2}
                direction="column"
                justifyItems="center" justifyContent="center">
            <Grid item>
              <Button variant={'contained'} type="submit" onClick={() => isServiceProvider ? navigate('add-booking') : openSpError()} sx={{width: 200}}>
                {t('add_booking')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant={'walkInButton'} type="submit" onClick={() => isServiceProvider ? navigate('add-booking') : openSpError()} sx={{width: 200}}>
                {t('add_walk_in')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={10} sx={{backgroundColor: 'white'}}>
          <Grid item mb={5}>
            <div className={classes.pickerWrapper}>
              <Typography sx={{display: 'block', width: 200}}>{t('statistics')}</Typography>
              <ArrowDatePicker unit={'days'} inputFormat={"DD MMM, YYYY"} onChange={(date: Date) => setStartDate(date)}
                               selectedDate={startDate} views={['day', 'month', 'year']}/>
            </div>
          </Grid>
          <Grid item style={{maxWidth: "95%"}}><StatisticsChart selectedDate={startDate}/></Grid>
        </Grid>
      </Grid>
    </>

  )
}