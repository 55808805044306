import React, {useContext} from 'react';
import {useQuery} from 'react-query'
import {getHighlightDishes} from 'api/private/serviceProviders'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useHighlightDishes = () => {
    const {defaultProvider} = useContext(ServiceProviderContext)

    const {data: highlightDishes} = useQuery(['highlight-dishes', defaultProvider?.id,],
        async () => await getHighlightDishes(defaultProvider?.id),
        {
            enabled: !!defaultProvider?.id
        }
    )

    const highlightDishesArray = {
        highlightDishes: {
            EN: highlightDishes?.filter((dish: any) => dish.languageCode === 'EN'),
            DE: highlightDishes?.filter((dish: any) => dish.languageCode === 'DE'),
            KA: highlightDishes?.filter((dish: any) => dish.languageCode === 'KA')
        }
    }

    return {highlightDishes, highlightDishesArray}
};
