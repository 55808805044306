import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField } from '@mui/material'
import React, { useContext } from 'react'
import { OptionsType } from 'types/General'
import RestaurantTablesContext from 'context/restaurantTables/RestaurantTablesContext'
import { RestaurantTablesActionsType } from 'types/Restaurant'

type props = {
  options: OptionsType[]
  translateLabel: boolean
  defaultValue?: string | number
  type: RestaurantTablesActionsType
  label: string
  width?: number
}

export const RestaurantTablesFilters = ({options, translateLabel, type, defaultValue, label, width}: props) => {
  const {t} = useTranslation()
  const defaultVal = options.find(item => item.value === defaultValue)
  const {dispatch} = useContext(RestaurantTablesContext)
  return (
    <Autocomplete
      options={options}
      defaultValue={defaultVal}
      value={defaultVal}
      onChange={(_, data) => {
        dispatch({type: type, payload: data?.value})
      }}
      getOptionLabel={(option) => option && translateLabel ? t(option.label) : option.label}
      isOptionEqualToValue={option => option.value === defaultVal?.value}
      renderInput={(params =>
        <TextField
          {...params}
          variant={'outlined'}
          size={'small'}
          sx={{width: width ? width : 250}}
          label={t(`${label}`)}
          autoComplete={'off'}
        />)}/>
  )
}