import React, { useContext, useMemo } from "react"
import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { AutocompleteController } from 'components/form/AutocompleteController'
import { memberRoles } from 'config/Options'
import { CreateMemberType } from 'types/Members'
import { useCreateMember } from 'hooks/members/useCreateMember'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { memberAddFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'


export const AddMemberDrawer = ({openBooking, setOpenBooking}: any) => {
  const {t} = useTranslation();
  const {AddMember} = useCreateMember()
  const schema = useMemo(() => memberAddFormSchema(), [i18n.language]);

  const {handleSubmit, control, reset} = useForm<CreateMemberType>({resolver: yupResolver(schema)})

  const toggleDrawer = () => {
    reset()
    setOpenBooking(!openBooking)
  }
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const submitHandler = (values: CreateMemberType) => {
    AddMember({...values, serviceProviderId: defaultServiceProviderId, defaultServiceProviderId: defaultServiceProviderId, languageCode: getCurrentLng().toUpperCase()})
    setOpenBooking(!openBooking)
    reset()
  }

  return (
    <Drawer open={openBooking} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h6'}>{t('add_new_member')}</Typography>
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} name={'firstName'} label={'first_name'}/>
            <TextFieldController control={control} name={'lastName'} label={'last_name'}/>
            <TextFieldController control={control} name={'email'} label={'email'}/>
            <AutocompleteController control={control} name={'memberRole'} label={'select_role'} options={memberRoles}/>

            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
          </Stack>
        </form>
      </Container>
    </Drawer>
  )
}