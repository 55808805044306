import { useMutation } from 'react-query'
import { ForgotPasswordInputType } from 'types/Auth'
import { forgotPassword } from 'api/public/publicapi'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const useForgotPassword = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {mutate: ForgotPassword} = useMutation(
    (payload: ForgotPasswordInputType) => {
      return forgotPassword(payload);
    },
    {
      onSuccess: () => {
        toast.success(`${t('forgot_password_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        navigate("/")
      },
      onError: () => {
        toast.error(`${t('forgot_password_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )

  return {ForgotPassword}
}