import React, {useEffect, useState} from "react"
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Box, Grid} from "@mui/material"
import {SuccessErrorBox} from "./SuccessErrorBox";
import {useUnsubscribeUnauthorized} from "../hooks/useUnsubscribeUnauthorized";

export const Unsubscribe = () => {
    const [searchParams] = useSearchParams();
    const {UseUnsubscribe, isSuccess, isLoading} = useUnsubscribeUnauthorized(Number(searchParams?.get("bookingId")))

    const {t} = useTranslation()

    useEffect(() => {
        const payload = {
            email: searchParams.get("email") as string,
            mailType: searchParams.get("mailType") as string,
            token: searchParams.get("token") as string
        };
        UseUnsubscribe(payload);
    }, [])

    return (
        <>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" minHeight="100vh"
                  sx={{backgroundColor: "#fbfafd"}}>
                {!isLoading &&
                    <SuccessErrorBox isSuccess={isSuccess} message={t('email_successfully_unsubscribed')}/>
                }
            </Grid>
        </>
    )
}