import { useQuery } from 'react-query'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { ServiceProviderStyle } from 'types/ServiceProvider'
import { getServiceProviderStyles } from 'api/private/serviceProviders'

export const useServiceProviderStyles = () => {
  const {defaultProvider} = useContext(ServiceProviderContext)

  const {data: serviceProviderStyles} = useQuery<ServiceProviderStyle>(['service-provider-styles', defaultProvider?.id],
    () => getServiceProviderStyles(defaultProvider?.id),
    {
      enabled: !!defaultProvider
    })

  return {serviceProviderStyles}
}