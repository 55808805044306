import { MembersAction, MembersStateType } from 'types/Members'


export const SELECT_PAGE_MEMBERS = 'SELECT_PAGE_MEMBERS'
export const SELECT_PAGE_SIZE_MEMBERS = 'SELECT_PAGE_SIZE_MEMBERS'

export const INITIAL_MEMBERS_STATE = {
  page: 0,
  perPage: 10
}

export const MembersReducer = (state: MembersStateType = INITIAL_MEMBERS_STATE, action: MembersAction) => {
  switch (action.type) {
    case SELECT_PAGE_MEMBERS:
      return {
        ...state,
        page: action.payload
      }
    case SELECT_PAGE_SIZE_MEMBERS:
      return {
        ...state,
        perPage: action.payload
      }
    default:
      return state;
  }
}