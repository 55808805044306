import {getUserId} from 'config/auth'
import {useQuery} from 'react-query'
import {getStripeBookingPriceConfiguration} from 'api/private/members'
import {StripeBookingPriceConfiguration} from "../../types/Members";

export const useStripeBookingConfiguration = () => {
  const userId = getUserId()

  const {data: stripeBookingPriceConfiguration, refetch} = useQuery<StripeBookingPriceConfiguration>(['stripe-booking-price-configuration', userId],
    () => getStripeBookingPriceConfiguration(userId))

  return {stripeBookingPriceConfiguration, refetch}
}