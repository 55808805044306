import { useMutation, useQueryClient } from 'react-query'
import { putServiceProvider } from 'api/private/serviceProviders'
import { ServiceProviderType } from 'types/ServiceProvider'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUpdateServiceProvider = (resetServiceProviderAndRefreshPage?: boolean) => {
  const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {defaultProvider} = useContext(ServiceProviderContext)

  const {mutate: UpdateServiceProvider} = useMutation((payload: ServiceProviderType) => (putServiceProvider(defaultProvider?.id, payload)),
    {
      onSuccess: async (data) => {
        defaultProvider.name = data.data.name;
        if (resetServiceProviderAndRefreshPage) {
            queryClient.resetQueries(['service-provider', defaultProvider?.id]).then(() => {
                queryClient.invalidateQueries({ queryKey: ['get-sp-available-timeslots', defaultProvider?.id], exact: false})
            })
        }
        toast.success(`${t('service_provider_update_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('service_provider_update_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {UpdateServiceProvider}
}