import React, {useContext, useEffect, useState} from "react"
import {
    Button,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material'
import Typography from '@mui/material/Typography'
import moment from 'moment/moment'
import {useTranslation} from 'react-i18next'
import {bookingSeats, bookingsStatus, bookingsTablesType, defaultTimeOptions, tableVipOptions} from 'config/Options'
import {useBookings} from 'hooks/bookings/useBookings'
import {BookingsFilters} from 'components/BookingFilters'
import BookingRow from 'components/BookingRow'
import {ISO_DATE_FORMAT} from 'config/const'
import {
    SELECT_DATE_CURRENT_BOOKINGS,
    SELECT_END_TIME_CURRENT_BOOKINGS,
    SELECT_NAME_CURRENT_BOOKINGS,
    SELECT_PAGE_CURRENT_BOOKINGS,
    SELECT_PAGE_SIZE_CURRENT_BOOKINGS,
    SELECT_SEATS_CURRENT_BOOKINGS,
    SELECT_START_TIME_CURRENT_BOOKINGS,
    SELECT_STATUS_CURRENT_BOOKINGS,
    SELECT_TABLE_NUMBER_CURRENT_BOOKINGS,
    SELECT_TABLE_TYPE_CURRENT_BOOKINGS,
    SELECT_VIP_CURRENT_BOOKINGS
} from 'context/bookings/BookingsReducer'
import BookingContext from 'context/bookings/BookingContext'
import {useTotalBookingsWithInterval} from 'hooks/bookings/useTotalBookingsWithInterval'
import {toast} from 'react-toastify'
import {DoneOutlined, EventOutlined} from '@mui/icons-material'
import {CustomDatePicker} from 'components/CustomDatePicker'
import {useRestaurantTables} from "../hooks/restaurant/useRestaurantTables";
import {OptionsType} from "../types/General";
import {GenerateReservationCardsDrawer} from "../components/drawers/GenerateReservationCardsDrawer";


export const CurrentBookings = () => {
    const {t} = useTranslation();

    const {bookings, dispatch} = useContext(BookingContext)
    const {startDate, startTime, endTime, status, seats,vip, tableType, tableNumber} = bookings.currentBookings

    const [bookingsCount, setBookingsCount] = useState<number>(0)
    const [countNewBookings, setCountNewBookings] = useState<number>(0)

    // we do count query for bookings when !refetchBookings
    const {totalBookings} = useTotalBookingsWithInterval()
    // we fetch bookings when refetchBookings
    const {bookingValues, isSuccess, refetch} = useBookings(bookingsCount)

    const toastId = React.useRef(null);

    const customIcon = <div><EventOutlined color={'info'}/></div>

    const CustomCloseButton = ({closeToast}: any) => (
        <IconButton onClick={closeToast}>
            <DoneOutlined color={'success'}/>
        </IconButton>
    );

    const getNewBookingsReceivedMessage = (diffNewBookingsCount: number) => {
        if (diffNewBookingsCount == 1) {
            return diffNewBookingsCount + t('booking_received')
        }
        return diffNewBookingsCount + t('bookings_received')
    }

    const {restaurantTables} = useRestaurantTables({status: 'ACTIVATED', type: undefined, vip: undefined, page: 0, perPage: 40})

    const [tableNumberOptions, setTableNumberOptions] = useState<OptionsType[]>([])

    useEffect(() => {
        if (restaurantTables) {
            setTableNumberOptions(restaurantTables.content.map((table, index) => ({
                value: table.number,
                label: table.number + " - " + t(table.type)
            })))
        }
    }, [restaurantTables])

    const [openGenerateReservationCards, setOpenGenerateReservationCards] = useState(false)

    useEffect(() => {
        if (totalBookings) {
            if (totalBookings.count != bookingsCount) {
                const diffNewBookingsCount = totalBookings.count - bookingsCount

                // If BookingsCount is zero it means its first run and we don't refetch booking values or show success toast
                if (bookingsCount == 0 && totalBookings.count != 0) {
                    setBookingsCount(totalBookings.count)
                    refetch()
                } else {
                    setBookingsCount(totalBookings.count)
                    refetch().then(() => {
                        //Here we have logic of success toast messages.
                        //In case there is existing toast message, we will just update existing one with adding new bookings count
                        if (isSuccess && totalBookings.count > 0) {
                            // @ts-ignore
                            if (!toast.isActive(toastId.current)) {
                                setCountNewBookings(diffNewBookingsCount)
                                // @ts-ignore
                                toastId.current = toast.success(getNewBookingsReceivedMessage(diffNewBookingsCount), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    icon: customIcon,
                                    autoClose: false,
                                    closeButton: CustomCloseButton,
                                    closeOnClick: true,
                                    onClose: () => {
                                        setCountNewBookings(0)
                                    }
                                })
                            } else {
                                setCountNewBookings(diffNewBookingsCount + countNewBookings)
                                // @ts-ignore
                                toast.update(toastId.current, {
                                    type: toast.TYPE.SUCCESS,
                                    render: getNewBookingsReceivedMessage(diffNewBookingsCount + countNewBookings)
                                })
                            }
                        }
                    })
                }
            }
        }
    }, [totalBookings])

    const handleNameChange = (name: string) => {
        if (name.length > 2) {
            dispatch({type: SELECT_NAME_CURRENT_BOOKINGS, payload: name})
        } else if (name.length === 0) {
            dispatch({type: SELECT_NAME_CURRENT_BOOKINGS, payload: ''})
        }
    }

    const handlePageChange = (page: number) => {
        dispatch({type: SELECT_PAGE_CURRENT_BOOKINGS, payload: page})
    }

    const handlePageSizeChange = (size: string) => {
        dispatch({type: SELECT_PAGE_SIZE_CURRENT_BOOKINGS, payload: Number(size)})
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item>
                    <CustomDatePicker label={t('date')}
                                      date={startDate}
                                      onChange={(newValue) => dispatch({
                                          type: SELECT_DATE_CURRENT_BOOKINGS,
                                          payload: moment(newValue).format(ISO_DATE_FORMAT)
                                      })}
                    />
                </Grid>
                <Grid item>
                    <BookingsFilters options={defaultTimeOptions} translateLabel={false} defaultValue={startTime}
                                     type={SELECT_START_TIME_CURRENT_BOOKINGS}
                                     label={'start_time_from'} disableClearable={true}/>
                </Grid>
                <Grid item>
                    <BookingsFilters options={defaultTimeOptions} translateLabel={false} defaultValue={endTime}
                                     type={SELECT_END_TIME_CURRENT_BOOKINGS}
                                     label={'start_time_to'} disableClearable={true}/>
                </Grid>
                <Grid item>
                    <BookingsFilters options={tableNumberOptions} translateLabel={false} defaultValue={tableNumber}
                                     type={SELECT_TABLE_NUMBER_CURRENT_BOOKINGS} label={'table_number'}/>
                </Grid>

                <Grid item>
                    <BookingsFilters options={tableVipOptions} translateLabel={true} defaultValue={vip}
                                     type={SELECT_VIP_CURRENT_BOOKINGS} label={'vip'}/>
                </Grid>
            </Grid>

            <Grid container spacing={3} my={1}>
                <Grid item>
                    <BookingsFilters options={bookingSeats} translateLabel={false} defaultValue={seats} type={SELECT_SEATS_CURRENT_BOOKINGS}
                                     label={'number_of_guests'}/>
                </Grid>
                <Grid item>
                    <BookingsFilters options={bookingsTablesType} translateLabel={true} defaultValue={tableType}
                                     type={SELECT_TABLE_TYPE_CURRENT_BOOKINGS}
                                     label={'type'} disabled={tableNumber != undefined}/>
                </Grid>
                <Grid item>
                    <BookingsFilters options={bookingsStatus} translateLabel={true} defaultValue={status}
                                     type={SELECT_STATUS_CURRENT_BOOKINGS} label={'status'}/>
                </Grid>
                <Grid item>
                    <TextField sx={{width: 200}} label={t('customer_name')} size={'small'}
                               onChange={e => handleNameChange(e.target.value)}/>
                </Grid>
            </Grid>

            <Grid container>
                <Paper sx={{width: '100%', padding: 2}}>

                    <Typography sx={{float: 'left'}} variant={'h5'} mb={2}>{t('bookings_history')}</Typography>
                    <Button variant={'contained'} sx={{backgroundColor: '#85E6CC', float:'right'}} onClick={() => setOpenGenerateReservationCards(true)}>
                        {t('generate_reservation_cards')}
                    </Button>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                                <TableRow>
                                    <TableCell>{t('table')}</TableCell>
                                    <TableCell>{t('channel')}</TableCell>
                                    <TableCell>{t('number_of_guests')}</TableCell>
                                    <TableCell>{t('booking_start_time')}</TableCell>
                                    <TableCell>{t('booking_end_time')}</TableCell>
                                    <TableCell>{t('customer_name')}</TableCell>
                                    <TableCell>{t('status')}</TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bookingValues?.content.map((booking, index) => {
                                    return (
                                        <BookingRow key={booking.id} index={index} booking={booking}/>
                                    )
                                })}

                            </TableBody>
                        </Table>
                        {bookingValues && <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            component="div"
                            labelRowsPerPage={t('rows_per_page')}
                            count={bookingValues?.totalElements}
                            rowsPerPage={bookingValues ? bookingValues.size : 10}
                            page={bookingValues?.number || 0}
                            onPageChange={(event: any, page: number) => handlePageChange(page)}
                            onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
                        />}
                    </TableContainer>
                </Paper>
                {openGenerateReservationCards && <GenerateReservationCardsDrawer bookings={bookingValues?.content} openGenerateReservationCards={openGenerateReservationCards} setOpenGenerateReservationCards={setOpenGenerateReservationCards}/>}
            </Grid>
        </>
    )
}