import React, {useMemo, useState} from "react"
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import {ForgotPasswordInputType, LoginInputType} from 'types/Auth'
import { useForgotPassword } from 'hooks/useForgotPassword'
import { useTranslation } from 'react-i18next'
import { forgotPasswordFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextFieldController } from 'components/form/TextFieldController'
import { forgotPasswordStyles } from 'theme/ForgotPassword.styles'
// @ts-ignore
import portalLogo from "../portal_logo.svg";
import ReCaptchaV3 from "../components/ReCaptchaV3";


export const ForgotPassword = () => {
  const classes = forgotPasswordStyles()
  const {t} = useTranslation()

  const schema = useMemo(() => forgotPasswordFormSchema(), [i18n.language]);

  const {handleSubmit, control, formState: {errors}} = useForm<ForgotPasswordInputType>({resolver: yupResolver(schema)})

  const {ForgotPassword} = useForgotPassword()

  const [values, setValues] = useState<ForgotPasswordInputType | null>(null)

  const forgotPasswordHandler: SubmitHandler<ForgotPasswordInputType> = (values: ForgotPasswordInputType) => {
    values.email = values.email.toLowerCase();
    setValues({...values})
  }

  return (

    <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh">
      <Box className={classes.forgotPasswordWrapper}>
        <Box component="img" alt="Skiper Portal Logo" src={portalLogo} style={{width:200, marginBottom: 20}}/>

        <Typography variant={'h4'} className={classes.forgotPasswordText} m={3}><b>{t('forgot_password')}</b></Typography>
        <Box component="form" onSubmit={handleSubmit(forgotPasswordHandler)} noValidate className={classes.formWrapper}>
          <Stack direction={'column'}>

            <TextFieldController control={control} name={'email'} label={'email'}/>
            <ReCaptchaV3 values={values} mutationFN={ForgotPassword}/>
            <Button type="submit" fullWidth variant="contained" className={classes.forgotPasswordButton}>
              {t('submit')}
            </Button>
          </Stack>
        </Box>
      </Box>

    </Grid>
  )
}