import React, {useState} from 'react';
import {IconButton, TableCell, TableRow, Tooltip} from '@mui/material'
import {RestaurantTablesContent} from 'types/Restaurant'
import {useDeactivateRestaurantTable} from 'hooks/restaurant/useDeactivateRestaurantTable'
import {useTranslation} from 'react-i18next'
import {CancelOutlined, DoneOutlined, EditOutlined} from '@mui/icons-material'
import {useActivateRestaurantTable} from 'hooks/restaurant/useActivateRestaurantTable'
import {TableDrawer} from 'components/drawers/TableDrawer'
import {useServiceProvider} from "../hooks/useServiceProvider";

type props = {
    index: number
    table: RestaurantTablesContent
}


const RestaurantTableRow = ({index, table}: props) => {
    const {t} = useTranslation();
    const {serviceProvider} = useServiceProvider()

    const {deactivateTables} = useDeactivateRestaurantTable()
    const {activateTables} = useActivateRestaurantTable()
    const [openEditTable, setOpenEditTable] = useState(false)
    const handleEdit = () => {
        setOpenEditTable(!openEditTable)
    }

    return (

        <TableRow key={index}>
            <TableCell>{table.number}</TableCell>
            <TableCell>{table.seats}</TableCell>
            <TableCell>{table.minGuests}</TableCell>
            <TableCell>{t(table.type)}</TableCell>
            <TableCell>
                {table.fillable &&
                    <Tooltip title={`${t('fillable_tooltip')}`} placement="top">
                        <DoneOutlined color={'success'}/>
                    </Tooltip>}
            </TableCell>
            <TableCell>
                {table.vip &&
                    <Tooltip title={`${t('vip_tooltip')}`} placement="top">
                        <DoneOutlined color={'success'}/>
                    </Tooltip>}
            </TableCell>
            {serviceProvider?.wheelchairSupport &&
                <TableCell>
                    {table.wheelchairSupport && <DoneOutlined color={'success'}/>}
                </TableCell>
            }
            <TableCell>{t(table.status)}</TableCell>
                <TableCell padding={'checkbox'}>
                    <Tooltip title={`${t('update')}`} placement="top" onClick={handleEdit}>
                        <IconButton>
                            <EditOutlined/>
                        </IconButton>
                    </Tooltip>
                    {/*<Button variant={'tableButton'} fullWidth sx={{backgroundColor: '#F8F8F8'}} onClick={() => handleEdit()}>*/}
                    {/*  {t('update')}*/}
                    {/*</Button>*/}
                </TableCell>
            {table.status === 'DEACTIVATED' && <TableCell padding={'checkbox'}>
                <Tooltip title={`${t('activate')}`} placement="top" onClick={() => activateTables(table.id)}>
                    <IconButton>
                        <DoneOutlined color={'success'}/>
                    </IconButton>
                </Tooltip>
            </TableCell>}
            {table.status === 'ACTIVATED' && <TableCell padding={'checkbox'}>
                <Tooltip title={`${t('deactivate')}`} placement="top" onClick={() => deactivateTables(table.id)}>
                    <IconButton>
                        <CancelOutlined color={'error'}/>
                    </IconButton>
                </Tooltip>
            </TableCell>}
            {openEditTable &&
                <TableDrawer table={table} openDrawer={openEditTable} setOpenDrawer={setOpenEditTable} edit={true}/>}
        </TableRow>

    )
}

export default RestaurantTableRow;