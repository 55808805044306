import {useTranslation} from 'react-i18next'
import {Autocomplete, TextField} from '@mui/material'
import React, {useContext} from 'react'
import {OptionsType} from 'types/General'
import BookingContext from 'context/bookings/BookingContext'
import {BookingActionsType} from 'types/Bookings'

type props = {
    options: OptionsType[]
    translateLabel: boolean
    defaultValue?: string | number
    type: BookingActionsType
    label: string
    width?: number
    disableClearable?: boolean
    disabled?: boolean
}

export const BookingsFilters = ({
                                    options,
                                    translateLabel,
                                    type,
                                    defaultValue,
                                    label,
                                    width,
                                    disableClearable = false,
                                    disabled = false
                                }: props) => {
    const {t} = useTranslation()

    const defaultVal = options.find(item => item.value === defaultValue)
    const {dispatch} = useContext(BookingContext)
    return (
        <Autocomplete
            options={options}
            defaultValue={defaultVal || null}
            value={defaultVal || null}
            onChange={(_, data) => {
                dispatch({type: type, payload: data?.value})
            }}
            disabled={disabled}
            getOptionLabel={(option) => option && translateLabel ? t(option.label) : option.label}
            disableClearable={disableClearable}
            isOptionEqualToValue={option => option.value === defaultVal?.value}
            renderInput={(params =>
                <TextField
                    {...params}
                    variant={'outlined'}
                    size={'small'}
                    sx={{width: width ? width : 200}}
                    label={t(`${label}`)}
                    autoComplete={'off'}
                />)}/>
    )
}