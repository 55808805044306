import {useQuery} from 'react-query'
import {getDesignTemplates} from 'api/private/serviceProviders'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {DesignTemplate} from "../../types/ServiceProvider";


export const useDesignTemplates = () => {
    const {serviceProvider} = useServiceProvider()

    const {data: designTemplates} = useQuery<DesignTemplate[]>(['designTemplates'],
    async () => await getDesignTemplates(),
    {
      enabled: !!serviceProvider?.id,
      onSuccess: (data => {
        return data
      })
    }
  )

  return {designTemplates}
}