import React, {useState} from 'react';
import {Alert, Box, Button, IconButton, Stack, TableCell, TableRow, Tooltip, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {AddWalkInDrawer} from 'components/drawers/AddWalkInDrawer'
import {RestaurantTablesContent} from 'types/Restaurant'
import {AccessibleOutlined, DoneOutlined, InfoOutlined} from "@mui/icons-material";


type props = {
    index: number
    item: RestaurantTablesContent
}

const WalkInRow = ({index, item}: props) => {
    const {t} = useTranslation();
    const [openWalkIn, setOpenWalkIn] = useState(false)

    return (
        <TableRow key={`walkin_${item.id}_${index}`}>
            <TableCell>{item.number}
                {item.wheelchairSupport &&
                    <Tooltip title={`${t('wheelchair_support')}`} placement="top">
                        <IconButton style={{paddingBottom: '10px'}}>
                            <AccessibleOutlined color={'info'}/>
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
            <TableCell>{item.seats}</TableCell>
            <TableCell>{t(item.type)}</TableCell>
            <TableCell>{item.remainingSeats}</TableCell>
            <TableCell>
                {item.vip &&
                    <Tooltip title={`${t('vip_tooltip')}`} placement="top">
                        <DoneOutlined color={'success'}/>
                    </Tooltip>}
            </TableCell>
            <TableCell>
                {item.outsideOfAvailabilityHours ?
                    <Button variant={'availableWithWarningButton'} fullWidth onClick={() => setOpenWalkIn(true)}>
                        {t('add_walk_in')}
                        <Tooltip title={
                            <Typography padding={0} variant={'body2'} dangerouslySetInnerHTML={
                                {__html: t('google_tooltip_text_one', {interpolation: {escapeValue: false}})}
                            }/>
                        } placement="top" enterTouchDelay={0}>
                            <InfoOutlined color={'info'} sx={{marginLeft: 1, cursor: 'pointer'}}/>
                        </Tooltip>
                    </Button>
                    :
                    <>
                        <Button variant={'walkInButton'} fullWidth onClick={() => setOpenWalkIn(true)}>
                            {t('add_walk_in')}
                        </Button>
                    </>
                }

            </TableCell>
            {openWalkIn && <AddWalkInDrawer table={item} openWalkIn={openWalkIn} setOpenWalkIn={setOpenWalkIn}/>}
        </TableRow>
    )
}

export default WalkInRow