import React, { createContext, useReducer } from 'react'
import { RestaurantTablesAction, RestaurantTablesStateType } from 'types/Restaurant'
import { INITIAL_RESTAURANT_TABLES_STATE, RestaurantTablesReducer } from 'context/restaurantTables/RestaurantTablesReducer'

type RestaurantTablesContextType = {
  restaurantTablesFilter: RestaurantTablesStateType
  dispatch: (action: RestaurantTablesAction) => void
}


const RestaurantTablesContext = createContext<RestaurantTablesContextType>({} as RestaurantTablesContextType)

export const RestaurantTables = ({children}: any) => {
  const [restaurantTablesFilter, dispatch] = useReducer(RestaurantTablesReducer, INITIAL_RESTAURANT_TABLES_STATE)


  return (
    <RestaurantTablesContext.Provider value={{restaurantTablesFilter, dispatch}}>
      {children}
    </RestaurantTablesContext.Provider>
  )
}

export default RestaurantTablesContext