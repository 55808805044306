import React, { createContext, useEffect, useState } from 'react'
import { useServiceProvider } from 'hooks/useServiceProvider'

type ServiceProviderContextType = {
  defaultServiceProviderId: number
  defaultProvider?: any
}

const ServiceProviderContext = createContext<ServiceProviderContextType>({defaultServiceProviderId: {} as number})

export const ServiceProvider = ({children}: any) => {


  const {serviceProvider} = useServiceProvider()

  const [defaultServiceProviderId, setDefaultServiceProviderId] = useState(0)
  const defaultProvider = serviceProvider

  useEffect(() => {
    if (serviceProvider) {
      setDefaultServiceProviderId(serviceProvider.id)
    }
  }, [serviceProvider])

  return (
    <ServiceProviderContext.Provider value={{defaultServiceProviderId, defaultProvider}}>
      {children}
    </ServiceProviderContext.Provider>
  )
}

export default ServiceProviderContext