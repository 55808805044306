import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

type props = {
  title: string
  accordionName: string
}

export const CustomAccordion = ({children, title, accordionName}: PropsWithChildren<props>) => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>('networkStatus');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Accordion expanded={expanded === accordionName} onChange={handleChange(accordionName)}>
      <AccordionSummary expandIcon={<ExpandMore/>}>
        <Typography>{t(title)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}