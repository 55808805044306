import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { minWidth } from 'theme/navigation.styles'

export const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '22vw',
      [theme.breakpoints.up('lg')]: {
        width: '22vw'
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: '27vw'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '35vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: minWidth
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(8)
        }
      })
    }
  })
)