import {useQuery} from 'react-query'
import {getAvailableRestaurantTable} from 'api/private/restaurants'
import {RestaurantTables} from 'types/Restaurant'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import BookingContext from 'context/bookings/BookingContext'

export const useRestaurantTablesAvailability = () => {

  const {selectedDateTime, bookings} = useContext(BookingContext)
  const {seats, tableType, vip} = bookings.add_bookings

  const payload = {
    startDate: selectedDateTime,
    numberOfSeats: seats,
    tableType: tableType,
    channel: 'DIRECT',
    vip: vip,
    page: bookings.add_bookings.page,
    size: bookings.add_bookings.perPage
  }
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const {data: availableTables, isSuccess, isError, refetch} = useQuery<RestaurantTables>(
    ['available-tables', {defaultServiceProviderId, payload}],
    () => getAvailableRestaurantTable(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId
    })

  return {availableTables, isSuccess, isError, refetch}
}