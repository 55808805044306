import React from 'react'
import {PluginProvider} from 'plugin/PluginContext'
import {Plugin} from 'plugin/Plugin'


export const PluginContainer = () => {

  return (
    <PluginProvider>
      <Plugin/>
    </PluginProvider>
  )
}