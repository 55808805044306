import {Control, Controller} from 'react-hook-form'
import {FormControl, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type props = {
    control: Control<any>
    name: string
    multiline?: boolean
    size?: "medium" | "small" | undefined
    defaultValue?: string | number
    type?: string
    variant?: "standard" | "outlined" | undefined
    label?: string
    plugin?: boolean
    placeholder?: string
    autoComplete?: boolean
    disabled?: boolean
}


export const TextFieldController = ({
                                        control,
                                        name,
                                        defaultValue,
                                        multiline,
                                        type,
                                        label = '',
                                        disabled = false,
                                        size = 'medium',
                                        autoComplete = true,
                                        variant = "outlined",
                                        placeholder = '',
                                    }: props) => {
    const {t} = useTranslation();
    return <Controller
        control={control}
        render={({field, fieldState: {error}}) => (
            <FormControl fullWidth={true} margin={'dense'}>
                <TextField
                    {...field}
                    style={{width: '100%'}}
                    variant={variant}
                    size={size}
                    value={field.value || field.value == 0 ? field.value : ''}
                    disabled={disabled}
                    multiline={multiline}
                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                    rows={multiline ? 3 : 1}
                    InputProps={{
                        inputProps: {
                            min: 0,
                        }
                    }}
                    onChange={field.onChange}
                    type={type ? type : 'text'}
                    defaultValue={defaultValue || defaultValue == 0 ? defaultValue : ''}
                    label={t(label)}
                    placeholder={t(placeholder)}
                    autoComplete={autoComplete ? label : 'off'}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                />
            </FormControl>
        )}
        name={name}/>

}