import { useQuery } from 'react-query'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { getTotalGuests } from 'api/private/bookings'
import moment from 'moment/moment'
import {getEndTimeOfTheDayInUTC, getStartTimeOfTheDayInUTC} from "../../config/Utils";


export const useTotalGuests = () => {
  const fallback = {count: 0}
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const payload = {
    startDate: getStartTimeOfTheDayInUTC(),
    endDate: getEndTimeOfTheDayInUTC()
  }

  const {data: totalGuests = fallback} = useQuery(['guests-total', defaultServiceProviderId],
    () => getTotalGuests(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId
    })

  return {totalGuests}
}