import React from 'react'
import { Grid } from '@mui/material'
import UploadMenu from 'components/network/UploadMenu'
import UploadVideo from 'components/network/UploadVideo'
import UploadImages from 'components/network/UploadImages'

export const UploadFiles = () => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <UploadMenu lang={'EN'}/>
          <UploadMenu lang={'DE'}/>
          <UploadVideo/>
        </Grid>

        <Grid item xs={6}>
          <UploadImages/>
        </Grid>
      </Grid>


    </>
  )
}