import {IconButton, Tooltip} from "@mui/material";
import {DirectionsWalkOutlined, Google, LanguageOutlined, PhoneCallbackOutlined, PhoneIphoneOutlined} from "@mui/icons-material";
import Box from "@mui/material/Box";
import logoSmall from "../logo_small.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export const getTooltipByChannel = (channel: string | undefined) => {
    const {t} = useTranslation();

    switch (channel) {
        case 'WALK_IN':
            return <Tooltip title={`${t('walk_in_booking')}`} placement="top">
                <IconButton>
                    <DirectionsWalkOutlined color={'info'}/>
                </IconButton>
            </Tooltip>
        case 'DIRECT':
            return <Tooltip title={`${t('direct_booking')}`} placement="top">
                <IconButton>
                    <PhoneCallbackOutlined color={'info'}/>
                </IconButton>
            </Tooltip>
        case 'WEB':
            return <Tooltip title={`${t('web_booking')}`} placement="top">
                <IconButton>
                    <LanguageOutlined color={'info'}/>
                </IconButton>
            </Tooltip>
        case 'MOBILE':
            return <Tooltip title={`${t('mobile_booking')}`} placement="top">
                <IconButton>
                    <PhoneIphoneOutlined color={'info'}/>
                </IconButton>
            </Tooltip>
        case 'GOOGLE':
            return <Tooltip title={`${t('google_booking')}`} placement="top">
                <IconButton>
                    <Google color={'info'}/>
                </IconButton>
            </Tooltip>
        case 'SKIPER_MARKETPLACE':
            return <Tooltip title={`${t('skiper_marketplace')}`} placement="top">
                <Box component="img" alt="Skiper logo" width="40px" height="40px" src={logoSmall}/>
            </Tooltip>
    }
}