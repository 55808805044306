import React, { createContext, useReducer } from 'react'
import { BookingsReducer, INITIAL_BOOKING_STATE } from 'context/bookings/BookingsReducer'
import { BookingAction, BookingStateType } from 'types/Bookings'

type BookingContextType = {
  selectedDateTime: string
  bookings: BookingStateType
  dispatch: (action: BookingAction) => void
}


const BookingContext = createContext<BookingContextType>({} as BookingContextType)

export const Booking = ({children}: any) => {
  const [bookings, dispatch] = useReducer(BookingsReducer, INITIAL_BOOKING_STATE)

  const selectedDateTime = `${bookings.add_bookings.startDate}T${bookings.add_bookings.startTime}`

  return (
    <BookingContext.Provider
      value={{selectedDateTime, bookings, dispatch}}>
      {children}
    </BookingContext.Provider>
  )
}

export default BookingContext