import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import './i18n';
import {ToastContainer} from "react-toastify";
import './fonts/montserrat/Montserrat-Medium.woff';
import './fonts/montserrat/Montserrat-Regular.woff';


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer/>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
