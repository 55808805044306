import { useMutation, useQueryClient } from 'react-query'
import { postServiceProvidersImage } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUploadImage = () => {
  const {t} = useTranslation()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {mutate: UploadImage} = useMutation(
    payload => postServiceProvidersImage(defaultProvider?.id, payload),
    {
      onSuccess: () => {
        queryClient.resetQueries(['images', defaultProvider?.id, 'SERVICE_PROVIDER_IMAGE'])
        toast.success(`${t('image_upload_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: () => {
        toast.error(`${t('image_upload_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )
  return {UploadImage}
}