import { makeStyles } from '@mui/styles'

export const loginStyles = makeStyles({
  loginWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formWrapper: {
    width: 440
  },
  loginText: {
    letterSpacing: '2.4px'
  },
  textField: {
    backgroundColor: '#F7F7F7'
  },
  input: {
    underline: {
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
    },
    color: 'red',
    padding: 50,
    border: '#F7F7F7',
    outline: 'none'
  },
  loginButton: {
    marginTop: '10px !important' as "marginTop",
    marginBottom: '10px !important' as "marginBottom",
    borderRadius: '4px',
    height: '50px',
    fontSize: '16px !important' as "fontSize"
  },
  link: {
    textDecoration: 'none',
    color: '#85E6CC',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: '#85E6CC',
    }
  }
})
