import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useServiceProvider} from "../../../hooks/useServiceProvider";
import {Calendar, DateObject} from "react-multi-date-picker"
import {DATE_FORMAT, ISO_DATE_FORMAT} from "../../../config/const";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {useDailyUnavailability} from "../../../hooks/useDailyUnavailability";
import {useUpdateDailyUnavailability} from "../../../hooks/useUpdateDailyUnavailability";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const DailyUnavailability = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    if (value !== index) return <></>; // Don't render if tab is not selected

    const {t} = useTranslation()
    const {serviceProvider} = useServiceProvider()

    const {DailyUnavailability} = useDailyUnavailability(serviceProvider?.id)
    const {UpdateDailyUnavailability} = useUpdateDailyUnavailability()

    const months = new DateObject().months.map(month => t(month.name.toLowerCase()))

    const weekDays = new DateObject().weekDays.map(weekDay => t(weekDay.shortName.toLowerCase()))

    const [values, setValues] = useState(DailyUnavailability?.dailyUnavailability?.map(a => [new DateObject(a.startDate), new DateObject(a.endDate)]))

    useEffect(() => {
        if (DailyUnavailability) {
            setValues(DailyUnavailability.dailyUnavailability?.map(a => [new DateObject(a.startDate), new DateObject(a.endDate)]))
        }
    }, [DailyUnavailability])

    const submitHandler = () => {
        const mappedValues = values ? values.map(a => {
            return {
                startDate: a[0].format(ISO_DATE_FORMAT),
                endDate: a[1] ? a[1].format(ISO_DATE_FORMAT) : a[0].format(ISO_DATE_FORMAT) // Case when 1 date is selected in range.
            }
        }) : undefined

        UpdateDailyUnavailability({
                dailyUnavailability: mappedValues
            }
        )
    }

    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`daily_unavailability-tab-panel`}
        aria-labelledby={`daily_unavailability-tab-panel`}
        {...other}
    >
        {value === index && (
            <Box sx={{p: 3, margin: 'auto'}}>
                <Typography variant={'h5'} textAlign={"center"} mt={3} mb={3}>{t("daily_unavailability_title")}</Typography>
                <Box sx={{p: 3, margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                    <Calendar
                        multiple
                        // @ts-ignore
                        value={values}
                        range
                        // @ts-ignore
                        onChange={setValues}
                        months={months}
                        weekDays={weekDays}
                        weekStartDayIndex={1}
                        fullYear
                        highlightToday={true}
                        mapDays={({date}) => {
                            const props: any = {}
                            const isWeekend = [0, 6].includes(date.weekDay.index)

                            if (isWeekend) props.className = "highlight highlight-red"

                            return props
                        }}
                        format={DATE_FORMAT}
                        plugins={[
                            <DatePanel key={'date-panel-for-daily-availability'} sort="date" header={t('dates')}/>
                        ]}
                        className='calendar-availability'
                    />
                </Box>
                <Button variant={'contained'} sx={{mt: 2, mb: 2}} onClick={submitHandler} type={'submit'}>{t('submit')}</Button>
            </Box>
        )}
    </div>
}