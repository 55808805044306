import { useMutation, useQueryClient } from 'react-query'
import { putMemberConsentDetails } from 'api/private/members'
import { UpdateMemberConsentDetails } from 'types/Members'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUpdateMemberConsentDetails = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {mutate: EditMemberConsentDetails} = useMutation((payload: UpdateMemberConsentDetails) => (putMemberConsentDetails(payload)),
    {
      onSuccess: async (data, payload) => {
        await queryClient.resetQueries(['member', payload.id])
      },
      onError: async () => {
        toast.error(`${t('member_consent_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {EditMemberConsentDetails}
}