import { useMutation, useQueryClient } from 'react-query'
import { postBooking } from 'api/private/bookings'
import { CreateBookingType } from 'types/Bookings'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useCreateBooking = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const {mutate: AddBooking} = useMutation((payload: CreateBookingType) => (postBooking(defaultServiceProviderId, payload)),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(['available-tables']).then(() => {
          queryClient.resetQueries(['bookings-statistics-query', defaultServiceProviderId])
        })
        toast.success(`${t('booking_created_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('booking_created_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {AddBooking}
}