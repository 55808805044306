import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getJwtToken, isMemberOwner } from 'config/auth'

export const OwnerRoute = () => {
  const auth = getJwtToken() != null

  if (auth && isMemberOwner()) {
    return <Outlet/>
  } else {
    return <Navigate to="/"/>
  }
}