import {privateAxiosClient} from 'config/AxiosClients'
import {
    BookingsType,
    BookingUpdateType,
    CancelBooking,
    CreateBookingType,
    CreateWalkInType,
    DownloadReservationCardsType
} from 'types/Bookings'

const SERVICE_PROVIDERS = '/service-providers'
const BOOKINGS = 'bookings'

export const getBookings = async (serviceProviderId: number | null, payload: any): Promise<BookingsType> => {
    return await (
        privateAxiosClient.get<BookingsType>(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}`, {
            params: {
                ...payload,
                sort: 'booking_time,asc'
            }
        })
            .then(response => response.data)
    )
}

export const getTotalBookings = async (serviceProviderId: number | null, payload: any) => {
    return await (privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/total`, {
            params: {...payload}
        }).then(response => response.data)
    )
}

export const getBookingsCount = async (serviceProviderId: number | null, payload: any) => {
    return await (privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/count`, {
            params: {...payload}
        }).then(response => response.data)
    )
}

export const getTotalGuests = async (serviceProviderId: number | null, payload: any) => {
    return await (privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/total-guests`, {
            params: {...payload}
        }).then(response => response.data)
    )
}

export const getBooking = (serviceProviderId: number | null, id = 1) => (
    privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/${id}`).then(response => response.data)
)

export const postBooking = (serviceProviderId: number | null, payload: CreateBookingType) => (
    privateAxiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}`, payload)
)

export const postWalkIn = (serviceProviderId: number | null, payload: CreateWalkInType) => (
    privateAxiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/walk-in`, payload)
)


export const putUpdateBooking = (serviceProviderId: number | null, payload: BookingUpdateType) => (
    privateAxiosClient.put(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/${payload.id}/update`, payload)
)

export const closeBooking = (serviceProviderId: number | null, payload: CancelBooking) => (
    privateAxiosClient.put(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/${payload.id}/close`, {comment: payload.comment})
)

export const cancelBooking = (serviceProviderId: number | null, payload: CancelBooking) => (
    privateAxiosClient.delete(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/${payload.id}/cancel`, {data: {comment: payload.comment}})
)

export const downloadReservationCards = async (serviceProviderId: number | null, payload: DownloadReservationCardsType) => {
    const searchParams = new URLSearchParams()
    payload.bookingIds.map(item => {
        searchParams.append('bookingIds', item)
    })
    searchParams.append('type', payload.type)

    return await (privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${BOOKINGS}/download-reservation-cards`, {
        params: searchParams,
        responseType: 'blob'
    }).then((response) => {
        const blob = new Blob([response.data], {type: response.data.type});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'unknown';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch?.length === 2)
                fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }));
}