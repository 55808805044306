import {useMutation} from 'react-query'
import {BookingUpdateTypeUnauthorized} from 'types/Bookings'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {putUpdateBookingUnauthorized} from "../../api/public/publicapi";

export const UseUpdateBookingDetailsUnauthorized = () => {
  const {t} = useTranslation()

  const {mutate: UpdateBookingDetailsUnauthorized, isSuccess} = useMutation((payload: BookingUpdateTypeUnauthorized) => (putUpdateBookingUnauthorized(payload)),
    {
      onSuccess: () => {
        toast.success(`${t('booking_updated_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('booking_updated_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {UpdateBookingDetailsUnauthorized, isUpdateBookingSuccess: isSuccess}
}