import { useMutation, useQueryClient } from 'react-query'
import { putUpdateImageSortOrder } from 'api/private/serviceProviders'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'


const useUpdateImageSortOrder = () => {
  const {t} = useTranslation()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()

  const {mutate: UpdateImageOrder} = useMutation((payload: any) => (putUpdateImageSortOrder(defaultProvider?.id, payload)), {
    onSuccess: () => {
      queryClient.resetQueries(['images', defaultProvider?.id])
    },
    onError: () => {
      toast.error(`${t('image_order_update_error')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdateImageOrder}
};

export default useUpdateImageSortOrder;
