import React, {useState} from 'react'
import {Alert, Box, Button, Grid, Paper, Stack, Typography} from '@mui/material'
import {loginStyles} from 'theme/Login.styles'
import {useTranslation} from 'react-i18next'
import {CreditCardOutlined, SellOutlined, TagOutlined} from "@mui/icons-material";
import {useCreateStripeSession} from "../hooks/members/useCreateStripeSession";
import {useMember} from "../hooks/members/useMember";
import {useCreateStripeCustomerPortalSession} from "../hooks/members/useCreateStripeCustomerPortalSession";
import {ArrowDatePicker} from "../components/ArrowDatePicker";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {styled, ThemeProvider} from '@mui/material/styles';
import {theme} from "../theme/Theme";
import {ServiceProvidersBillingTable} from "../components/billing/ServiceProvidersBillingTable";
import {ServiceProvidersBillingTableWithChannels} from "../components/billing/ServiceProvidersBillingTableWithChannels";
import {useServiceProvidersBillingCount} from "../plugin/useServiceProvidersBillingCount";
import {Moment} from "moment/moment";
import moment from "moment";
import {useStripeBookingConfiguration} from "../hooks/members/useStripeBookingPriceConfiguration";


const ToggleButton = styled(MuiToggleButton)((selectedColor: any) => ({
    '&.MuiToggleButton-root': {
        transition: 'all .2s ease !important',
    },
    '&.MuiToggleButton-root:hover': {
        backgroundColor: "#5a769f",
        color: 'white'
    },
    '&.Mui-selected': {
        color: 'white',
        backgroundColor: "#6A8AB9",
    },
    '&.Mui-selected:hover': {
        backgroundColor: "#5a769f",
    }
}));

export const Billing = () => {
    const {t} = useTranslation()
    const classes = loginStyles()
    const {member} = useMember()
    const {stripeBookingPriceConfiguration} = useStripeBookingConfiguration()
    const {CreateStripeSession} = useCreateStripeSession()
    const {CreateStripeCustomerPortalSession} = useCreateStripeCustomerPortalSession()

    const [billingMonth, setBillingMonth] = useState<Moment>(moment(new Date()))

    const {ServiceProvidersBillingCount} = useServiceProvidersBillingCount(billingMonth)


    const handleClick = () => {
        CreateStripeSession()
    }

    const handleClickStripeCustomerPortal = () => {
        CreateStripeCustomerPortalSession()
    }

    const [showPerChannel, setShowPerChannel] = React.useState<string | null>(null);

    const changeShowPerChannel = (event: React.MouseEvent<HTMLElement>, showPerChannel: string | null) => {
        setShowPerChannel(showPerChannel);
    };

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Box className={classes.loginWrapper}>
                <Typography variant={'h4'} className={classes.loginText} m={3}><b>{t('billing')}</b></Typography>
                <Alert sx={{mb: 3}} severity={'info'}>
                    {t('billing_page_description')}
                </Alert>
                <Stack direction={"row"} gap={2}>
                    {
                        <Button variant="alarmButton" sx={{color: "white",
                            '&:hover': { // changes colors for button hover state
                                backgroundColor: '#FF6A63',
                                cursor: "default"
                            }}} size={"large"} startIcon={<SellOutlined/>}>
                            {stripeBookingPriceConfiguration?.priceType == 'FLAT_RATE' &&
                                `${Number(stripeBookingPriceConfiguration?.price).toFixed(2)} ${stripeBookingPriceConfiguration?.currency} ${t('per_month')}`
                            }
                            {stripeBookingPriceConfiguration?.priceType == 'USAGE_BASED' &&
                                `${Number(stripeBookingPriceConfiguration?.price).toFixed(2)} ${stripeBookingPriceConfiguration?.currency} ${t('per_booking')}`
                            }
                        </Button>
                    }
                    {!member?.stripeSubscriptionId &&
                        <Button variant="walkInButton" size={"large"} startIcon={<CreditCardOutlined/>}
                                onClick={handleClick}>
                            {t('subscribe_via_stripe')}
                        </Button>
                    }
                    {member?.stripeSubscriptionId &&
                        <Button variant="walkInButton" size={"large"} startIcon={<CreditCardOutlined/>}
                                onClick={handleClickStripeCustomerPortal}>
                            {t('manage_payment_details_using_stripe')}
                        </Button>
                    }
                </Stack>
            </Box>
            <Grid item xs={12} mt={5} sx={{backgroundColor: 'white', width: '100%'}}>
                <Grid item mb={10} mt={2}>
                    <Box style={{float: 'left', marginLeft: 20}}>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup value={showPerChannel} exclusive onChange={changeShowPerChannel}>
                                <ToggleButton value="left">
                                    {t('show_per_channel')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>
                    </Box>
                    <Box style={{float: 'right'}}>
                        <ArrowDatePicker
                            unit={'months'}
                            inputFormat={"MMM, YYYY"}
                            views={['month', 'year']}
                            onChange={(date: Date) => setBillingMonth(moment(date))}
                            selectedDate={billingMonth.toDate()}/>
                    </Box>
                </Grid>
                <Grid item sx={{width: '100%'}}>
                    <Paper sx={{width: '100%'}}>
                        {showPerChannel &&
                            <ServiceProvidersBillingTableWithChannels
                                serviceProvidersBillingCount={ServiceProvidersBillingCount}/>
                        }
                        {!showPerChannel &&
                            <ServiceProvidersBillingTable serviceProvidersBillingCount={ServiceProvidersBillingCount}/>}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}