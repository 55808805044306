import React, { useEffect, useState } from 'react'
import List from '@mui/material/List'
import { navigation } from 'config/Navigation'
import { Link, useLocation } from 'react-router-dom'
import { ListItem, useMediaQuery } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { navigationStyles } from 'theme/navigation.styles'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
// @ts-ignore
import logo from '../../logo.svg'
// @ts-ignore
import logoSmall from 'logo_small.svg'
import { isMemberAdminOrOwner } from 'config/auth'
import { useMember } from 'hooks/members/useMember'
import { Drawer } from 'components/Drawer'
import { toast } from 'react-toastify'


export const Navigation = () => {
  const {t} = useTranslation()
  const classes = navigationStyles()
  const greaterThan1300 = useMediaQuery("(min-width:1300px)")
  const greaterThan767 = useMediaQuery("(min-width:767px)")
  const [open, setOpen] = useState(greaterThan1300)

  const isAdmin = isMemberAdminOrOwner()
  const {member} = useMember()

  const isServiceProvider = !!member?.defaultServiceProviderId
  //DON'T DELETE rerenders component based on location change.
  const location = useLocation()
  const toggleDrawer = () => {
    if (greaterThan767) {
      setOpen(!open)
    }
  }
  const openSpError = () => {
    toast.error(`${t('add_service_provider_first')}`, {
      position: toast.POSITION.TOP_RIGHT
    })
  }

  useEffect(() => {
    setOpen(greaterThan1300);
  }, [greaterThan1300]);

  return (
    <Drawer variant="permanent" open={open} className={'sidebar-highlight'}>
      <Box component="img" alt="Skipper Logo large" src={open ? logo : logoSmall} className={classes.logoLg} onClick={toggleDrawer}/>
      {/*<Box component="img" alt="Skipper Logo small" src={logoSmall} className={classes.logoSm}/>*/}
      <List>
        {navigation.filter(item => !item.protected || (item.protected && isAdmin)).map((item) => {
          const isActive = item.route.split("/")[0] == window.location.pathname.substring(1).split("/")[0]

          {
            if (isServiceProvider) {
              return (
                <Link to={item.route} key={item.id} className={`${classes.link}`}>
                  <ListItem button className={isActive ? `${item.route} ${classes.active}` : `${item.route} ${classes.hover}`}>
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={t(`${item.label}`)}/>
                  </ListItem>
                </Link>
              )
            } else {
              return (
                <ListItem key={item.id} onClick={openSpError} button
                          className={isActive ? `${item.route} ${classes.active}` : `${item.route} ${classes.hover}`}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(`${item.label}`)}/>
                </ListItem>
              )
            }
          }

        })}
      </List>
    </Drawer>
  )
}