import {useQuery} from 'react-query'
import {checkFeedbackUnauthorized } from "../api/public/publicapi";

export const useCheckFeedbackUnauthorized = (bookingId:number | undefined, token: string | null) => {

  const {data: UseCheckFeedback, isLoading} = useQuery('feedback',
      async () => await checkFeedbackUnauthorized(bookingId, token),
  )

  return {UseCheckFeedback, isLoading}
}