import React from 'react'
import {
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import {PluginProps, PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import {DatePicker, LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {
    AccessTimeOutlined,
    AddOutlined,
    DateRangeOutlined,
    PeopleOutline, PeopleOutlined,
    RemoveOutlined, SettingsSuggestOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {DATE_FORMAT, DATE_FORMAT_MASK, MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS} from 'config/const'
import 'moment/locale/de'
import {convertHexToRGBA} from "../../config/Utils";


export const HorizontalPluginDemo = ({mainColor, secondaryColor, iconColor}: PluginProps) => {
    const classes = PluginStyles({
        mainColor: `${convertHexToRGBA(mainColor, 0.5)}`,
        secondaryColor: secondaryColor,
        iconColor: iconColor})
    const {t, i18n} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''

    return (
        <>
            <Grid container spacing={0} direction="column" className={classes.horizontalMainWrapper}>
                <Grid container direction="row" spacing={0} justifyContent="space-between">
                    <Grid item width={100}>
                        <Box component="img" alt="Skiper Logo" src={'/textlogo.png'}
                             className={classes.logo}/>
                    </Grid>
                    <Grid container spacing={0}
                          width={'80%'}
                          direction="row"
                          alignItems="center"
                          justifyContent="left">
                        <Grid container direction="row" xs={2} sx={{margin: "5px"}}>
                            <PeopleOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                <IconButton style={{backgroundColor: 'transparent'}}
                                            className={classes.horizontalIcon}>
                                    <RemoveOutlined style={{backgroundColor: 'transparent'}}/>
                                </IconButton>

                                <Typography variant={'body1'}>1</Typography>
                                <IconButton style={{backgroundColor: 'transparent'}}
                                            className={classes.horizontalIcon}>
                                    <AddOutlined style={{backgroundColor: 'transparent'}}/>
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid container direction="row" xs={2} sx={{margin: "5px"}}>
                            <TableRestaurantOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                            <Select sx={{ml:"10px", width:100}} value={'INDOOR'} fullWidth size={'small'} disabled={true}
                                    variant={'standard'}>
                                <MenuItem value={"INDOOR"}>{t('INDOOR')}</MenuItem>
                                <MenuItem value={"OUTDOOR"}>{t('OUTDOOR')}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid container direction="row" xs={2.5} sx={{margin: "5px"}}>
                            <DateRangeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                            <Box mt={1}>
                                <LocalizationProvider dateAdapter={AdapterMoment}
                                                      locale={i18n.language}>
                                    <DatePicker
                                        disabled={true}
                                        onChange={() => {
                                            void 0
                                        }}
                                        value={new Date()}
                                        inputFormat={DATE_FORMAT}
                                        mask={DATE_FORMAT_MASK}
                                        PaperProps={{
                                            sx: {marginTop: '-230px', marginLeft: '-180px'}
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                                                            variant={'standard'}
                                                                            style={{width: 130}}
                                                                            size={'small'}/>}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid container direction="row" xs={2} sx={{margin: "5px"}}>
                            <AccessTimeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                            <Select sx={{ml:"10px"}} disabled={true} value={'18:00'} size={'small'}
                                    variant={'standard'}>
                                <MenuItem value={'18:00'}>{'18:00'}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={5.3} sm={0.8} md={4} lg={2} sx={{margin: '5px'}}>
                            <Button className={classes.submitButton} sx={{
                                width: "100",
                                backgroundColor: `${secondaryColor}`,
                                color: 'white !important',
                                transition: 'all .4s ease !important',
                                '&:hover': { // changes colors for button hover state
                                    opacity: '0.7 !important',
                                    backgroundColor: `${secondaryColor}`
                                }
                            }}>{t('next')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item width={100}>
                        <Select disabled={true} value={getCurrentLng().toLowerCase()} className={classes.horizontalLanguageSwitch}>
                            <MenuItem value={"en"}>EN</MenuItem>
                            <MenuItem value={"de"}>DE</MenuItem>
                            <MenuItem value={"ka"}>GE</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
