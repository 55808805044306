import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

type props = {
  documentUrl: string
}

export const LegalViewer = ({documentUrl}: props) => {
  const {t, i18n} = useTranslation()

  const englishName = documentUrl + ".html"
  const germanName = documentUrl + "_de.html"

  useEffect(() => {
    if (i18n.language) {
      setPdfUrl(i18n.language == 'en' ? englishName : germanName)
    }
  }, [i18n.language])


  const [pdfUrl, setPdfUrl] = useState<string>()

  return (
    <>
      <iframe src={pdfUrl} width="100%" height="1000px" style={{marginTop: 50}} frameBorder={0}/>
    </>
  )
}
