import * as React from 'react';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person'
import { useNavigate } from 'react-router-dom'
import { unSetJwtToken } from 'config/auth'
import { useTranslation } from 'react-i18next'
import { EditMemberDetailsDrawer } from 'components/drawers/EditMemberDetailsDrawer'
import { MemberType } from 'types/Members'
import { CreditCardOutlined, EmailOutlined, KeyOutlined } from '@mui/icons-material'
import { UpdatePasswordDrawer } from 'components/drawers/UpdatePasswordDrawer'
import { Button } from '@mui/material'
import {showGoogleRecaptchaBadge} from "../config/Utils";

type props = {
  member: MemberType | undefined
}

export const ProfileMenu = ({member}: props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null)
  const [openProfileEditTable, setOpenProfileEditTable] = useState(false)
  const [openUpdatePasswordTable, setOpenUpdatePasswordTable] = useState(false)

  const openProfile = Boolean(anchorElProfile)
  const isPro = !member?.planExpiryDate
  const titleStr: string = t('profile_settings')

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget)
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null)
  };

  const logout = () => {
    showGoogleRecaptchaBadge()
    unSetJwtToken()
    navigate("/login")
  }

  const handleProfileEdit = () => {
    setOpenProfileEditTable(!openProfileEditTable)
  }

  const handleUpdatePassword = () => {
    setOpenUpdatePasswordTable(!openUpdatePasswordTable)
  }


  return (
    <React.Fragment>
      <Tooltip title={titleStr}>
        <IconButton
          color="inherit"
          className={'profile-icon'}
          sx={{color: '#A4AFB7', ml: 1}}
          size={"large"}
          onClick={handleClickProfile}
          aria-controls={openProfile ? 'profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openProfile ? 'true' : undefined}
        >
          <PersonIcon/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElProfile}
        id="profile-menu"
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={() => handleProfileEdit()}>
          <ListItemIcon>
            <PersonIcon fontSize="small"/>
          </ListItemIcon>
          {t('profile')}
        </MenuItem>
        <MenuItem onClick={() => handleUpdatePassword()}>
          <ListItemIcon>
            <KeyOutlined fontSize="small"/>
          </ListItemIcon>
          {t('update_password')}
        </MenuItem>
        {member?.memberRole === "OWNER" &&
            <MenuItem onClick={() => navigate('billing')}>
              {isPro ? <>
                  <ListItemIcon>
                    <CreditCardOutlined fontSize="small"/>
                  </ListItemIcon>
                  {t('billing')}
                </> :
                <Button variant={'alarmButton'} fullWidth>{t('upgrade_to_pro')}</Button>
              }
            </MenuItem>
        }
        <Divider/>
        <MenuItem>
          <ListItemIcon>
            <EmailOutlined fontSize="small"/>
          </ListItemIcon>
          <a style={{color: 'inherit', textDecoration: 'none'}} href={'mailto:business@skiper.io'}>
            {t('contact_support')}
          </a>
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small"/>
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
      <EditMemberDetailsDrawer member={member} openEditMember={openProfileEditTable} setOpenEditMember={setOpenProfileEditTable}/>
      <UpdatePasswordDrawer member={member} openUpdatePassword={openUpdatePasswordTable} setOpenUpdatePassword={setOpenUpdatePasswordTable}/>
    </React.Fragment>
  );
}
