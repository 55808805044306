import React, { createContext, useReducer } from 'react'
import { OpeningHoursAction, OpeningHoursStateType } from 'types/ServiceProvider'
import { INITIAL_OPENING_HOURS_STATE, OpeningHoursReducer } from 'context/OpeningHoursReducer'

type OpeningHoursContextType = {
  openingHoursFromContext: OpeningHoursStateType
  dispatch: (action: OpeningHoursAction) => void
}


const OpeningHoursContext = createContext<OpeningHoursContextType>({} as OpeningHoursContextType)

export const OpeningHours = ({children}: any) => {
  const [openingHours, dispatch] = useReducer(OpeningHoursReducer, INITIAL_OPENING_HOURS_STATE)


  return (
    <OpeningHoursContext.Provider value={{openingHoursFromContext: openingHours, dispatch}}>
      {children}
    </OpeningHoursContext.Provider>
  )
}

export default OpeningHoursContext