import React, {useEffect, useMemo} from 'react'
import {Button, Grid} from '@mui/material'
import {useForm} from 'react-hook-form'
import {ServiceProviderType} from 'types/ServiceProvider'
import {getUserId} from 'config/auth'
import {useUpdateServiceProvider} from 'hooks/useUpdateServiceProvider'
import {useTranslation} from 'react-i18next'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {serviceProviderAddFormSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {useChannels} from 'hooks/serviceProvider/useChannels'
import {InputValues} from 'components/settings/ServiceProvider/InputValues'
import {DisabledValues} from 'components/settings/ServiceProvider/DisabledValues'
import {EditableValues} from 'components/settings/ServiceProvider/EditableValues'


export const EditServiceProvider = () => {
    const {t} = useTranslation()
    const schema = useMemo(() => serviceProviderAddFormSchema(), [i18n.language]);
    const {serviceProvider} = useServiceProvider()
    const serviceProviderWithTags = useMemo(() => ({
        ...serviceProvider,
        tagIds: serviceProvider?.tags ? serviceProvider?.tags?.map((tag: any) => ({
            value: tag.id,
            label: tag.name
        })) : [],
        paymentMethodIds: serviceProvider?.paymentMethods ? serviceProvider?.paymentMethods.map((paymentMethod: any) => ({
            value: paymentMethod.id,
            label: paymentMethod.name
        })) : []
    }), [serviceProvider])

    const {handleSubmit, control, reset} = useForm<ServiceProviderType>({
        defaultValues: serviceProviderWithTags,
        resolver: yupResolver(schema)
    })
    const userId = getUserId()
    const {UpdateServiceProvider} = useUpdateServiceProvider()
    const {isSPEditable} = useChannels()

    const submitHandler = (values: ServiceProviderType) => {

        const defaultTags = values.tagIds ? values.tagIds.map((tag: any) => tag.value) : []
        const paymentMethodsValues = values.paymentMethodIds ? values.paymentMethodIds.map((paymentMethod: any) => paymentMethod.value) : []
        if (isSPEditable) {
            UpdateServiceProvider(
                {
                    ...values,
                    tagIds: defaultTags,
                    paymentMethodIds: paymentMethodsValues,
                    ownerId: userId
                } as ServiceProviderType
            )
        } else {
            UpdateServiceProvider(
                {
                    fbAccount: values.fbAccount,
                    instagramAccount: values.instagramAccount,
                    tagIds: defaultTags,
                    paymentMethodIds: paymentMethodsValues,
                    averageTimePerCustomer: values.averageTimePerCustomer,
                    pricingRange: values.pricingRange,
                    bookingEmailsEnabled: values.bookingEmailsEnabled,
                    blockSameDayBookings: values.blockSameDayBookings,
                    bookingNotificationEmail: values.bookingNotificationEmail,
                    wheelchairSupport: values.wheelchairSupport
                } as ServiceProviderType
            )
        }
    }

    useEffect(() => {
        if (serviceProvider) {
            reset({
                ...serviceProvider,
                tagIds: serviceProvider.tags ? serviceProvider.tags.map((tag: any) => ({
                    value: tag.id,
                    label: tag.name
                })) : [],
                paymentMethodIds: serviceProvider?.paymentMethods ? serviceProvider?.paymentMethods.map((paymentMethod: any) => ({
                    value: paymentMethod.id,
                    label: paymentMethod.name
                })) : []
            })
        }
    }, [serviceProvider])

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={3}>

                {isSPEditable ? <InputValues control={control}/> : <DisabledValues/>}
                <EditableValues control={control}/>
                <Grid item xs={12}>
                    <Button variant={'contained'} fullWidth type={'submit'}>{t('submit')}</Button>
                </Grid>

            </Grid>
        </form>
    )
}