import React, { useEffect, useMemo } from "react"
import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { MemberType, UpdateMemberType } from 'types/Members'
import { useUpdateMemberDetails } from 'hooks/members/useUpdateMemberDetails'
import { memberEditFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'


type props = {
  openEditMember: any
  setOpenEditMember: any
  member: MemberType | undefined
}

export const EditMemberDetailsDrawer = ({openEditMember, setOpenEditMember, member}: props) => {
  const {t} = useTranslation();
  const schema = useMemo(() => memberEditFormSchema(), [i18n.language]);

  const {EditMemberDetails} = useUpdateMemberDetails()
  const {handleSubmit, control, reset} = useForm<UpdateMemberType>({defaultValues: member, resolver: yupResolver(schema)})
  const toggleDrawer = () => {
    reset(member)
    setOpenEditMember(!openEditMember)
  }

  useEffect(() => {
    reset(member);
  }, [member]);

  const submitHandler = (values: UpdateMemberType) => {

    EditMemberDetails({...values, id: member?.id})
    setOpenEditMember(!openEditMember)
  }

  return (
    <Drawer open={openEditMember} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h6'}>{t('update_member_details')}</Typography>
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} name={'firstName'} label={'first_name'}/>
            <TextFieldController control={control} name={'lastName'} label={'last_name'}/>
            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('update')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>
  )
}