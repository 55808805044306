import React from "react"
import { Alert, Button, Container, Divider, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { CancelBooking } from 'types/Bookings'
import { useCancelBooking } from 'hooks/bookings/useCancelBooking'

type props = {
  openCancelBooking: any
  setOpenCancelBooking: any
  bookingId?: number
}

export const CancelBookingDrawer = ({openCancelBooking, setOpenCancelBooking, bookingId}: props) => {
  const {t} = useTranslation();

  const {CancelBooking} = useCancelBooking()
  const {handleSubmit, control, reset} = useForm<CancelBooking>({})
  const toggleDrawer = () => {
    reset()
    setOpenCancelBooking(!openCancelBooking)
  }

  const submitHandler = (values: CancelBooking) => {
    CancelBooking({id: bookingId, comment: values.comment})
    reset()
    setOpenCancelBooking(!openCancelBooking)
  }

  return (
    <Drawer open={openCancelBooking} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Alert severity={'warning'}>{t('cancel_warning')}</Alert>
          <Typography variant={'h6'}>{t('cancel_booking')}</Typography>
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} multiline name={'comment'} label={'comment'}/>
            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('cancel')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>
  )
}