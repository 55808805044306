import React, {useEffect} from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from "react-i18next";

const SITE_KEY = "6LfFX1olAAAAAF9W5T9eePr4_C9hOQStM0yZp6M8";

type props = {
    values: any,
    mutationFN: any,
    showText?: boolean,
    callback?: () => void
}

const ReCaptchaV3 = ({values, mutationFN, showText = false, callback = () => {return true}}: props) => {
    const {t} = useTranslation()

    useEffect((() => {
        if (values) {
            // @ts-ignore
            window.grecaptcha.ready(() => {
                // @ts-ignore
                window.grecaptcha.execute(SITE_KEY, {action: 'submit'}).then((token: any) => {
                    mutationFN({...values, "recaptchaToken": token})
                })
            })
        }
    }), [values])

    useEffect(() => {
        const loadScriptByURL = (id: any, url: any, callback: any) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.recaptcha.net/recaptcha/api.js?render=${SITE_KEY}`, callback);
    }, []);

    return (
        <>{showText &&
            <Typography style={{fontSize: '10px', color: 'grey'}} variant={"subtitle2"}>
                {t('google_recaptcha_1')}
                <a href="https://policies.google.com/privacy">{t('google_recaptcha_2')}</a> {t('google_recaptcha_3')} <a
                href="https://policies.google.com/terms">{t('google_recaptcha_4')}</a> {t('google_recaptcha_5')}
            </Typography>
        }
        </>
    )
}

export default ReCaptchaV3