import React, { PropsWithChildren, useRef } from 'react';
import { Button, CircularProgress, Grid, Stack } from '@mui/material'
import { UploadFileLanguageType, UploadFileTypes } from 'types/ServiceProvider'
import { useTranslation } from 'react-i18next'
import { useUploadMenu } from 'hooks/serviceProvider/useUploadMenu'
import { useUploadVideo } from 'hooks/serviceProvider/useUploadVideo'
import { useUploadImage } from 'hooks/serviceProvider/useUploadImage'
import {useUploadLogo} from "../../hooks/serviceProvider/useUploadLogo";


type props = {
  fileType: UploadFileTypes
  label: string
  acceptedTypes: string
  lang?: UploadFileLanguageType
}

const CustomFileUploadButton = ({children, fileType, label, acceptedTypes, lang}: PropsWithChildren<props>) => {
  const {t} = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null);
  const {UploadMenu} = useUploadMenu(lang)
  const {UploadVideo, isLoading} = useUploadVideo()
  const {UploadImage} = useUploadImage()
  const {UploadLogo} = useUploadLogo()

  const handleUpload = (event: any) => {
    event.preventDefault()
    switch (fileType) {
      case 'SERVICE_PROVIDER_MENU':
        UploadMenu(event.target.files[0])
        break
      case 'SERVICE_PROVIDER_VIDEO':
        UploadVideo(event.target.files[0])
        break
      case 'SERVICE_PROVIDER_IMAGE':
        UploadImage(event.target.files[0])
        break;
      case 'SERVICE_PROVIDER_LOGO':
        UploadLogo(event.target.files[0])
        break;
    }
  }

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item xs={fileType === 'SERVICE_PROVIDER_IMAGE' ? 12 : 5}>
        <input type={'file'} ref={inputRef} hidden accept={acceptedTypes} onChange={handleUpload}/>
        <Button variant={'contained'} fullWidth size={'small'} onClick={handleUploadClick}>{t(label)}</Button>
      </Grid>
      <Grid item xs={7}>
        {isLoading ?
          <Stack direction={'column'} style={{width: '80%', height: '150px'}} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress/>
          </Stack>
          :
          children
        }
      </Grid>
    </Grid>
  );
};

export default CustomFileUploadButton;
