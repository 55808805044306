import {makeStyles} from '@mui/styles'

export const arrowDatePickerStyles = makeStyles({
        dayWrapper: {
            position: "relative",
        },
        day: {
            width: 36,
            height: 36,
            margin: "0 2px",
            color: "inherit",
        },
        customDayHighlight: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: "2px",
            right: "2px",
            // border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: "50%",
        },
        highlightNonCurrentMonthDay: {
            color: "#676767",
        },
        highlight: {
            background: '',
            color: 'white'
        },
        firstHighlight: {
            extend: "highlight",
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
        },
        endHighlight: {
            extend: "highlight",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
        },
        pickerWrapper: {
            width:'100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    }
);