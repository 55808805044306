import React, { createContext, useEffect, useReducer, useState } from 'react'
import { INITIAL_PLUGIN_STATE, PluginReducer } from 'plugin/PluginReducer'
import moment from 'moment/moment'
import { ISO_DATE_FORMAT } from 'config/const'
import { useServiceProviderStyles } from 'plugin/useServiceProviderStyles'
import { PLUGIN_ICON_COLOR, PLUGIN_MAIN_COLOR, PLUGIN_SECONDARY_COLOR } from 'theme/Plugin.styles'
import {useParams} from "react-router-dom";

type BookingContextType = {
  selectedDateTime: string
  bookings: any
  page: PageType
  setPage: React.Dispatch<React.SetStateAction<PageType>>
  dispatch: (action: any) => void
  mainColor: string
  secondaryColor: string
  iconColor: string
  isStylesCalled: boolean
}


type PageType = {
  guestNumber: boolean
  guestInfo: boolean
  bookDetails: boolean
  bookSuccess: boolean
}

const PluginContext = createContext<BookingContextType>({} as BookingContextType)

export const PluginProvider = ({children}: any) => {
  const [bookings, dispatch] = useReducer(PluginReducer, INITIAL_PLUGIN_STATE)
  const {id} = useParams()
  const serviceProviderId = Number(id)
  const {serviceProviderStyles} = useServiceProviderStyles(serviceProviderId)

  const [mainColor, setMainColor] = useState<string>(PLUGIN_MAIN_COLOR)
  const [secondaryColor, setSecondaryColor] = useState<string>(PLUGIN_SECONDARY_COLOR)
  const [iconColor, setIconColor] = useState<string>(PLUGIN_ICON_COLOR)
  const [isStylesCalled, setIsStylesCalled] = useState<boolean>(false)
  useEffect(() => {
    if (serviceProviderStyles && serviceProviderStyles.mainColor) {
      setMainColor(serviceProviderStyles.mainColor)
      setSecondaryColor(serviceProviderStyles.secondaryColor)
      setIconColor(serviceProviderStyles.iconColor)
    }
    setIsStylesCalled(true)
  }, [serviceProviderStyles])


  const selectedDateTime = `${moment(bookings.add_plugin_bookings.startDate).format(ISO_DATE_FORMAT)}T${bookings.add_plugin_bookings.startTime}`

  const [page, setPage] = useState({
    guestNumber: true,
    guestInfo: false,
    bookDetails: false,
    bookSuccess: false
  })
  return (
    <PluginContext.Provider value={{selectedDateTime, bookings, page, setPage, dispatch,
      mainColor, secondaryColor, iconColor, isStylesCalled}}>
      {children}
    </PluginContext.Provider>
  )
}

export default PluginContext