import React, {useContext, useState} from "react"
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {useRestaurantTables} from 'hooks/restaurant/useRestaurantTables'
import RestaurantTableRow from 'components/RestaurantTableRow'
import {bookingsTablesType, restaurantTablesStatus, tableVipOptions} from 'config/Options'
import {RestaurantTablesFilters} from 'components/restaurantTables/RestaurantTablesFilters'
import {
    SELECT_PAGE_FILTER_TABLES,
    SELECT_PAGE_SIZE_FILTER_TABLES,
    SELECT_STATUS_FILTER_TABLES,
    SELECT_TYPE_FILTER_TABLES,
    SELECT_VIP_FILTER_TABLES
} from 'context/restaurantTables/RestaurantTablesReducer'
import RestaurantTablesContext from 'context/restaurantTables/RestaurantTablesContext'
import {TableDrawer} from 'components/drawers/TableDrawer'
import {useServiceProvider} from "../hooks/useServiceProvider";


export const ConfigureTables = () => {

    const {t} = useTranslation()
    const [openConfigureTablesDrawer, setOpenConfigureTablesDrawer] = useState(false)
    const {serviceProvider} = useServiceProvider()

    const {restaurantTablesFilter, dispatch} = useContext(RestaurantTablesContext)

    const {restaurantTables} = useRestaurantTables(restaurantTablesFilter)

    const defaultValues = {
        id: 0,
        number: 0,
        seats: 0,
        minGuests: 0,
        fillable: false,
        vip: false,
        type: ''
    }


    const handleEdit = () => {
        setOpenConfigureTablesDrawer(!openConfigureTablesDrawer)
    }

    const handlePageChange = (page: number) => {
        dispatch({type: SELECT_PAGE_FILTER_TABLES, payload: page})
    }

    const handlePageSizeChange = (size: string) => {
        dispatch({type: SELECT_PAGE_SIZE_FILTER_TABLES, payload: Number(size)})
    }

    return (
        <>
            <Grid item>
                <Button variant={'contained'} onClick={() => handleEdit()}>{t('add_table')}</Button>
            </Grid>
            <Grid container spacing={2} my={2}>
                <Grid item>
                    <RestaurantTablesFilters options={restaurantTablesStatus} translateLabel={true}
                                             defaultValue={restaurantTablesFilter.status}
                                             type={SELECT_STATUS_FILTER_TABLES}
                                             label={'status'}/>
                </Grid>
                <Grid item>
                    <RestaurantTablesFilters options={bookingsTablesType} translateLabel={true}
                                             defaultValue={restaurantTablesFilter.type}
                                             type={SELECT_TYPE_FILTER_TABLES}
                                             label={'type'}/>
                </Grid>
                <Grid item>
                    <RestaurantTablesFilters options={tableVipOptions} translateLabel={true}
                                             defaultValue={restaurantTablesFilter.vip}
                                             type={SELECT_VIP_FILTER_TABLES}
                                             label={'vip'}/>
                </Grid>
            </Grid>

            <Grid container>
                <Paper sx={{width: '100%', padding: 2}}>
                    <Typography variant={'h5'}>{t('tables_overview')}</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                                <TableRow>
                                    <TableCell>{t('table')}</TableCell>
                                    <TableCell>{t('seats')}</TableCell>
                                    <TableCell>{t('min_guests')}</TableCell>
                                    <TableCell>{t('type')}</TableCell>
                                    <TableCell>{t('fillable')}</TableCell>
                                    <TableCell>{t('vip')}</TableCell>
                                    {serviceProvider?.wheelchairSupport &&
                                        <TableCell>{t('wheelchair_support')}</TableCell>
                                    }
                                    <TableCell>{t('status')}</TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {restaurantTables?.content.map((table, index) => (
                                    <RestaurantTableRow key={index} index={index} table={table}/>
                                ))}
                                {openConfigureTablesDrawer && <TableDrawer table={defaultValues}
                                                                           openDrawer={openConfigureTablesDrawer}
                                                                           setOpenDrawer={setOpenConfigureTablesDrawer}
                                                                           edit={false}
                                />
                                }
                            </TableBody>
                        </Table>
                        {restaurantTables && <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40]}
                            component="div"
                            labelRowsPerPage={t('rows_per_page')}
                            count={restaurantTables?.totalElements}
                            rowsPerPage={restaurantTables ? restaurantTables.size : 10}
                            page={restaurantTables?.number || 0}
                            onPageChange={(event: any, page: number) => handlePageChange(page)}
                            onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
                        />}
                    </TableContainer>
                </Paper>
            </Grid>


        </>
    )
}