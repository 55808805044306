import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {useTranslation} from 'react-i18next'
import {Typography} from "@mui/material";
import {useDisableServiceProviderChannel} from "../hooks/serviceProvider/useDisableServiceProviderChannel";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type props = {
    open: any,
    setOpen: any
}

export const DisableGoogleChannelModal = ({open, setOpen}: props) => {
    const {t} = useTranslation()

    const {DisableServiceProviderChannel} = useDisableServiceProviderChannel()

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        DisableServiceProviderChannel({
            channelName: "GOOGLE"
        })
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="disable-google-channel-modal"
            fullWidth={true}
        >
            <DialogTitle style={{textAlign: 'center'}}>{t("google_reserve_opt_out_confirmation")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="disable-google-channel-modal-id">
                    <Typography>{t('google_reserve_opt_out_text')}</Typography>
                    <br/>
                    <Typography>{t('google_reserve_opt_out_text_2')}</Typography>
                    <br/>
                    <br/>
                    <Typography>{t('google_reserve_opt_out_are_you_sure')}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: "center"}}>
                <Button type="submit" variant="contained" onClick={handleConfirm}>
                    {t('yes')}
                </Button>

                <Button type="submit" variant="contained" onClick={handleClose}>
                    {t('no')}
                </Button>

            </DialogActions>
        </Dialog>
    );
}
