import {useMutation} from 'react-query'
import React from 'react'
import {CancelBookingType} from "../types/Bookings";
import {cancelBookingUnauthorized, unsubscribeUnauthorized} from "../api/public/publicapi";
import {UnsubscribeType} from "../types/General";

export const useUnsubscribeUnauthorized = (bookingId:number | undefined) => {

  const {mutate: UseUnsubscribe, isSuccess, isLoading} = useMutation((payload: UnsubscribeType) => {
      return unsubscribeUnauthorized(payload, bookingId);
    }
  )

  return {UseUnsubscribe, isSuccess, isLoading}
}