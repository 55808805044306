import React, {useCallback, useContext, useMemo} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText, Grid,
    Grow, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import {TextFieldController} from 'components/form/TextFieldController'
import {PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {PluginSchema} from 'config/YupSchemaValidations'
import PluginContext from 'plugin/PluginContext'
import {PLUGIN_GUEST_DETAILS, PLUGIN_SELECT_TABLE_TYPE} from 'plugin/PluginReducer'
import {Link} from 'react-router-dom'
import {EmailOutlined, PersonOutlined, PhoneIphoneOutlined, TableRestaurantOutlined} from '@mui/icons-material'
import {PhoneNumberController} from "../../components/form/PhoneNumberController";


export const GuestDetails = () => {
    const {t, i18n} = useTranslation()
    const schema = useMemo(() => PluginSchema(), [i18n.language]);
    const {bookings, dispatch, mainColor, secondaryColor, iconColor} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })


    const {handleSubmit, control} = useForm({
        defaultValues: bookings.add_plugin_bookings.guestDetails,
        resolver: yupResolver(schema)
    })
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const guestDetails = bookings.add_plugin_bookings.guestDetails
    const {page, setPage} = useContext(PluginContext)

    const submitHandler = (values: any) => {
        dispatch({type: PLUGIN_GUEST_DETAILS, payload: values})
        setPage({
            guestNumber: false,
            guestInfo: false,
            bookDetails: true,
            bookSuccess: false
        })
    }

    const handleClick = useCallback((input: string) => {
        if (input === 'back') {
            setPage({
                guestNumber: true,
                guestInfo: false,
                bookDetails: false,
                bookSuccess: false
            })
        }
    }, [])

    return (
        <form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={0}
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'>
                <Grid container alignItems='center' direction='row' sm={5.8} md={3.8} lg={3} xl={2}
                      sx={{marginLeft: '5px'}}>
                    <PersonOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Grid item sx={{width: '80%', marginLeft: '5px'}}>
                        <TextFieldController control={control} defaultValue={guestDetails.guestName}
                                             size={'small'} name={'guestName'} placeholder={'name'}
                                             variant={'standard'}/>
                    </Grid>
                </Grid>
                <Grid container alignItems='center' direction='row' sm={5.8} md={3.8} lg={4} xl={2}
                      sx={{marginLeft: '5px'}}>
                    <EmailOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Grid item sx={{width: '80%', marginLeft: '5px'}}>
                        <TextFieldController control={control} defaultValue={guestDetails.email} variant={'standard'}
                                             size={'small'} name={'email'} placeholder={'email'}/>
                    </Grid>
                </Grid>
                <Grid container alignItems='center' direction='row' sm={5.8} md={3.8} lg={2.5} xl={2}
                      sx={{marginLeft: '5px'}}>
                    <PhoneIphoneOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Grid item sx={{width: '80%', marginLeft: '5px'}}>
                        <PhoneNumberController control={control} defaultValue={guestDetails.email}
                                               size={'small'} name={'guestMobile'}
                                               variant={'standard'}/>
                    </Grid>
                </Grid>
                <Grid item sx={{margin: '5px'}} sm={5.8} md={6} xl={3}>
                    <Controller control={control} name={'legalAgreement'}
                                defaultValue={guestDetails.legalAgreement}
                                render={({field: {onChange}, fieldState: {error}}) =>
                                    <FormControl error={!!error}>
                                        <FormControlLabel style={{marginLeft: -8, marginRight: 0}}
                                                          className={classes.legalAgreement}
                                                          control={<Checkbox
                                                              defaultChecked={guestDetails.legalAgreement}
                                                              onChange={onChange} color='primary'/>}
                                                          label={
                                                              <Box sx={{fontSize: 12}}>
                                                                  <span>{t('agree_on_legal_terms_and_conditions_1')}</span>
                                                                  <Link target='_blank'
                                                                        to={'/data-privacy?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_2')}</Link>
                                                                  <span>{t('agree_on_legal_terms_and_conditions_3')}</span>
                                                                  <Link target='_blank'
                                                                        to={'/terms-and-conditions?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_4')}</Link>
                                                              </Box>
                                                          }/>
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                }
                    />
                </Grid>
                <Grid container direction='row' md={5.6} xl={2} justifyContent={'right'} sx={{margin: '5px'}}>
                    <Grid item>
                        <Button sx={{
                            width: 100,
                            height: "40px",
                            marginLeft: '5px',
                            backgroundColor: `${secondaryColor}`,
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }}
                                onClick={() => handleClick('back')}>{t('back')}</Button>

                    </Grid>
                    <Grid item>
                        <Button type={'submit'} sx={{
                            width: 100,
                            height: "40px",
                            marginLeft: '5px',
                            backgroundColor: `${secondaryColor}`,
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }}>{t('next')}</Button>
                    </Grid>
                </Grid>
            </Grid>


        </form>

    )
}
