import * as yup from 'yup'
import i18n from 'i18next'
import moment from 'moment/moment'

export const loginFormSchema = () =>
  yup.object().shape({
    email: yup.string().email(i18n.t('email_validation')).required(i18n.t('email_is_required')),
    password: yup.string().required(i18n.t('password_is_required')),
  });

export const registrationFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('firstname_is_required')),
    lastName: yup.string().required(i18n.t('lastname_is_required')),
    email: yup.string().email(i18n.t('email_validation')).required(i18n.t('email_is_required')),
    password: yup.string().required(i18n.t('password_is_required')).min(8, i18n.t('min_password_length')).max(20, i18n.t('max_password_length')),
    legalAgreement: yup.boolean().oneOf([true], i18n.t('legal_agreement_is_required'))
  });

export const forgotPasswordFormSchema = () =>
  yup.object().shape({
    email: yup.string().email(i18n.t('email_validation')).required(i18n.t('email_is_required')),
  });

export const resetForgottenPasswordFormSchema = () =>
  yup.object().shape({
    password: yup.string().required(i18n.t('new_password_is_required')).min(8, i18n.t('min_password_length')).max(20, i18n.t('max_password_length')),
    confirmedPassword: yup.string().oneOf([yup.ref('password'), null], i18n.t('new_password_does_not_match'))
  });

export const memberAddFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18n.t('firstname_is_required')),
    lastName: yup.string().required(i18n.t('lastname_is_required')),
    email: yup.string().email(i18n.t('email_validation')).required(i18n.t('email_is_required')),
    memberRole: yup.string().required(i18n.t('member_role_is_required')),
  });

export const memberEditFormSchema = () => {
  return yup.object().shape({
    firstName: yup.string().required(i18n.t('firstname_is_required')),
    lastName: yup.string().required(i18n.t('lastname_is_required')),
  });
}

export const memberUpdateRoleFormSchema = () => {
  return yup.object().shape({
    role: yup.string().required(i18n.t('member_role_is_required')),
  });
}

export const updatePasswordSchema = () =>
  yup.object({
    currentPassword: yup.string().required(i18n.t('current_password_is_required')),
    password: yup.string().required(i18n.t('new_password_is_required')).min(8, i18n.t('min_password_length')).max(20, i18n.t('max_password_length')),
    confirmedPassword: yup.string().oneOf([yup.ref('password'), null], i18n.t('new_password_does_not_match'))
  });

export const tableFormSchema = () =>
  yup.object().shape({
    number: yup.number().typeError(i18n.t('table_number_should_be_number')).required(i18n.t('table_number_is_required')).min(1, i18n.t('minimum_table_number')),
    seats: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required(i18n.t('table_seats_is_required')).min(1, i18n.t('minimum_table_seats')),
    minGuests: yup.number().required(i18n.t('min_guests_is_required'))
      .when('seats', {
        is: (number: number) => number || number !== 0,
        then: yup.number().max(yup.ref("seats"), i18n.t('min_number_exceeds')).transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        otherwise: yup.number().typeError(i18n.t('table_number_should_be_number')).transform((value) => (isNaN(value) ? undefined : value)).nullable()
      }),
    type: yup.string().required(i18n.t('table_type_is_required')),
  });


const phoneRegExp = new RegExp('\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$')

export const serviceProviderWebsiteSchema = () =>
  yup.object().shape({
    websiteUrl: yup.string().required(i18n.t('service_provider_website_is_required')),
  })

export const serviceProviderAddFormSchema = () =>
  yup.object().shape({
    name: yup.string().required(i18n.t('service_provider_name_is_required')),
    registrationNumber: yup.string().nullable(),
    phoneNumber: yup.string().trim().matches(phoneRegExp, i18n.t('phone_number_is_invalid')).required(i18n.t('phone_number_is_required')),
    averageTimePerCustomer: yup.number().min(1, i18n.t('minimum_average_time_per_booking')).required(i18n.t('average_time_per_booking_is_required'))
      .typeError('').transform((value) => (isNaN(value) ? undefined : value)),
    description: yup.string().required(i18n.t('description_is_required')),
    websiteUrl: yup.string().nullable(),
    address: yup.object({
      country: yup.string().required(i18n.t('country_required')),
      state: yup.string().nullable(),
      city: yup.string().required(i18n.t('city_required')),
      streetNumber: yup.string().required(i18n.t('street_number_is_required')),
      streetName: yup.string().required(i18n.t('street_name_is_required')),
      postalCode: yup.string().required(i18n.t('postal_code_is_required')),
    }),
    bookingNotificationEmail: yup.string().default(null)
      // eslint-disable-next-line
      .matches(/^(([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
          { excludeEmptyString: true, message: i18n.t('emails_must_be_seperated') })
      .nullable(),
  });

export const bookingAddFormSchema = () =>
  yup.object().shape({
    guestName: yup.string().required(i18n.t('customer_name_is_required')),
    numberOfGuests: yup.number().min(1, i18n.t('minimum_guests_number')).required(i18n.t('minimum_guests_number_required')),
    guestMobile: yup.string().matches(phoneRegExp, i18n.t('phone_number_is_invalid')).nullable(),
    guestEmail: yup.string().email(i18n.t('email_validation')).nullable(),
    comment: yup.string().nullable(),
  });

export const availabilitySchema = () =>
    yup.object().shape({
    advanceReservationTimeUnit: yup.string().nullable(),
    advanceReservationTime: yup.number().transform((value) => (isNaN(value) ? 0 : value)).when('advanceReservationTimeUnit', {
        is: 'H',
        then: yup.number().max(48, i18n.t('max_reservation_time_message_hours')),
        otherwise: yup.number().max(90, i18n.t('max_reservation_time_message_minutes')),
    }),
})

export const feedbackSchema = () =>
    yup.object().shape({
        foodAndDrinksRating: yup.number().min(1,'minimum_rating').required(i18n.t('required')),
        atmosphereAndServiceRating: yup.number().min(1,'minimum_rating').required(i18n.t('required')),
        experienceRating: yup.number().min(1,'minimum_rating').nullable(),
    });

export const walkInAddFormSchema = () =>
  yup.object().shape({
    numberOfGuests: yup.number().min(1, i18n.t('minimum_guests_number')).required(i18n.t('minimum_guests_number_required')),
  });

export const bookingEditFormSchema = () =>
  yup.object().shape({
    guestName: yup.string().required(i18n.t('customer_name_is_required')),
    guestMobile: yup.string().matches(phoneRegExp, i18n.t('phone_number_is_invalid')).nullable(),
    guestEmail: yup.string().email(i18n.t('email_validation')).nullable(),
    comment: yup.string().nullable(),
  });


export const PluginSchema = () =>
  yup.object().shape({
    guestName: yup.string().required(i18n.t('customer_name_is_required')),
    email: yup.string().email(i18n.t('email_validation')).when('guestMobile', {
      is: (guestMobile: string) => !guestMobile || guestMobile.length === 0,
      then: yup.string().email(i18n.t('email_validation')).required(i18n.t('fill_email_or_phone')).nullable(),
      otherwise: yup.string().nullable()
    }),
    guestMobile: yup.string().transform(value => !value ? null : value).nullable().matches(phoneRegExp, i18n.t('phone_number_is_invalid')),
    legalAgreement: yup.boolean().oneOf([true], i18n.t('legal_agreement_is_required'))
  }, [['email', 'guestMobile']]);


const isBefore = (startTime: string, endTime: string) => {
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
}

const isAfter = (startTime: string, endTime: string) => {
  return moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'));
}

export const openingHoursFormSchema = () =>
  yup.object().shape({
    days: yup.array().of(
      yup.object().shape({
        dayOfWeek: yup.string(),
        startTime: yup.string().ensure()
          .when(["endTime"], {
            is: (endTime: string) => endTime !== '',
            then: yup.string().required(i18n.t('start_time_required')).nullable(),
            otherwise: yup.string().nullable(),
          })
          .test("start_time_test", i18n.t('start_time_must_be_before_end_time'),
            function () {
              const {startTime, endTime} = this.parent;
              if (startTime && endTime) {
                return isBefore(startTime, endTime);
              }
              return true
            }
          ),
        endTime: yup.string().ensure()
          .when(["startTime"], {
            is: (startTime: string) => startTime !== '',
            then: yup.string().required(i18n.t('end_time_required')).nullable(),
            otherwise: yup.string().nullable(),
          }),
        breakStartTime: yup.string().nullable()
          .test("start_end_time_exists", i18n.t('break_start_time_blank'),
            function () {
              const {startTime, endTime, breakStartTime} = this.parent;
              return !(!startTime && !endTime && breakStartTime);

            }
          )
          .test("break_start_end_time_after_test", i18n.t('break_start_end_time_after'),
            function () {
              const {startTime, endTime, breakStartTime, breakEndTime} = this.parent;
              if (startTime && endTime && breakStartTime && breakEndTime) {
                return isBefore(breakEndTime, endTime) && isAfter(breakStartTime, startTime)
              }
              return true
            }
          )
          .test("break_start_time_test", i18n.t('break_start_time_before_break_end_time'),
            function () {
              const {breakStartTime, breakEndTime} = this.parent;
              if (breakStartTime && breakEndTime) {
                return isBefore(breakStartTime, breakEndTime);
              }
              return true
            }
          )
          .test("break_end_time_exists", i18n.t('break_start_time_required'),
            function () {
              const {breakStartTime, breakEndTime} = this.parent;
              if (breakEndTime) {
                return breakStartTime;
              }
              return true;
            }
          ),
        breakEndTime: yup.string().nullable()
          .test("start_end_time_exists", i18n.t('break_end_time_blank'),
            function () {
              const {startTime, endTime, breakEndTime} = this.parent;
              return !(!startTime && !endTime && breakEndTime);

            }
          )
          .test("break_start_time_test", i18n.t('break_start_time_before_break_end_time'),
            function () {
              const {breakStartTime, breakEndTime} = this.parent;
              if (breakStartTime && breakEndTime) {
                return isBefore(breakStartTime, breakEndTime);
              }
              return true
            }
          )
          .test("break_start_time_exists", i18n.t('break_end_time_required'),
            function () {
              const {breakStartTime, breakEndTime} = this.parent;
              if (breakStartTime) {
                return breakEndTime;
              }
              return true;
            }
          ),
      }, [["startTime", "endTime"]])
    )
  })