import { makeStyles } from '@mui/styles'

export const registrationStyles = makeStyles({
  registrationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  registrationText: {
    letterSpacing: '2.4px',
  },
  textField: {
    backgroundColor: '#F7F7F7',
  },
  registrationButton: {
    marginTop: '10px !important' as "marginTop",
    borderRadius: '4px',
    height: '50px',
    fontSize: '16px !important' as "fontSize"
  },
  legalAgreement: {
    color: '#7699CE'
  },
  formWrapper: {
    width: 440
  }
})
