import React, { useContext, useState } from "react"
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useMembers } from 'hooks/members/useMembers'
import MemberTableRow from 'components/MemberTableRow'
import { AddMemberDrawer } from 'components/drawers/AddMemberDrawer'
import MembersContext from 'context/members/MembersContext'
import { SELECT_PAGE_MEMBERS, SELECT_PAGE_SIZE_MEMBERS } from 'context/members/MembersReducer'

export const ConfigureMembers = () => {
  const {t} = useTranslation()
  const [openConfigureMemberDrawer, setOpenConfigureMemberDrawer] = useState(false)
  const {dispatch} = useContext(MembersContext)
  const {members} = useMembers()

  const handleEdit = () => {
    setOpenConfigureMemberDrawer(!openConfigureMemberDrawer)
  }

  const handlePageChange = (page: number) => {
    dispatch({type: SELECT_PAGE_MEMBERS, payload: page})
  }

  const handlePageSizeChange = (size: string) => {
    dispatch({type: SELECT_PAGE_SIZE_MEMBERS, payload: Number(size)})
  }
  return (
    <>

      <Grid item my={2}>
        <Button variant={'contained'} onClick={() => handleEdit()}>{t('add_member')}</Button>
      </Grid>

      <Grid container>
        <Paper sx={{width: '100%', padding: 2}}>
          <Typography variant={'h5'} mb={2}>{t('members_overview')}</Typography>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                <TableRow>
                  <TableCell>{t('full_name')}</TableCell>
                  <TableCell>{t('email')}</TableCell>
                  <TableCell>{t('role')}</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {members?.content.map((member, index) => (
                  <MemberTableRow key={index} index={index} member={member}/>
                ))}
                {openConfigureMemberDrawer && <AddMemberDrawer openBooking={openConfigureMemberDrawer} setOpenBooking={setOpenConfigureMemberDrawer}/>}
              </TableBody>
            </Table>
            {members && <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                component="div"
                labelRowsPerPage={t('rows_per_page')}
                count={members?.totalElements}
                rowsPerPage={members ? members.size : 10}
                page={members?.number || 0}
                onPageChange={(event: any, page: number) => handlePageChange(page)}
                onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
            />}
          </TableContainer>
        </Paper>
      </Grid>
    </>
  )
}