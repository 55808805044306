import { useQuery } from 'react-query'
import { ServiceProviderType } from 'types/ServiceProvider'
import { getServiceProviders } from 'api/private/serviceProviders'
import { getUserId } from 'config/auth'


export const useServiceProviders = () => {
  const userId = getUserId()

  const fallback: ServiceProviderType[] = []
  const {data: serviceProviders = fallback, isSuccess, refetch} = useQuery<ServiceProviderType[], Error>(['service-providers', userId],
    async () => await getServiceProviders(userId),
    {
      enabled: !!userId,
    }
  )
  return {serviceProviders, isSuccess, refetch}
}