import React from "react"
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { resendVerificationLink } from 'theme/ResendVerificationLink'
import { useResendActivationEmail } from 'hooks/useResendActivationEmail'
import { useResendPasswordResetEmail } from 'hooks/useResendPasswordResetEmail'
// @ts-ignore
import portalLogo from "../portal_logo.svg";

type props = {
  isForgotPassword: boolean
}
export const ResendVerificationLink = ({isForgotPassword}: props) => {

  const [searchParams] = useSearchParams();
  const {t} = useTranslation()
  const classes = resendVerificationLink()

  const {ResendActivationEmail} = useResendActivationEmail()
  const {ResendPasswordResetEmail} = useResendPasswordResetEmail()

  const handleOnClick = () => {
    if (isForgotPassword) {
      // @ts-ignore
      ResendPasswordResetEmail({email: searchParams.get("email")})
    } else {
      // @ts-ignore
      ResendActivationEmail({email: searchParams.get("email")})
    }
  }

  return (
    <>
      <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh">
        <Box className={classes.resendVerificationLinkWrapper}>
          <Box component="img" alt="Skiper Portal Logo" src={portalLogo} style={{width:200, marginBottom: 20}}/>

          <Typography variant={'h4'} m={2}>{t('email_link_expired')}</Typography>

          <Stack direction={'column'} className={classes.formWrapper}>
            <Button onClick={handleOnClick} type="submit" fullWidth variant="contained" className={classes.resendVerificationLinkButton}>
              {t('resend_email')}
            </Button>
          </Stack>
        </Box>
      </Grid>
    </>
  )
}