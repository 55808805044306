import {useQuery} from 'react-query'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {getBookings} from 'api/private/bookings'
import moment from 'moment/moment'
import BookingContext from 'context/bookings/BookingContext'
import {ISO_DATE_TIME_FORMAT} from "../../config/const";

interface BookingsPayload {
  startDate: string,
  endDate: string,
  status?: string
  numberOfGuests?: number
  guestName?: string
  tableType?: string
  tableNumber?: number
  vip?: string
  page: number
  size: number
}

export const useBookings = (bookingsCount: number) => {
  const {bookings} = useContext(BookingContext)
  const startTime = bookings.currentBookings.startTime ? bookings.currentBookings.startTime : '00:00'
  const endTime = bookings.currentBookings.endTime ? bookings.currentBookings.endTime : '23:30'

  const selectedDateTime = `${bookings.currentBookings.startDate}T${startTime}`
  const selectedEndDateTime = `${bookings.currentBookings.startDate}T${endTime}`

  const payload: BookingsPayload = {
    startDate: moment(selectedDateTime).format(ISO_DATE_TIME_FORMAT),
    endDate: moment(selectedEndDateTime).format(ISO_DATE_TIME_FORMAT),
    page: bookings.currentBookings.page,
    size: bookings.currentBookings.perPage
  }

  if (bookings.currentBookings.seats) {
    payload.numberOfGuests = bookings.currentBookings.seats
  }
  if (bookings.currentBookings.tableNumber) {
    payload.tableNumber = bookings.currentBookings.tableNumber
  } else if (bookings.currentBookings.tableType) {
    payload.tableType = bookings.currentBookings.tableType
  }
  if (bookings.currentBookings.guestName) {
    payload.guestName = bookings.currentBookings.guestName
  }
  if (bookings.currentBookings.status) {
    payload.status = bookings.currentBookings.status
  }
  if (bookings.currentBookings.vip) {
    payload.vip = bookings.currentBookings.vip
  }
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const {data: bookingValues, isSuccess, isError, refetch} = useQuery(['bookings', {defaultServiceProviderId, payload}],
    () => getBookings(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId
    })

  return {bookingValues, isSuccess, isError, refetch}
}