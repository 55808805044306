import {useMutation} from 'react-query'
import {putUpdatePassword} from 'api/private/members'
import {UpdatePasswordType} from 'types/Members'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {useLogin} from "../useLogin";
import {useMember} from "./useMember";
import {useNavigate} from "react-router-dom";

export const useUpdatePassword = () => {
    const {t} = useTranslation()
    const {Login} = useLogin()
    const {member} = useMember()
    const navigate = useNavigate()

    const {mutate: UpdatePassword} = useMutation((payload: UpdatePasswordType) => (putUpdatePassword(payload)),
        {
            onSuccess: async (data, payload) => {
                toast.success(`${t('password_updated_success')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })

                navigate("/")
            },
            onError: async () => {
                toast.error(`${t('password_updated_error')}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        })

    return {UpdatePassword}
}