import React, {useCallback, useMemo} from 'react'
import moment, {DurationInputArg2} from "moment";
import {Box, IconButton, TextField} from "@mui/material";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {useTranslation} from "react-i18next";
import 'moment/locale/de'
import {CalendarPickerView} from "@mui/x-date-pickers/internals/models";


export const ArrowDatePicker = (props: {
    onChange: (date: Date) => void
    unit: DurationInputArg2
    inputFormat: string
    selectedDate: Date | null
    views: CalendarPickerView[]
}) => {
    const {t, i18n} = useTranslation()

    const onChange = props?.onChange
    const selectedDate: Date | null = useMemo(() => props?.selectedDate, [props?.selectedDate])

    const changeSelectedDateWithDay = useCallback((dateOffSet: number) => {
        onChange(moment(selectedDate).add(dateOffSet, props.unit).toDate())
    }, [selectedDate, onChange])

    return (
        <Box sx={{width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <IconButton
                aria-label='back-arrow'
                size={'small'}
                onClick={() => changeSelectedDateWithDay(-1)}>
                <ArrowBack/>
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
                <DatePicker
                    disableMaskedInput
                    views={props.views}
                    value={selectedDate}
                    onChange={date => onChange(moment(date).toDate())}
                    inputFormat={props.inputFormat}
                    renderInput={(params) => <TextField {...params} variant={'outlined'} style={{width: 165}} size={'small'}/>}
                >
                </DatePicker>
            </LocalizationProvider>
            <IconButton
                size={'small'}
                aria-label='forward-arrow'
                onClick={() => changeSelectedDateWithDay(1)}>
                <ArrowForward/>
            </IconButton>
        </Box>
    )
}
