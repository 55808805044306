import React, {useContext, useEffect, useState} from 'react'
import {Box, Grid, MenuItem, Select, Stack} from '@mui/material'
// @ts-ignore
import logo from 'skiperio-logo.png'
import {useTranslation} from 'react-i18next'
import {PluginStyles} from 'theme/Plugin.styles'
import {TableDetails} from 'plugin/steps/TableDetails'
import {GuestDetails} from 'plugin/steps/GuestDetails'
import PluginContext from 'plugin/PluginContext'
import {BookDetails} from 'plugin/steps/BookDetails'
import {BookSuccess} from 'plugin/steps/BookSuccess'
import moment from "moment/moment";
import 'moment/locale/de'
import {hideGoogleRecaptchaBadgeForPlugin} from "../config/Utils";
import {useGeolocation} from "../hooks/useGeolocation";

export const Plugin = () => {
    const {t, i18n} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''
    moment.updateLocale(getCurrentLng(), {week: {dow: 1}})

    useEffect(() => {
        hideGoogleRecaptchaBadgeForPlugin()
    }, [])

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            moment.updateLocale(lang, {week: {dow: 1}})
        })
    }
    const {page, mainColor, secondaryColor, iconColor, isStylesCalled} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const {GeoLocationInfo} = useGeolocation()

    useEffect(() => {
        if (GeoLocationInfo?.country_code == "GE") {
            i18n.changeLanguage("ka")
        } else if (GeoLocationInfo?.country_code == "GB") {
            i18n.changeLanguage("en")
        } else if (GeoLocationInfo?.country_code == "DE") {
            i18n.changeLanguage("de")
        }
    }, [GeoLocationInfo]);

    return (
        <>
            {isStylesCalled &&
                <Grid container spacing={0} direction="column" sx={{maxWidth: 350, maxHeight: 550}}>
                    <Box className={classes.mainWrapper}>
                        <Box className={classes.inputWrapper} alignItems={'end'}>
                            <Stack direction={'row'}>
                                {showErrorMessage ?
                                    <Box sx={{flexGrow: 1}}/>
                                    :
                                    <Box component="img" alt="Skiper Logo" src={'/textlogo.png'}
                                         className={classes.logo}/>
                                }
                                <Select value={getCurrentLng().toLowerCase()} className={classes.languageSwitch}
                                        onChange={(e) => changeLanguageHandler(e.target.value.toLowerCase())}>
                                    <MenuItem value={"en"}>EN</MenuItem>
                                    <MenuItem value={"de"}>DE</MenuItem>
                                    <MenuItem value={"ka"}>GE</MenuItem>
                                </Select>
                            </Stack>
                            <Stack direction={'column'}>
                                {page.guestNumber && <TableDetails setShowErrorMessage={setShowErrorMessage}/>}
                                {page.guestInfo && <GuestDetails/>}
                                {page.bookDetails && <BookDetails/>}
                                {page.bookSuccess && <BookSuccess/>}
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
            }
        </>
    )
}