import React from 'react';
import { Control, Controller } from 'react-hook-form'

type props = {
  control: Control<any>
  name: string
  defaultValue?: string
}

const HiddenInputController = ({control, name, defaultValue}: props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field}) => (
        <input type="hidden" {...field} />
      )}
    />
  );
};

export default HiddenInputController;
