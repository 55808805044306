import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, IconButton, Tooltip } from '@mui/material'
import CustomFileUploadButton from 'components/network/CustomFileUploadButton'
import { useImages } from 'hooks/serviceProvider/useImages'
import { CancelOutlined } from '@mui/icons-material'
import useDeleteFile from 'hooks/serviceProvider/useDeleteFile'
import { useTranslation } from 'react-i18next'
import { ImageType } from 'types/ServiceProvider'
import useUpdateImageSortOrder from 'hooks/serviceProvider/useUpdateImageSortOrder'

const UploadImages = () => {
  const {t} = useTranslation();
  const {images} = useImages('SERVICE_PROVIDER_IMAGE')
  const {DeleteFile} = useDeleteFile('SERVICE_PROVIDER_IMAGE')

  const [imageList, setImageList] = useState<ImageType[]>([])
  const [currentImage, setCurrentImage] = useState<any>(null)
  const [highlighted, setHighlighted] = useState(false)

  const {UpdateImageOrder} = useUpdateImageSortOrder()
  useEffect(() => {
    if (images) {
      setImageList(images)
    }
  }, [images])


  const dragHandler = (type: string, card: any) => {
    if (type === 'start') {
      setCurrentImage(card)
    } else if (type === 'over') {
      setHighlighted(true)
    } else if (type === 'end') {
      const sortedImages = {images: imageList.map(image => ({id: image.id, sortOrder: image.sortOrder}))}
      setHighlighted(false)
      UpdateImageOrder(sortedImages)
    }
  }

  const drop = (card: any) => {
    const sort = imageList?.map(image => {
      if (image.id === card.id) {
        return {...image, sortOrder: currentImage.sortOrder}
      }
      if (image.id === currentImage.id) {
        return {...image, sortOrder: card.sortOrder}
      }
      return image
    })
    setImageList(sort)

  }

  return (
    <Grid container spacing={1}>
      <Alert severity={'info'}>{t('image_sort_tip')}</Alert>
      <Grid item xs={12}>
        <CustomFileUploadButton fileType={'SERVICE_PROVIDER_IMAGE'} label={'upload_image'} acceptedTypes={'image/png, image/jpeg'}/>
      </Grid>

      {imageList && imageList.map((file: any) => {
        return (
          <Box
            onDragStart={() => dragHandler('start', file)}
            onDragEnd={() => dragHandler('end', null)}
            onDragOver={(e) => {
              e.preventDefault()
              dragHandler('over', file)
            }}
            onDrop={(e) => {
              e.preventDefault()
              drop(file)
            }}
            draggable={true}
            sx={{
              cursor: 'grab',
              backgroundColor: highlighted ? 'lightGrey' : 'white',
              border: '1px solid #85E6CC',
              borderRadius: 1,
              padding: 1,
              position: 'relative'
            }}
            key={file.id} width={80} height={80} mx={2} my={1}>
            <Tooltip title={`${t('delete')}`} placement="top" onClick={() => DeleteFile(file.id)}>
              <IconButton style={{position: 'absolute', top: -10, right: -13, backgroundColor: 'white', padding: 0, borderRadius: 50}}>
                <CancelOutlined color={'error'}/>
              </IconButton>
            </Tooltip>
            <img src={file.fileUrl} alt="image" style={{width: '100%', height: 'auto', objectFit: 'cover', objectPosition: 'center'}}/>
          </Box>
        )
      })}

    </Grid>
  );
};

export default UploadImages;
