import * as React from 'react'
import BarChartIcon from '@mui/icons-material/BarChart'
import ManOutlinedIcon from '@mui/icons-material/ManOutlined';
import { AddCardOutlined, EventOutlined, TableRestaurantOutlined } from "@mui/icons-material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SettingsIcon from '@mui/icons-material/Settings'


type Navigation = {
  id: number
  icon: JSX.Element
  label: string
  route: string
  protected: boolean
}

export const navigation: Navigation[] = [
  {
    id: 0,
    icon: <BarChartIcon/>,
    label: 'dashboard',
    route: '',
    protected: false
  },
  {
    id: 1,
    icon: <AddCardOutlined/>,
    label: 'add_booking',
    route: 'add-booking',
    protected: false
  },
  {
    id: 2,
    icon: <AddBusinessIcon/>,
    label: 'add_walk_in',
    route: 'add-walk-in',
    protected: false
  },
  {
    id: 3,
    icon: <EventOutlined/>,
    label: 'bookings',
    route: 'current-bookings',
    protected: false
  },
  {
    id: 4,
    icon: <TableRestaurantOutlined/>,
    label: 'configure_tables',
    route: 'configure-tables',
    protected: true
  },
  {
    id: 5,
    icon: <ManOutlinedIcon/>,
    label: 'configure_members',
    route: 'configure-members',
    protected: true
  },
  {
    id: 6,
    icon: <SettingsIcon/>,
    label: 'network_and_settings',
    route: 'settings/network',
    protected: true
  }
]