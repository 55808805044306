import React, { useCallback } from "react";
import { AttachMoneyOutlined } from '@mui/icons-material'
import { Box, Rating, Stack, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ratings = [
  {value: 1, label: 'price_one_to_twenty'},
  {value: 2, label: 'price_twenty_one_to_thirty'},
  {value: 3, label: 'price_thirty_one_to_fifty'},
  {value: 4, label: 'price_fifty_to_more'},
];

type props = {
  control: Control<any>
  name: string
  defaultValue: number
}
const PriceRangeSelector = ({name, control, defaultValue}: props) => {
  const {t} = useTranslation()
  const getText = useCallback((value: number) => {
    return ratings.find(item => item.value == value)?.label || ''
  }, [])


  return (
    <Box p={1}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({field}) => (
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant={'body2'} my={1}>{t('select_price_range')}</Typography>
            <Rating
              {...field}
              onChange={(event, value) => field.onChange(value)}
              icon={<AttachMoneyOutlined fontSize="inherit"/>}
              emptyIcon={<AttachMoneyOutlined fontSize="inherit"/>}
              max={4}
            />
            <Typography>{t(getText(field.value))}</Typography>
          </Stack>
        )}
      />

    </Box>
  )
}

export default PriceRangeSelector