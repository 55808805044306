import { useMutation } from 'react-query'
import { ResendEmailInputType } from 'types/Auth'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { resendPasswordResetEmail } from 'api/public/publicapi';
import { useTranslation } from 'react-i18next'
import React from 'react'

export const useResendPasswordResetEmail = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {mutate: ResendPasswordResetEmail} = useMutation((payload: ResendEmailInputType) => {
      return resendPasswordResetEmail(payload);
    },
    {
      onSuccess: async () => {
        toast.success(`${t('resend_activation_email_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
        navigate("/")
      },
      onError: async () => {
        toast.error(`${t('resend_activation_email_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )

  return {ResendPasswordResetEmail}
}