import React, {Dispatch, SetStateAction, useEffect, useMemo} from 'react'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
    Typography
} from '@mui/material'
import {RestaurantTable, RestaurantTablesContent} from 'types/Restaurant'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {tableFormSchema} from 'config/YupSchemaValidations'
import i18n from 'i18next'
import {useCreateRestaurantTable} from 'hooks/restaurant/useCreateRestaurantTable'
import {TextFieldController} from 'components/form/TextFieldController'
import {AutocompleteController} from 'components/form/AutocompleteController'
import {bookingsTablesType} from 'config/Options'
import {useTranslation} from 'react-i18next'
import {useUpdateRestaurantTable} from 'hooks/restaurant/useUpdateRestaurantTable'
import CheckboxController from "components/form/CheckboxController";
import {useServiceProvider} from "../../hooks/useServiceProvider";

type props = {
    openDrawer: boolean
    setOpenDrawer: Dispatch<SetStateAction<boolean>>
    table: RestaurantTable
    edit?: boolean
}

export const TableDrawer = ({openDrawer, setOpenDrawer, table, edit}: props) => {
    const {t} = useTranslation();
    const schema = useMemo(() => tableFormSchema(), [i18n.language]);

    const {handleSubmit, control, reset, setValue, watch} = useForm<RestaurantTablesContent>({
        defaultValues: table,
        resolver: yupResolver(schema)
    })

    const toggleDrawer = () => {
        reset(table)
        setOpenDrawer(!openDrawer)
    }
    const {serviceProvider} = useServiceProvider()

    const {AddTable} = useCreateRestaurantTable()
    const {EditTable} = useUpdateRestaurantTable()
    const [seats] = watch(['seats', 'minGuests'])
    const submitHandler = (values: RestaurantTablesContent) => {
        if (edit) {
            EditTable({...values, id: table.id})
            reset()
        } else {
            AddTable({...values})
            reset()
        }
        setOpenDrawer(!openDrawer)
    }

    useEffect(() => {
        if (seats) {
            if (edit) {
                setValue('minGuests', table.minGuests)
            } else {
                // @ts-ignore
                setValue('minGuests', (seats / 2).toFixed())
            }
        }
    }, [seats])

    return (
        <Drawer open={openDrawer} onClose={toggleDrawer}>
            <Container maxWidth={'sm'} sx={{paddingY: 2}}>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <Typography variant={'h6'}>{edit ? t('edit_table') : t('add_new_table')}</Typography>
                    <Divider variant="fullWidth"/>
                    <Stack direction={'column'} my={2}>
                        <TextFieldController control={control} type={'number'} name={'number'} label={'table_number'}/>
                        <TextFieldController control={control} type={'number'} name={'seats'} label={'seats'}/>
                        <Box>
                            <Alert severity={'info'}>{t('min_guests_tooltip')}</Alert>
                            <TextFieldController control={control} type={'number'} name={'minGuests'}
                                                 label={'min_guests'}/>
                        </Box>
                        <AutocompleteController control={control} defaultValue={table.type} name={'type'}
                                                label={'select_type'}
                                                options={bookingsTablesType}/>

                        <Alert severity={'info'}>{t('fillable_tooltip')}</Alert>
                        <Controller name={'fillable'} control={control}
                                    render={({field: {onChange}, fieldState: {error}}) =>
                                        <FormControl error={!!error}>
                                            <FormControlLabel
                                                control={<Checkbox checked={watch('fillable')} onChange={(e) => {
                                                    onChange(e.target.checked)
                                                }} color="primary"/>} label={<>{t('fillable')}</>}/>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    }
                        />
                        <Alert severity={'info'}>{t('vip_tooltip')}</Alert>
                        <Controller name={'vip'} control={control}
                                    render={({field: {onChange}, fieldState: {error}}) =>
                                        <FormControl error={!!error}>
                                            <FormControlLabel
                                                control={<Checkbox checked={watch('vip')} onChange={(e) => {
                                                    onChange(e.target.checked)
                                                }} color="primary"/>} label={<>{t('vip')}</>}/>
                                            <FormHelperText>{error?.message}</FormHelperText>
                                        </FormControl>
                                    }
                        />
                        {serviceProvider?.wheelchairSupport &&
                            <CheckboxController control={control} name={'wheelchairSupport'}
                                                label={'wheelchair_support'}/>
                        }
                        <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
                    </Stack>
                </form>
            </Container>
        </Drawer>
    )
}