import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getJwtToken } from 'config/auth'

export const PrivateRoute = () => {
  const auth = getJwtToken() != null

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet/> : <Navigate to="/login"/>
}