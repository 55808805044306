import React, {useCallback, useState} from 'react';
import {IconButton, TableCell, TableRow, Tooltip} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {BookingType} from 'types/Bookings'
import {EditBookingDetailsDrawer} from 'components/drawers/EditBookingDetailsDrawer'
import {CloseBookingDrawer} from 'components/drawers/CloseBookingDrawer'
import {CancelBookingDrawer} from 'components/drawers/CancelBookingDrawer'
import {BookingDetailedInformationDrawer} from 'components/drawers/BookingDetailedInformationDrawer'
import {
  AccessibleOutlined,
  CancelOutlined,
  DirectionsWalkOutlined,
  DoneOutlined,
  EditOutlined,
  Google,
  InfoOutlined,
  LanguageOutlined,
  PhoneCallbackOutlined,
  PhoneIphoneOutlined
} from '@mui/icons-material'
import moment from 'moment/moment'
import {TIME_FORMAT} from 'config/const'
import {convertToUTCAndValidateBookingTime} from 'config/Utils'
// @ts-ignore
import logoSmall from 'logo_small.svg'
import Box from "@mui/material/Box";
import {getTooltipByChannel} from "./ToolTipByChannel";


type props = {
  index: number
  booking: BookingType
}

const BookingRow = ({index, booking}: props) => {
  const {t} = useTranslation();

  const [openEditBookingDetails, setOpenEditBookingDetails] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [openCloseBooking, setOpenCloseBooking] = useState(false)
  const [openCancelBooking, setOpenCancelBooking] = useState(false)
  const handleEdit = () => {
    setOpenEditBookingDetails(!openEditBookingDetails)
  }
  const getColorByBookingStatus = useCallback((bookingStatus: string | undefined): string => {
    if (bookingStatus == "COMPLETED") {
      return '#2e7d32'
    } else if (bookingStatus == "CANCELLED") {
      return '#FF6A63'
    }
    return ''
  }, [])

  return (
    <>
      <TableRow key={index}>
        <TableCell>{booking.tableNumber}
          {booking.wheelchairSupport &&
              <Tooltip title={`${t('wheelchair_support')}`} placement="top">
                <IconButton style={{paddingBottom: '10px'}}>
                  <AccessibleOutlined color={'info'}/>
                </IconButton>
              </Tooltip>
          }
        </TableCell>
        <TableCell>
          {getTooltipByChannel(booking.channel)}
        </TableCell>
        <TableCell>{booking.numberOfGuests}</TableCell>
        <TableCell>{moment(booking.bookingTime).format(TIME_FORMAT)}</TableCell>
        <TableCell>{moment(booking.bookingEndTime).format(TIME_FORMAT)}</TableCell>
        <TableCell>{booking.guestName}</TableCell>
        <TableCell style={{color: getColorByBookingStatus(booking.status)}} >{t(booking.status? booking.status : "")}</TableCell>
        {/*WHY hiding icons*/}
        {booking.channel !== 'WALK_IN' ?
          <TableCell padding={'checkbox'}>
            <Tooltip title={`${t('details')}`} placement="top" onClick={() => setOpenDetails(!openDetails)}>
              <IconButton>
                <InfoOutlined color={'info'}/>
              </IconButton>
            </Tooltip>
          </TableCell>
          :
          <TableCell/>
        }

        {booking.channel !== 'WALK_IN' && booking.status == 'ACTIVE' && convertToUTCAndValidateBookingTime(booking.bookingTime) ?
          <TableCell padding={'checkbox'}>
            <Tooltip title={`${t('update')}`} placement="top" onClick={handleEdit}>
              <IconButton>
                <EditOutlined/>
              </IconButton>
            </Tooltip>
          </TableCell>
          :
          <TableCell/>
        }

        {booking.status == 'ACTIVE' ?
          <TableCell padding={'checkbox'}>
            <Tooltip title={`${t('close')}`} placement="top" onClick={() => setOpenCloseBooking(!openCloseBooking)}>
              <IconButton>
                <DoneOutlined color={'success'}/>
              </IconButton>
            </Tooltip>
          </TableCell>
          :
          <TableCell/>
        }
        {booking.channel !== 'WALK_IN' && booking.status == 'ACTIVE' && convertToUTCAndValidateBookingTime(booking.bookingTime) ?
          <TableCell padding={'checkbox'}>
            <Tooltip title={`${t('cancel')}`} placement="top" onClick={() => setOpenCancelBooking(!openCancelBooking)}>
              <IconButton>
                <CancelOutlined color={'error'}/>
              </IconButton>
            </Tooltip>
          </TableCell>
          :
          <TableCell/>
        }

        <BookingDetailedInformationDrawer info={booking} openDetails={openDetails} setOpenDetails={setOpenDetails} page={'current-bookings'}/>
        {openEditBookingDetails && <EditBookingDetailsDrawer booking={booking} openEditBookingDetails={openEditBookingDetails} setOpenEditBookingDetails={setOpenEditBookingDetails}/>
        }
      </TableRow>
      <CloseBookingDrawer openCloseBooking={openCloseBooking} setOpenCloseBooking={setOpenCloseBooking} bookingId={booking.id}/>
      <CancelBookingDrawer openCancelBooking={openCancelBooking} setOpenCancelBooking={setOpenCancelBooking} bookingId={booking.id}/>
    </>
  )
}

export default BookingRow