import {
    ActivateMemberInputType,
    ForgotPasswordInputType,
    LoginInputType,
    RegisterInputType,
    ResendEmailInputType,
    ResetForgottenPasswordInputType
} from 'types/Auth'
import {axiosClient} from 'config/AxiosClients'
import {BookingUpdateTypeUnauthorized, CancelBookingType, CreateBookingTypeUnauthorized} from 'types/Bookings'
import {FeedbackType, UnsubscribeType} from "../../types/General";


const SERVICE_PROVIDERS = '/service-providers'

export const login = (payload: LoginInputType) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload

    return axiosClient.post('/login', payloadWithoutRecaptchaToken, {
        headers: {
            "recaptcha-response": payload.recaptchaToken
        }
    }).then(response => response.data['token'])
}

export const register = (payload: RegisterInputType) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload

    return axiosClient.post('/register', payloadWithoutRecaptchaToken, {
        headers: {
            "recaptcha-response": payload.recaptchaToken
        }
    })
}

export const forgotPassword = (payload: ForgotPasswordInputType) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload

    return axiosClient.post('/forgot-password', payloadWithoutRecaptchaToken, {
        headers: {
            "recaptcha-response": payload.recaptchaToken
        }
    })
}

export const resetForgottenPassword = (payload: ResetForgottenPasswordInputType) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload

    return axiosClient.post('/update-forgotten-password', payloadWithoutRecaptchaToken, {
      headers: {
          "recaptcha-response": payload.recaptchaToken
      }
  })
}

export const activateMember = (payload: ActivateMemberInputType) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload

    return axiosClient.post('/activate-member', payloadWithoutRecaptchaToken, {
      headers: {
          "recaptcha-response": payload.recaptchaToken
      }
  })
}

export const resendActivationEmail = (payload: ResendEmailInputType) => {
  return axiosClient.post('/resend-activation-email', payload)
}

export const resendPasswordResetEmail = (payload: ResendEmailInputType) => {
  return axiosClient.post('/resend-password-reset-email', payload)
}

export const getSpAvailableTimeslots = (serviceProviderId: number | undefined, payload: any) => {
  const params = payload
  return axiosClient.get(`get-sp-available-timeslots/${serviceProviderId}`, {params})
    .then(response => response.data)
}

export const postExternalBooking = (serviceProviderId: number | null, payload: CreateBookingTypeUnauthorized) => {
    const {recaptchaToken: _, ...payloadWithoutRecaptchaToken} = payload
    return axiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/external-booking`, payloadWithoutRecaptchaToken, {
        headers: {
            "recaptcha-response": payload.recaptchaToken
        }
    })
}

export const getExternalServiceProviderStyles = (serviceProviderId: number | undefined) => {
  return axiosClient.get(`/external-get-style/${serviceProviderId}`)
    .then(response => response.data)
}

export const getServiceProviderPluginDetails = (serviceProviderId: number | undefined, payload: any) => {
    const params = payload
    return axiosClient.get(`/sp-plugin-details/${serviceProviderId}`, {params})
        .then(response => response.data)
}

export const cancelBookingUnauthorized = (payload: CancelBookingType) => {
  return axiosClient.delete(`/bookings/${payload.bookingId}/cancel`, {data: {token: payload.token},
      headers: {
          "recaptcha-response": payload.recaptchaToken
      }
  })
}

export const unsubscribeUnauthorized = (payload: UnsubscribeType, bookingId:number | undefined) => {
    return axiosClient.post(`/unsubscribe/${bookingId}`, payload)
}

export const checkFeedbackUnauthorized = (bookingId:number|undefined, token:string | null) => {
    return axiosClient.get(`/feedback/${bookingId}?token=${token}`).then(response=> response.data)
}

export const feedbackUnauthorized = (payload: FeedbackType, bookingId:number | undefined) => {
    return axiosClient.post(`/feedback/${bookingId}`, payload)
}

export const getBookingUnauthorized = (bookingId: number | undefined, token: string | undefined) => {
  return axiosClient.get(`/bookings/${bookingId}?token=${token}`).then(response => response.data)
}

export const putUpdateBookingUnauthorized = (payload: BookingUpdateTypeUnauthorized) => {
  const { recaptchaToken: _, ...payloadWithoutRecaptchaToken } = payload
  return axiosClient.post(`/bookings/${payload.id}/update`, payloadWithoutRecaptchaToken, {
    headers: {
      "recaptcha-response": payload.recaptchaToken
    }
  })
}

export const getAllTags = () => {
    return axiosClient.get(`/tags`).then(response => response.data)
}

export const getAllPaymentMethods = () => {
    return axiosClient.get(`/payment-methods`).then(response => response.data)
}
