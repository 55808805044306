import {useQuery} from 'react-query'
import {getAvailableRestaurantTable} from 'api/private/restaurants'
import {RestaurantTables} from 'types/Restaurant'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useFindAvailableTables = (startDate: string, numberOfSeats: number, tableType: string, channel?: string, vip?: string, bookingId?: number) => {

  const payload = {
    startDate: startDate,
    numberOfSeats: numberOfSeats,
    tableType: tableType,
    channel: channel,
    bookingId: bookingId,
    vip: vip,
    page: 0,
    size: 20
  }
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {data: availableTables, isSuccess, isError, refetch} = useQuery<RestaurantTables>(
    ['find-available-tables', {defaultServiceProviderId, payload}],
    () => getAvailableRestaurantTable(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId
    })

  return {availableTables, isSuccess, isError, refetch}
}