import React, {useCallback, useContext, useMemo} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grow,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import {TextFieldController} from 'components/form/TextFieldController'
import {PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {PluginSchema} from 'config/YupSchemaValidations'
import PluginContext from 'plugin/PluginContext'
import {PLUGIN_GUEST_DETAILS} from 'plugin/PluginReducer'
import {Link} from 'react-router-dom'
import {EmailOutlined, PersonOutlined, PhoneIphoneOutlined} from '@mui/icons-material'
import {PhoneNumberController} from "../../components/form/PhoneNumberController";


export const GuestDetails = () => {
    const {t, i18n} = useTranslation()
    const schema = useMemo(() => PluginSchema(), [i18n.language]);
    const {bookings, dispatch, mainColor, secondaryColor, iconColor} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })


    const {handleSubmit, control} = useForm({
        defaultValues: bookings.add_plugin_bookings.guestDetails,
        resolver: yupResolver(schema)
    })
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const guestDetails = bookings.add_plugin_bookings.guestDetails
    const {page, setPage} = useContext(PluginContext)

    const submitHandler = (values: any) => {
        dispatch({type: PLUGIN_GUEST_DETAILS, payload: values})
        setPage({
            guestNumber: false,
            guestInfo: false,
            bookDetails: true,
            bookSuccess: false
        })
    }

    const handleClick = useCallback((input: string) => {
        if (input === 'back') {
            setPage({
                guestNumber: true,
                guestInfo: false,
                bookDetails: false,
                bookSuccess: false
            })
        }
    }, [])

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Grow in={page.guestInfo} style={{transformOrigin: '0 0 0'}}
                  {...(page.guestInfo ? {timeout: 1000} : {})}>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow color={'primary'}>
                                <TableCell colSpan={2}>
                                    <Typography variant={'h6'}>{t(`guest_details`)}</Typography>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}>
                                    <PersonOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell padding={'none'}
                                           style={{borderBottom: "none", width: '100%', marginTop: 13}}>
                                    <TextFieldController control={control} defaultValue={guestDetails.guestName}
                                                         plugin={true} size={'small'} name={'guestName'}
                                                         label={'name'}/>

                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}>
                                    <EmailOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell padding={'none'}
                                           style={{borderBottom: "none", width: '100%', marginTop: 13}}>
                                    <TextFieldController control={control} defaultValue={guestDetails.email}
                                                         plugin={true} size={'small'} name={'email'} label={'email'}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}>
                                    <PhoneIphoneOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell padding={'none'}
                                           style={{borderBottom: "none", width: '100%', marginTop: 13}}>
                                    <PhoneNumberController control={control} size={'small'} name={'guestMobile'}
                                                           defaultValue={guestDetails.guestMobile} label={'mobile'}/>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}>
                                    {/*<PhoneIphoneOutlined fontSize={'medium'} className={classes.myIcon}/>*/}
                                </TableCell>
                                <TableCell padding={'none'}
                                           style={{borderBottom: "none", width: '100%', marginTop: -5}}>
                                    <Controller control={control} name={'legalAgreement'}
                                                defaultValue={guestDetails.legalAgreement}
                                                render={({field: {onChange}, fieldState: {error}}) =>
                                                    <FormControl error={!!error}>
                                                        <FormControlLabel style={{marginLeft: -8, marginRight: 0}}
                                                                          className={classes.legalAgreement}
                                                                          control={<Checkbox
                                                                              defaultChecked={guestDetails.legalAgreement}
                                                                              onChange={onChange} color="primary"/>}
                                                                          label={
                                                                              <Box sx={{fontSize: 12}}>
                                                                                  <span>{t('agree_on_legal_terms_and_conditions_1')}</span>
                                                                                  <Link target="_blank"
                                                                                        to={'/data-privacy?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_2')}</Link>
                                                                                  <span>{t('agree_on_legal_terms_and_conditions_3')}</span>
                                                                                  <Link target="_blank"
                                                                                        to={'/terms-and-conditions?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_4')}</Link>
                                                                              </Box>
                                                                          }/>
                                                        <FormHelperText>{error?.message}</FormHelperText>
                                                    </FormControl>
                                                }
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Grow>
            <Button sx={{
                width: 80, position: 'absolute', top: 500, left: 170,
                backgroundColor: `${secondaryColor}`,
                color: 'white !important',
                transition: 'all .4s ease !important',
                '&:hover': { // changes colors for button hover state
                    opacity: '0.7 !important',
                    backgroundColor: `${secondaryColor}`
                }
            }}
                    onClick={() => handleClick('back')}>{t('back')}</Button>
            <Button type={'submit'} sx={{
                width: 80, position: 'absolute', top: 500, left: 260,
                backgroundColor: `${secondaryColor}`,
                color: 'white !important',
                transition: 'all .4s ease !important',
                '&:hover': { // changes colors for button hover state
                    opacity: '0.7 !important',
                    backgroundColor: `${secondaryColor}`
                }
            }}>{t('next')}</Button>
        </form>

    )
}
