import {privateAxiosClient} from 'config/AxiosClients'
import {
  DailyUnavailabilityType,
  HighlightDishType,
  ImageType,
  OpeningHoursType,
  ServiceProviderDisableChannel,
  ServiceProviderStyle,
  ServiceProviderType,
  UploadFileLanguageType,
  UploadFileTypes
} from 'types/ServiceProvider'

export const getServiceProviders = (providerAccountId: number) => {
  return privateAxiosClient.get<ServiceProviderType[]>('/service-providers', {params: {providerAccountId: providerAccountId}})
    .then(response => response.data)
}

export const getServiceProvider = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get<ServiceProviderType>(`/service-providers/${serviceProviderId}`)
    .then(response => {
      return response
    })
}

export const postServiceProviders = (payload: ServiceProviderType) => {
  return privateAxiosClient.post('/service-providers', payload)
}

export const putServiceProvider = (serviceProviderId: number, payload: ServiceProviderType) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}`, payload)
}

export const getPublicHolidays = (serviceProviderId: number) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/get-public-holidays`)
    .then(response => response.data)
}

export const putPublicHolidays = (serviceProviderId: number, payload: number[]) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/update-public-holidays`, payload)
}

export const putServiceProviderStyles = (serviceProviderId: number, payload: ServiceProviderStyle) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/update-style`, payload)
}

export const updateAvailability = (serviceProviderId: number, payload: OpeningHoursType) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/update-availability`, payload)
}

export const updateDailyUnavailability = (serviceProviderId: number, payload: DailyUnavailabilityType) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/update-daily-unavailability`, payload)
}

export const getAvailability = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/get-availability`)
    .then(response => response.data)
}

export const getDailyUnavailability = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/get-daily-unavailability`)
      .then(response => response.data)
}

export const getServiceProvidersBillingCount = (payload: any) => {
  const params = payload
  return privateAxiosClient.get(`/service-providers/billing-count`, {params})
      .then(response => response.data)
}

export const getServiceProviderStyles = (serviceProviderId: number) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/get-style`)
    .then(response => response.data)
}

export const postServiceProvidersImage = (serviceProviderId: number, payload: any) => {
  const formData = new FormData()
  formData.append('file', payload)

  return privateAxiosClient.post(`/service-providers/${serviceProviderId}/upload-image`, formData)
}

export const postServiceProvidersLogo = (serviceProviderId: number, payload: any) => {
  const formData = new FormData()
  formData.append('file', payload)

  return privateAxiosClient.post(`/service-providers/${serviceProviderId}/upload-logo`, formData)
}


export const postServiceProvidersMenu = (serviceProviderId: number, payload: any, lang: UploadFileLanguageType | undefined) => {
  const formData = new FormData()
  formData.append('file', payload)

  return privateAxiosClient.post(`/service-providers/${serviceProviderId}/upload-menu/${lang}`, formData,
    {headers: {"Content-Type": "multipart/form-data"}}
  )
}

export const postServiceProvidersVideo = (serviceProviderId: number, payload: any) => {
  const formData = new FormData()
  formData.append('file', payload)

  return privateAxiosClient.post(`/service-providers/${serviceProviderId}/upload-video`, formData,
    {headers: {"Content-Type": "multipart/form-data"}}
  )
}

export const getChannels = (serviceProviderId: number | undefined, payload: string[]) => {
  const searchParams = new URLSearchParams()
  payload.map(item => {
    searchParams.append('channelNames', item)
  })

  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/channels`, {params: searchParams})
    .then(response => response.data)
}


export const getAvailabilityTimeslots = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/get-available-timeslots`)
      .then(response => response.data)
}

export const getFile = (serviceProviderId: number | undefined, fileType: UploadFileTypes, lang: UploadFileLanguageType | undefined) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/files/${fileType}`, {params: {languageCode: lang}})
    .then(response => response.data)
}

export const getHighlightDishes = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get<HighlightDishType[]>(`/service-providers/${serviceProviderId}/highlight-dishes`)
    .then(response => response.data)
}

export const getImage = (serviceProviderId: number | undefined, filetype : string) => {
  return privateAxiosClient.get<ImageType[]>(`/service-providers/${serviceProviderId}/images/${filetype}`)
    .then(response => response.data)
}

export const putHighlightDishes = (serviceProviderId: number, payload: any) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/highlight-dishes`, payload)
}

export const deleteFile = (serviceProviderId: number | null, payload: number | undefined) => (
  privateAxiosClient.delete(`/service-providers/${serviceProviderId}/files/${payload}`)
)

export const putSubmitForReview = (serviceProviderId: number) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/submit-for-review`)
}

export const getDesignTemplates = () => {
  return privateAxiosClient.get(`/service-providers/designs/templates`)
    .then(response => response.data)
}

export const getDesignLayouts = () => {
  return privateAxiosClient.get(`/service-providers/designs/layouts`)
    .then(response => response.data)
}

export const getDesignColors = () => {
  return privateAxiosClient.get(`/service-providers/designs/colors`)
    .then(response => response.data)
}

export const putDesignTemplate = (serviceProviderId: number | undefined, payload: any) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/designs/template`, payload)
}

export const putUpdateImageSortOrder = (serviceProviderId: number | undefined, payload: any) => {
  return privateAxiosClient.put(`/service-providers/${serviceProviderId}/images/update-sort-order`, payload)
}

export const getTags = (serviceProviderId: number | undefined) => {
  return privateAxiosClient.get(`/service-providers/${serviceProviderId}/tags`)
    .then(response => response.data)
}

export const postDisableServiceProviderChannel = (serviceProviderId: number | undefined, payload: ServiceProviderDisableChannel) => {
  return privateAxiosClient.post(`/service-providers/${serviceProviderId}/channels/disable`, payload)
}