import jwt_decode from "jwt-decode";

export function getJwtToken () {
  return sessionStorage.getItem("jwt")
}

export function setJwtToken (token: string) {
  sessionStorage.setItem("jwt", token)
}

export function unSetJwtToken () {
  sessionStorage.removeItem("jwt")
}

export function getUserId () {
  const currentToken = getJwtToken()
  if (currentToken) {
    return Number(Object(jwt_decode(currentToken))["userId"])
  } else {
    return 0
  }
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken () {
  return sessionStorage.getItem("refreshToken")
}

export function setRefreshToken (token: string) {
  sessionStorage.setItem("refreshToken", token)
}

export function getMemberRole() {
  const currentToken = getJwtToken()
  if (currentToken) {
    return Object(jwt_decode(currentToken))["permissions"][0]
  } else {
    return ""
  }
}

export function isFirstLogin() {
  const currentToken = getJwtToken()
  if (currentToken) {
    return Boolean(Object(jwt_decode(currentToken))["firstLogin"])
  } else {
    return false
  }
}

export function isMemberAdminOrOwner() {
  const memberRole = getMemberRole()
  return memberRole == 'ADMIN' || memberRole == 'OWNER'
}

export function isMemberOwner() {
  return getMemberRole() == 'OWNER'
}
