import React from 'react'
import { Alert, Grid, List, ListItem, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useServiceProvider } from 'hooks/useServiceProvider'


export const DisabledValues = () => {
  const {t} = useTranslation()
  const {serviceProvider} = useServiceProvider()

  return (

    <Grid item xs={6}>
      <List>
        <Alert severity={'error'}>{t('input_locked')}</Alert>
        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
          <ListItemText primary={t('name')} secondary={serviceProvider?.name}/>
        </ListItem>

        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
          <ListItemText primary={t('phone_number')} secondary={serviceProvider?.phoneNumber}/>
        </ListItem>
        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
          <ListItemText primary={t('service_provider_description')} secondary={serviceProvider?.description}/>
        </ListItem>
        {serviceProvider?.descriptionEn &&
          <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
            <ListItemText primary={t('service_provider_description_en')} secondary={serviceProvider?.descriptionEn}/>
          </ListItem>
        }
        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
          <ListItemText primary={t('address')}
                        secondary={`${serviceProvider?.address.streetName} ${serviceProvider?.address.streetNumber}, 
                        ${serviceProvider?.address.city} ${serviceProvider?.address.country} ${serviceProvider?.address.postalCode}`}/>
        </ListItem>
        <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
          <ListItemText primary={t('registration_number_optional')}
                        secondary={serviceProvider?.registrationNumber}/>
        </ListItem>
      </List>
    </Grid>


  )
}