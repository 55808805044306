import {useQuery} from 'react-query'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {getTotalBookings} from 'api/private/bookings'
import {BOOKING_REFETCH_INTERVAL_IN_MS} from 'config/const'
import {getEndTimeOfTheDayInUTC, getStartTimeOfTheDayInUTC} from "../../config/Utils";

export const useTotalBookingsWithInterval = () => {
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const payload = {
      startDate: getStartTimeOfTheDayInUTC(),
      endDate: getEndTimeOfTheDayInUTC()
  }

  const {data: totalBookings} = useQuery(['bookings-total-with-interval', defaultServiceProviderId],
    () => getTotalBookings(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId,
      refetchInterval: BOOKING_REFETCH_INTERVAL_IN_MS,
      refetchIntervalInBackground: true
    }
  )

  return {totalBookings}

}