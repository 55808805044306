import React, { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { deleteFile } from 'api/private/serviceProviders'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { UploadFileTypes } from 'types/ServiceProvider'

const useDeleteFile = (type: UploadFileTypes) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const {mutate: DeleteFile} = useMutation((id: number | undefined) => (deleteFile(defaultProvider?.id, id)),
    {
      onSuccess: async () => {
        switch (type) {
          case 'SERVICE_PROVIDER_IMAGE':
            await queryClient.resetQueries(['images'])
            toast.success(`${t('image_delete_success')}`, {position: toast.POSITION.TOP_RIGHT})
            break
          case 'SERVICE_PROVIDER_LOGO':
            await queryClient.resetQueries(['images'])
            toast.success(`${t('logo_delete_success')}`, {position: toast.POSITION.TOP_RIGHT})
            break
          case 'SERVICE_PROVIDER_VIDEO':
          case 'SERVICE_PROVIDER_MENU':
            await queryClient.resetQueries(['files'])
            if (type === 'SERVICE_PROVIDER_VIDEO') {
              toast.success(`${t('video_delete_success')}`, {position: toast.POSITION.TOP_RIGHT})
            } else {
              toast.success(`${t('menu_delete_success')}`, {position: toast.POSITION.TOP_RIGHT})
            }
            break
        }

      },
      onError: async () => {
        switch (type) {
          case 'SERVICE_PROVIDER_IMAGE':
            toast.error(`${t('image_delete_error')}`, {position: toast.POSITION.TOP_RIGHT})
            break
          case 'SERVICE_PROVIDER_LOGO':
            toast.error(`${t('logo_delete_error')}`, {position: toast.POSITION.TOP_RIGHT})
            break
          case 'SERVICE_PROVIDER_VIDEO':
            toast.error(`${t('video_delete_error')}`, {position: toast.POSITION.TOP_RIGHT})
            break
          case 'SERVICE_PROVIDER_MENU':
            toast.error(`${t('menu_delete_error')}`, {position: toast.POSITION.TOP_RIGHT})
            break
        }

      }
    })

  return {DeleteFile}
}

export default useDeleteFile;
