import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Registration } from 'pages/Registration'
import { Dashboard } from 'pages/Dashboard'
import { Login } from 'pages/Login'
import { Main } from 'pages/Main'
import { CurrentBookings } from 'pages/CurrentBookings'
import { TopBarUnauthorized } from 'layouts/portal/TopBarUnauthorized'
import { AddBooking } from 'pages/AddBooking'
import { ConfigureTables } from 'pages/ConfigureTables'
import { ConfigureMembers } from 'pages/ConfigureMembers'
import { PrivateRoute } from 'PrivateRoute'
import { AdminRoute } from 'AdminRoute'
import { AddWalkIn } from 'pages/AddWalkIn'
import { Settings } from 'pages/Settings'
import { PluginContainer } from 'plugin/PluginContainer'
import { LegalViewer } from 'components/LegalViewer'
import { ForgotPassword } from 'pages/ForgotPassword'
import { ResetForgottenPassword } from 'pages/ResetForgottenPassword'
import { ActivateMember } from 'pages/ActivateMember'
import { EditServiceProvider } from 'components/settings/EditServiceProvider'
import { PublicHolidays } from 'components/settings/PublicHolidays'
import { Availability } from 'components/settings/availability/Availability'
import { GeneratePlugin } from 'components/settings/GeneratePlugin'
import { Billing } from 'pages/Billing'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { theme } from 'theme/Theme'
import { ThemeProvider } from '@mui/material/styles';
import { TrialExpiredRoute } from 'TrialExpiredRoute'
import { OwnerRoute } from 'OwnerRoute'
import { CancelBooking } from "pages/CancelBooking";
import { CustomerContainer } from "layouts/customer/CustomerContainer";
import { UpdateBooking } from 'pages/UpdateBooking'
import { NetworkPage } from 'components/settings/NetworkPage'
import {PublicRoute} from "./PublicRoute";
import { Unsubscribe } from 'pages/Unsubscribe'
import {Feedback } from "pages/Feedback";
import {HorizontalPluginContainer} from "plugin-horizontal/HorizontalPluginContainer";


export default function App () {
  const queryClient = new QueryClient(
    {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 0
        }
      }
    }
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<PrivateRoute/>}>
            <Route path='/' element={<Dashboard/>}>
              <Route index element={<Main/>}/>
              <Route element={<TrialExpiredRoute/>}>
                <Route path='/add-booking' element={<AddBooking/>}/>
                <Route path='/add-walk-in' element={<AddWalkIn/>}/>
                <Route path='/current-bookings' element={<CurrentBookings/>}/>
                <Route element={<AdminRoute/>}>
                  <Route path='/configure-tables' element={<ConfigureTables/>}/>
                  <Route path='/configure-members' element={<ConfigureMembers/>}/>
                  <Route path='/settings' element={<Settings/>}>
                    <Route path='network' element={<NetworkPage/>}/>
                    <Route path='edit-service-provider' element={<EditServiceProvider/>}/>
                    <Route path='public-holidays' element={<PublicHolidays/>}/>
                    <Route path='availability' element={<Availability/>}/>
                    <Route path='plugin-customization' element={<GeneratePlugin/>}/>
                  </Route>


                </Route>
              </Route>
              <Route element={<OwnerRoute/>}>
                <Route path='/billing' element={<Billing/>}/>
              </Route>
            </Route>
          </Route>

          <Route element={<PublicRoute/>}>
            <Route path='/' element={<TopBarUnauthorized/>}>
              <Route path='/registration' element={<Registration/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/forgot-password' element={<ForgotPassword/>}/>
              <Route path='/activate-member' element={<ActivateMember/>}/>
              <Route path='/reset-forgotten-password' element={<ResetForgottenPassword/>}/>
              <Route path='/data-privacy' element={<LegalViewer documentUrl={"https://skiper-legal.s3.eu-central-1.amazonaws.com/data_privacy"}/>}/>
              <Route path='/terms-and-conditions' element={<LegalViewer documentUrl={"https://skiper-legal.s3.eu-central-1.amazonaws.com/terms_and_conditions"}/>}/>
              <Route path='/imprint' element={<LegalViewer documentUrl={"https://skiper-legal.s3.eu-central-1.amazonaws.com/imprint"}/>}/>
              <Route path='/terms-and-conditions-service-provider' element={<LegalViewer documentUrl={"https://skiper-legal.s3.eu-central-1.amazonaws.com/terms_and_conditions_service_provider"}/>}/>
            </Route>
          </Route>

          <Route path='/' element={<CustomerContainer/>}>
            <Route path='/cancel-booking' element={<CancelBooking/>}/>
            <Route path='/update-booking' element={<UpdateBooking/>}/>
            <Route path='/unsubscribe' element={<Unsubscribe/>}/>
            <Route path='/feedback' element={<Feedback/>}/>
          </Route>
          <Route path='/booking-plugin/:id' element={<PluginContainer/>}/>
          <Route path='/booking-plugin-2/:id' element={<HorizontalPluginContainer/>}/>
        </Routes>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
  )
}