import React, { useMemo, useState } from "react"
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import {ActivateMemberInputType, ResetForgottenPasswordInputType} from 'types/Auth'
import { useResetForgottenPassword } from 'hooks/useResetForgottenPassword'
import { useTranslation } from 'react-i18next'
import { resetForgottenPasswordFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextFieldController } from 'components/form/TextFieldController'
import { resetForgottenPasswordStyles } from 'theme/ResetForgottenPassword.styles'
import { useSearchParams } from 'react-router-dom'
import { ResendVerificationLink } from 'components/ResendVerificationLink'
// @ts-ignore
import portalLogo from "../portal_logo.svg";
import ReCaptchaV3 from "../components/ReCaptchaV3";


export const ResetForgottenPassword = () => {
  const [searchParams] = useSearchParams();
  const classes = resetForgottenPasswordStyles()
  const schema = useMemo(() => resetForgottenPasswordFormSchema(), [i18n.language]);
  const [showResetToken, setShowResetToken] = useState<boolean>(false)

  const {handleSubmit, control} = useForm<ResetForgottenPasswordInputType>({resolver: yupResolver(schema)})

  const {ResetForgottenPassword} = useResetForgottenPassword(setShowResetToken)
  const {t} = useTranslation()

  const [values, setValues] = useState<ResetForgottenPasswordInputType | null>(null)

  const resetForgottenPasswordHandler: SubmitHandler<ResetForgottenPasswordInputType> = (values: ResetForgottenPasswordInputType) => {
    if (searchParams.get("email") && searchParams.get("resetToken")) {
      // @ts-ignore
      values.email = searchParams?.get("email")
      // @ts-ignore
      values.resetToken = searchParams?.get("resetToken")
      setValues({...values})
    }
  }

  return (
    <>
      {showResetToken ? (
        <ResendVerificationLink isForgotPassword={true}/>
      ) : (
        <Grid container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              minHeight="100vh">
          <Box className={classes.resetForgottenPasswordWrapper}>
            <Box component="img" alt="Skiper Portal Logo" src={portalLogo} style={{width:200, marginBottom: 20}}/>

            <ReCaptchaV3 values={values} mutationFN={ResetForgottenPassword}/>

            <Typography variant={'h4'} className={classes.resetForgottenPasswordText} m={3}><b>{t('reset_password')}</b></Typography>
            <Box component="form" onSubmit={handleSubmit(resetForgottenPasswordHandler)} noValidate className={classes.formWrapper}>
              <Stack direction={'column'}>

                <TextFieldController control={control} name={'password'} label={'new_password'} type="password"/>
                <TextFieldController control={control} name={'confirmedPassword'} label={'repeat_new_password'} type="password"/>

                <Button type="submit" fullWidth variant="contained" className={classes.resetForgottenPasswordButton}>
                  {t('update')}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
      )
      }
    </>

  )
}