import {useQuery} from 'react-query'
import {getDesignLayouts} from 'api/private/serviceProviders'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {DesignLayout} from "../../types/ServiceProvider";


export const useDesignLayouts = () => {
    const {serviceProvider} = useServiceProvider()

    const {data: designLayouts} = useQuery<DesignLayout[]>(['designLayouts'],
    async () => await getDesignLayouts(),
    {
      enabled: !!serviceProvider?.id,
      onSuccess: (data => {
        return data
      })
    }
  )

  return {designLayouts}
}