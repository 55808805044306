import { useMutation } from 'react-query'
import { RegisterInputType } from 'types/Auth'
import { register } from 'api/public/publicapi'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const useRegistration = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {mutate: Registration} = useMutation((payload: RegisterInputType) => {
    return register(payload)
  }, {
    onSuccess: (data, payload) => {
      toast.success(`${t('registration_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      navigate("/")
    },
    onError: () => {
      toast.error(t("registration_error"), {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {Registration}
}