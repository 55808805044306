import { useMutation, useQueryClient } from 'react-query'
import { putRestaurantTable } from 'api/private/restaurants'
import { RestaurantTablesContent } from 'types/Restaurant'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useUpdateRestaurantTable = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {mutate: EditTable} = useMutation((payload: RestaurantTablesContent) => (putRestaurantTable(defaultServiceProviderId, payload)),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(['restaurants'])
        toast.success(`${t('table_updated_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('table_updated_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {EditTable}
}