import {useQuery} from 'react-query'
import {ServiceProviderBillingCount} from "../types/ServiceProvider";
import {ISO_DATE_FORMAT} from "../config/const";
import {Moment} from "moment";
import {getServiceProvidersBillingCount} from "../api/private/serviceProviders";


export const useServiceProvidersBillingCount = (month: Moment) => {

    const payload = {
        startDate: month.startOf('month').format(ISO_DATE_FORMAT),
        endDate: month.endOf('month').format(ISO_DATE_FORMAT)
    }
    const {data: ServiceProvidersBillingCount, refetch, error} = useQuery<ServiceProviderBillingCount[]>(['service-providers-billing-count', payload],
        async () => await getServiceProvidersBillingCount(payload),
        {
            onSuccess: (data => {
                return data
            })
        }
    )

    return {ServiceProvidersBillingCount, error, refetch}
}