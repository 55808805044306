import {useQuery} from 'react-query'
import {getGeoLocation} from "../api/public/geolocation";
import {getUserId} from "../config/auth";
import {GeolocationInfo} from "../types/Geolocation";

export const useGeolocation = () => {

    const {data: GeoLocationInfo} = useQuery<GeolocationInfo>(['geolocation'],
        async () => await getGeoLocation(),
        {
            onSuccess: (data => {
                return data
            }),
            onError: () => {
                return null
            }
        }
    )
    return {GeoLocationInfo}
}