import { useMutation, useQueryClient } from 'react-query'
import { CancelBooking } from 'types/Bookings'
import { closeBooking } from 'api/private/bookings'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useCloseBooking = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {mutate: CloseBooking} = useMutation((payload: CancelBooking) => (closeBooking(defaultServiceProviderId, payload)),
    {
      onSuccess: () => {
        queryClient.resetQueries(['bookings'])
        toast.success(`${t('booking_close_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('booking_close_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {CloseBooking}
}