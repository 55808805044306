import React from 'react'
import {Grid} from '@mui/material'
import {TextFieldController} from 'components/form/TextFieldController'
import {serviceProviderCountry} from 'config/Options'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {AutocompleteController} from 'components/form/AutocompleteController'
import {PhoneNumberController} from "../../form/PhoneNumberController";


export const InputValues = ({control}: any) => {
  const {serviceProvider} = useServiceProvider()

  return (
    <>
      {serviceProvider && <Grid item xs={6} mt={3}>
          <Grid container>
              <Grid item xs={12}>
                  <TextFieldController control={control} size={'small'} name={'name'} label={'service_provider_name'}/>
              </Grid>
              <Grid item xs={12}>
                  <TextFieldController control={control} size={'small'} name={'registrationNumber'} label={'registration_number_optional'}/>
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberController control={control} size={'small'} name={'phoneNumber'} label={'phone_number'}/>
              </Grid>
              <Grid item xs={12}>
                  <TextFieldController control={control} size={'small'} multiline={true} name={'description'} label={'service_provider_description'}/>
              </Grid>
            <Grid item xs={12}>
              <TextFieldController control={control} size={'small'} multiline={true} name={'descriptionEn'} label={'service_provider_description_en'}/>
            </Grid>
              <Grid container spacing={1} mt={3}>
                  <Grid item xs={4}>
                      <AutocompleteController control={control} defaultValue={serviceProvider?.address.country} name={'address.country'}
                                              label={'country'} size={'small'}
                                              options={serviceProviderCountry}/>
                  </Grid>
                  <Grid item xs={4}>
                      <TextFieldController control={control} size={'small'} name={'address.state'} label={'state_optional'}/>
                  </Grid>
                  <Grid item xs={4}>
                      <TextFieldController control={control} size={'small'} name={'address.city'} label={'city'}/>
                  </Grid>
                  <Grid item xs={6}>
                      <TextFieldController control={control} size={'small'} name={'address.streetName'} label={'street_name'}/>
                  </Grid>
                  <Grid item xs={3}>
                      <TextFieldController control={control} size={'small'} name={'address.streetNumber'} label={'street_number'}/>
                  </Grid>
                  <Grid item xs={3}>
                      <TextFieldController control={control} size={'small'} name={'address.postalCode'} label={'postal_code'}/>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
      }
    </>
  )
}