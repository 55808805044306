import { useMutation, useQueryClient } from 'react-query'
import { postServiceProvidersMenu } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { UploadFileLanguageType } from 'types/ServiceProvider'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUploadMenu = (lang: UploadFileLanguageType | undefined) => {
  const {t} = useTranslation()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {mutate: UploadMenu} = useMutation(
    payload => postServiceProvidersMenu(defaultProvider?.id, payload, lang),
    {
      onSuccess: () => {
        queryClient.resetQueries(['files', defaultProvider?.id, 'SERVICE_PROVIDER_MENU'])
        toast.success(`${t('menu_upload_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: () => {
        toast.error(`${t('menu_upload_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )
  return {UploadMenu}
}