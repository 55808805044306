import React from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useBookingStatistics} from 'hooks/bookings/useBookingStatistics'
import {useTranslation} from 'react-i18next'

export const StatisticsChart = (props: { selectedDate: Date }) => {
    const {t} = useTranslation()

    const {data} = useBookingStatistics(props.selectedDate)

    return (
        <div style={{width: '100%', height: 300}}>
            <ResponsiveContainer>
                <AreaChart width={730} height={250} data={data}
                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#6A8AB9" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#6A8AB9" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#85E6CC" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#85E6CC" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dy={5} dataKey="name" tick={{fontFamily: "Montserrat"}} allowDecimals={false}/>
                    <YAxis dx={-5} tick={{fontFamily: "Montserrat"}} allowDecimals={false}/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip wrapperStyle={{fontFamily: "Montserrat"}}/>
                    <Area name={t("total_bookings")} type="monotone" dataKey="uv" stroke="#6A8AB9" fillOpacity={1} fill="url(#colorUv)"
                          dot={{stroke: 'white', strokeWidth: 2, r: 5, strokeDasharray: ''}}/>
                    <Area name={t("total_guests")} type="monotone" dataKey="pv" stroke="#85E6CC" fillOpacity={1} fill="url(#colorPv)"
                          dot={{stroke: 'white', strokeWidth: 2, r: 5, strokeDasharray: ''}}/>

                    <Legend wrapperStyle={{fontFamily: "Montserrat"}} verticalAlign="bottom" height={36}/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
