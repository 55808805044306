import { useMutation, useQueryClient } from 'react-query'
import { postWalkIn } from 'api/private/bookings'
import { CreateWalkInType } from 'types/Bookings'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useCreateWalkIn = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {mutate: AddWalkIn} = useMutation((payload: CreateWalkInType) => (postWalkIn(defaultServiceProviderId, payload)),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(['available-tables']).then(() => {
          queryClient.resetQueries(['bookings-statistics-query', defaultServiceProviderId])
        })

        toast.success(`${t('walk_in_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('walk_in_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {AddWalkIn}
}