import { useQuery } from 'react-query'
import { getMembers } from 'api/private/members'
import { MembersType } from 'types/Members'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import MembersContext from 'context/members/MembersContext'

export const useMembers = () => {

  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const {membersFilter} = useContext(MembersContext)

  const {data: members, refetch} = useQuery<MembersType>(['members', defaultServiceProviderId],
    () => getMembers(defaultServiceProviderId, membersFilter))

  return {members, refetch}
}
