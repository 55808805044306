import React, {useState} from "react"
import {useSearchParams} from 'react-router-dom'
import {useActivateMember} from 'hooks/useActivateMember'
import {useTranslation} from 'react-i18next'
import {ResendVerificationLink} from 'components/ResendVerificationLink'
import {ActivateMemberInputType} from "../types/Auth";
import ReCaptchaV3 from "../components/ReCaptchaV3";

export const ActivateMember = () => {

  const [showResetToken, setShowResetToken] = useState<boolean>(false)
  const {ActivateMember} = useActivateMember(setShowResetToken)

  const [searchParams] = useSearchParams();
  const {t, i18n} = useTranslation()

  const [values, setValues] = useState<ActivateMemberInputType | null>(null)

  const callback = () => {
    if (searchParams && searchParams.get("email") && searchParams.get("resetToken")) {
      // @ts-ignore
      setValues({email: searchParams.get("email"), resetToken: searchParams.get("resetToken")})
    }
  }

  return (
    <>
      <ReCaptchaV3 values={values} mutationFN={ActivateMember} callback={callback}/>
      {showResetToken &&
          <ResendVerificationLink isForgotPassword={false}/>
      }
    </>
  )
}