import {makeStyles} from '@mui/styles'

export const footerStyles = makeStyles({
    logo: {
        float: 'left',
        height: 64,
        cursor: 'pointer'
    },
    toolBar: {
        backgroundColor: 'white',
        fontSize: '14px',
        borderTop: '1px solid #e5ecf4'
    },
    divider: {
        color: '#A4AFB7',
        flexGrow: 2
    },
    footerBottomArea: {
        gridTemplateColumns: '1fr 1fr',
        borderTop: '1px solid',
        borderTopColor: '#E5ECF4',
        justifyContent: 'center',
    },
})