import { useMutation, useQueryClient } from 'react-query'
import { postServiceProviders } from 'api/private/serviceProviders'
import { ServiceProviderType } from 'types/ServiceProvider'

export const useCreateServiceProvider = () => {
  const queryClient = useQueryClient()
  const {mutate: AddServiceProvider} = useMutation((payload: ServiceProviderType) => (postServiceProviders(payload)),
    {
      onSuccess: async (data, payload) => {
        await queryClient.resetQueries(['service-providers', payload.ownerId]).then(() => {
          queryClient.resetQueries(['member', payload.ownerId])
        })
      }
    })

  return {AddServiceProvider}
}