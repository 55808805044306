import { useQuery } from 'react-query'
import { ChannelsType } from 'types/ServiceProvider'
import { getChannels } from 'api/private/serviceProviders'
import { useServiceProvider } from 'hooks/useServiceProvider'

type ChannelsResponseType = {
  channel: string
  status: 'INACTIVE' | 'REVIEW' | 'ACTIVE' | 'BLOCKED'
  rating: number
}

export const useChannels = () => {
  const {serviceProvider} = useServiceProvider()

  const payload: ChannelsType[] = ['GOOGLE', 'SKIPER_MARKETPLACE']

  const {data: Channels} = useQuery<ChannelsResponseType[]>(['channels', serviceProvider?.id],
    async () => await getChannels(serviceProvider?.id, payload),
    {
      enabled: !!serviceProvider?.id,
      onSuccess: (data => {
        return data
      })
    }
  )

  const isSPEditable = !Channels?.map(channel => channel.status)
      .filter(status => status === 'ACTIVE' || status === 'REVIEW' || status === 'BLOCKED').length

  return {Channels, isSPEditable}
}