import React from "react"
import { Alert, Button, Container, Divider, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { CancelBooking } from 'types/Bookings'
import { useCloseBooking } from 'hooks/bookings/useCloseBooking'


type props = {
  openCloseBooking: any
  setOpenCloseBooking: any
  bookingId?: number
}

export const CloseBookingDrawer = ({openCloseBooking, setOpenCloseBooking, bookingId}: props) => {
  const {t} = useTranslation();

  const {CloseBooking} = useCloseBooking()
  const {handleSubmit, control, reset} = useForm<CancelBooking>({})
  const toggleDrawer = () => {
    reset()
    setOpenCloseBooking(!openCloseBooking)
  }

  const submitHandler = (values: CancelBooking) => {
    CloseBooking({id: bookingId, comment: values.comment})
    setOpenCloseBooking(!openCloseBooking)
    reset()
  }

  return (
    <Drawer open={openCloseBooking} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Alert severity={'warning'}>{t('close_warning')}</Alert>
          <Typography variant={'h6'}>{t('close_booking')}</Typography>
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} multiline name={'comment'} label={'comment'}/>
            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('close')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>
  )
}