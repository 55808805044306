import { useMutation, useQueryClient } from 'react-query'
import { putMemberDetails } from 'api/private/members'
import { UpdateMemberType } from 'types/Members'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUpdateMemberDetails = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {mutate: EditMemberDetails} = useMutation((payload: UpdateMemberType) => (putMemberDetails(payload)),
    {
      onSuccess: async (data, payload) => {
        await queryClient.resetQueries(['members']).then(() => {
          queryClient.resetQueries(['member', payload.id])
        })
        toast.success(`${t('member_updated_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('member_updated_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {EditMemberDetails}
}