import React from "react"
import {Box, Container, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { DATE_TIME_FORMAT } from 'config/const'
import {AccessibleOutlined, DoneOutlined} from "@mui/icons-material";


export const BookingDetailedInformationDrawer = ({openDetails, setOpenDetails, info, page}: any) => {
  const {t} = useTranslation();


  const toggleDrawer = () => {
    setOpenDetails(!openDetails)
  }

  return (
    <Drawer open={openDetails} onClose={toggleDrawer}>
      <Container maxWidth={'lg'} sx={{paddingY: 2}}>
        {page === 'current-bookings' &&
            <>
                <Typography variant={'h4'}>{t('submission_details')}</Typography>

                <Box mt={2}>
                    <Divider variant="fullWidth"/>
                </Box>
                <Table>

                    <TableHead>
                        <TableRow color={'primary'}>
                            <TableCell colSpan={2}>
                                <Typography variant={'h6'}>{t(`guest_details`)}</Typography>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}><strong>{t('customer_name')}:</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.guestName}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('mobile')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.guestMobile}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('email')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.guestEmail}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('number_of_guests')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.numberOfGuests}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('comment')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {t(info.comment)}</Typography>
                            </TableCell>
                        </TableRow>
                        {info.cancelComment && info.cancelComment !== "" && <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('cancel_comment')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {t(info.cancelComment)}</Typography>
                            </TableCell>
                        </TableRow>}
                        {info.closeComment && info.closeComment !== "" && <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('close_comment')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.closeComment}</Typography>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>

                <Table>
                    <TableHead>
                        <TableRow color={'primary'}>
                            <TableCell colSpan={2}>
                                <Typography variant={'h6'}>{t('table_details')}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('table')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.tableNumber}
                                    {info.wheelchairSupport &&
                                        <Tooltip title={`${t('wheelchair_support')}`} placement="top">
                                            <IconButton style={{paddingBottom: '10px'}}>
                                                <AccessibleOutlined color={'info'}/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('seats')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {info.numberOfSeats}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}>
                                    <strong>{t('type')}:</strong>
                                </Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {t(info.tableType)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}><strong>{t('booking_start_time')}:</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {moment(info.bookingTime).format(DATE_TIME_FORMAT)}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant={'body2'}><strong>{t('booking_end_time')}:</strong></Typography>
                            </TableCell>
                            <TableCell padding={'none'}>
                                <Typography variant={'body2'}> {moment(info.bookingEndTime).format(DATE_TIME_FORMAT)}</Typography>
                            </TableCell>
                        </TableRow>
                        {info.vip &&
                            <TableRow>
                                <TableCell>
                                    <Typography variant={'body2'}><strong>{t('vip')}:</strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'}>
                                    <Tooltip title={`${t('vip_tooltip')}`} placement="top">
                                        <DoneOutlined color={'success'}/>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </>
        }
      </Container>
    </Drawer>


  )
}