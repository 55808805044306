import React, {useCallback, useContext, useState} from 'react'
import {Box, Button, Grow, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import PluginContext from 'plugin/PluginContext'
import moment from 'moment/moment'
import {DATE_FORMAT, ISO_DATE_TIME_FORMAT, TIME_FORMAT} from 'config/const'
import {
    AccessTimeOutlined,
    DateRangeOutlined,
    EmailOutlined,
    PeopleOutline,
    PersonOutlined,
    PhoneIphoneOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {PluginStyles} from 'theme/Plugin.styles'
import {usePluginCreateBooking} from 'plugin/usePluginCreateBooking'
import i18n from 'i18next'
import ReCaptchaV3 from "../../components/ReCaptchaV3";
import {CreateBookingTypeUnauthorized} from "../../types/Bookings";


export const BookDetails = () => {
    const {t} = useTranslation()

    const {bookings, selectedDateTime, page, setPage, mainColor, secondaryColor, iconColor} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })


    const {seats, tableType, guestDetails, additionalOptions} = bookings.add_plugin_bookings

    const {AddBooking} = usePluginCreateBooking()

    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const handleClick = useCallback(() => {
        setPage({
            guestNumber: false,
            guestInfo: true,
            bookDetails: false,
            bookSuccess: false
        })

    }, [])

    const [values, setValues] = useState<CreateBookingTypeUnauthorized | null>(null)

    const handleSubmit = () => {
        const bookingValue = {
            "tableType": tableType,
            bookingTime: moment(selectedDateTime).format(ISO_DATE_TIME_FORMAT),
            guestName: guestDetails.guestName,
            guestMobile: guestDetails.guestMobile,
            guestEmail: guestDetails.email,
            numberOfGuests: seats,
            comment: '',
            channel: 'WEB',
            languageCode: getCurrentLng().toUpperCase(),
            additionalOptions: additionalOptions,
            recaptchaToken: ""
        }
        setValues(bookingValue)
    }

    return (
        <>
            <Typography marginLeft={3} variant={'h6'}>{t(`summary`)}</Typography>
            <Grow in={page.bookDetails} style={{transformOrigin: '0 0 0'}}
                  {...(page.bookDetails ? {timeout: 1000} : {})}>
                <Box>
                    <Table size={'small'}>
                        <TableHead>
                            <TableRow color={'primary'}>
                                <TableCell/>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                    <PeopleOutline fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                    <Typography><strong>{t('Guests')}: </strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <Typography> {seats} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                    <DateRangeOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                    <Typography><strong>{t('date')}:</strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <Typography>{moment(selectedDateTime).format(DATE_FORMAT)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                    <AccessTimeOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                    <Typography><strong>{t('time')}:</strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <Typography>{moment(selectedDateTime).format(TIME_FORMAT)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                    <TableRestaurantOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                    <Typography><strong>{t('table')}:</strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <Typography>{t(tableType)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                    <PersonOutlined fontSize={'medium'} className={classes.myIcon}/>
                                </TableCell>
                                <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                    <Typography><strong>{t('name')}: </strong></Typography>
                                </TableCell>
                                <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                    <Typography>{guestDetails.guestName}</Typography>
                                </TableCell>
                            </TableRow>
                            {guestDetails.email &&
                                <TableRow>
                                    <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                        <EmailOutlined fontSize={'medium'} className={classes.myIcon}/>
                                    </TableCell>
                                    <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                        <Typography><strong>{t('email')}: </strong></Typography>
                                    </TableCell>
                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                        <Typography style={{wordBreak: 'break-all'}}>{guestDetails.email}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            {guestDetails.guestMobile &&
                                <TableRow>
                                    <TableCell style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                        <PhoneIphoneOutlined fontSize={'medium'} className={classes.myIcon}/>
                                    </TableCell>
                                    <TableCell style={{borderBottom: "none", width: 60, paddingLeft: 0}}>
                                        <Typography><strong>{t('mobile')}:</strong></Typography>
                                    </TableCell>
                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                        <Typography
                                            style={{wordBreak: 'break-all'}}>{guestDetails.guestMobile}</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Box sx={{marginLeft: '7px', marginTop: '-5px'}}><ReCaptchaV3 values={values}
                                                                                  mutationFN={AddBooking}
                                                                                  showText={true}/></Box>
                </Box>
            </Grow>
            <Button sx={{
                width: 80, position: 'absolute', top: 500, left: 170,
                backgroundColor: `${secondaryColor}`,
                color: 'white !important',
                transition: 'all .4s ease !important',
                '&:hover': { // changes colors for button hover state
                    opacity: '0.7 !important',
                    backgroundColor: `${secondaryColor}`
                }
            }} onClick={handleClick}>{t('back')}</Button>
            <Button onClick={handleSubmit} sx={{
                width: 80, position: 'absolute', top: 500, left: 260,
                color: 'white !important',
                transition: 'all .4s ease !important',
                backgroundColor: `${secondaryColor}`,
                '&:hover': { // changes colors for button hover state
                    opacity: '0.7 !important',
                    backgroundColor: `${secondaryColor}`
                }
            }}>{t('to_book')}</Button>
        </>
    )
}
