import moment, { Duration, Moment } from 'moment'
import {BOOKING_TIME_UPPER_LIMIT_MINUTES, ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT, TIME_FORMAT} from 'config/const'
import { OptionsType } from 'types/General'
import { AvailableTimeslots } from 'types/ServiceProvider'


const defaultAvailableTimeslots = {
    availableTimeslots: {
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: [],
        SUNDAY: []
    }
}

export const getDynamicTimeOptionsByDate = (date: string, availableTimeslots: AvailableTimeslots = defaultAvailableTimeslots): OptionsType[] => {
    const today = moment(new Date()).format(ISO_DATE_FORMAT)

    const currentTime = moment(getTimeRoundedWith30Minutes(moment(new Date())), TIME_FORMAT)

    // @ts-ignore
    const filterDayOfWeek = availableTimeslots.availableTimeslots[moment(date).locale("en").format('dddd').toUpperCase()]
    const dayOfWeekOptions: OptionsType[] = []
    if (!filterDayOfWeek || filterDayOfWeek.length == 0) {
        return dayOfWeekOptions
    }
    if (today == moment(date).format(ISO_DATE_FORMAT) && filterDayOfWeek) {
        const removePatsTime = filterDayOfWeek.filter((time: any) => time >= moment(currentTime).format(TIME_FORMAT))
        removePatsTime.forEach((item: string) => {
            dayOfWeekOptions.push({value: item, label: item})
        })
        return dayOfWeekOptions
    } else {
        filterDayOfWeek.forEach((item: string) => {
            dayOfWeekOptions.push({value: item, label: item})
        })
        return dayOfWeekOptions
    }

}

export const getTimeRoundedWith30Minutes = (date: Moment): string => {
    const roundedDate = round(date, moment.duration(30, "minutes"));

    return roundedDate.format(TIME_FORMAT)
}

function round (date: Moment, duration: Duration) {
    return moment(Math['ceil']((+date) / (+duration)) * (+duration));
}

export const convertToUTCAndValidateBookingTime = (bookingTimeString: string | undefined): boolean => {
    const now = moment(new Date())
    const bookingMaximumTime = moment(bookingTimeString).add(BOOKING_TIME_UPPER_LIMIT_MINUTES, 'minutes')
    return now.isSameOrBefore(bookingMaximumTime)
}

export  const convertHexToRGBA = (hexCode: string, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
}


export const getStartTimeOfTheDayInUTC = (): string => {
    return moment(new Date()).startOf('day').format(ISO_DATE_TIME_FORMAT)
}

export const getEndTimeOfTheDayInUTC = (): string => {
    return moment(new Date()).endOf('day').format(ISO_DATE_TIME_FORMAT)
}

export const hideGoogleRecaptchaBadge = () => {
    const googleRecaptchaElement = document.getElementsByClassName('grecaptcha-badge')
    if (googleRecaptchaElement && googleRecaptchaElement.length > 0) {
        googleRecaptchaElement[0].classList.add('grecaptcha-badge-hide')
    }
}

export const showGoogleRecaptchaBadge = () => {
    const googleRecaptchaElement = document.getElementsByClassName('grecaptcha-badge')
    if (googleRecaptchaElement && googleRecaptchaElement.length > 0) {
        googleRecaptchaElement[0].classList.remove('grecaptcha-badge-hide')
    }
}

export const hideGoogleRecaptchaBadgeForPlugin = () => {
    const styleElement = document.createElement('style')
    styleElement.innerHTML = ".grecaptcha-badge { visibility: hidden; }";
    document.body.appendChild(styleElement);
}

export const EURO_FORMATTER = new Intl.NumberFormat('en-DE', {
    style: 'currency',
    currency: 'EUR',
});
