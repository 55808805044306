import {useQuery} from 'react-query'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {getBookingsCount} from 'api/private/bookings'
import moment from 'moment/moment'
import {BOOKING_STATISTICS_COUNT_DAYS, ISO_DATE_FORMAT, ISO_DATE_TIME_FORMAT, ISO_DAY_MONTH} from 'config/const'
import {BookingsCountResponseWrapper} from 'types/Bookings'


export const useBookingStatistics = (selectedDate: Date) => {
  const fallback = getDefaultValue(selectedDate)

  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const startDate = moment(selectedDate).subtract(BOOKING_STATISTICS_COUNT_DAYS, 'days')
  const endDate = moment(selectedDate).add(BOOKING_STATISTICS_COUNT_DAYS, 'days')

  const payload = {
    startDate: startDate.format(ISO_DATE_TIME_FORMAT),
    endDate: endDate.format(ISO_DATE_TIME_FORMAT)
  }

  const {data = fallback, refetch} = useQuery<any>(['bookings-statistics-query', defaultServiceProviderId, payload.startDate],
    () => getBookingsCount(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId && defaultServiceProviderId != 0,
      select: (returnData) => {
        const startDate = moment(selectedDate).subtract(BOOKING_STATISTICS_COUNT_DAYS, 'days')
        const endDate = moment(selectedDate).add(BOOKING_STATISTICS_COUNT_DAYS, 'days')
        const data = []
        for (let m = startDate; m.diff(endDate, 'days') < 0 || m.isSame(endDate); m.add(1, 'days')) {
          if (returnData && returnData.bookingCountInfos) {
            const count = returnData.bookingCountInfos.find((a:any) => a.date == m.format(ISO_DATE_FORMAT))
            if (count) {
              data.push({
                name: m.format(ISO_DAY_MONTH),
                uv: count.totalBookings,
                pv: count.totalGuests
              })
            } else {
              data.push({
                name: m.format(ISO_DAY_MONTH),
                uv: 0,
                pv: 0
              })
            }
          } else {
            data.push({
              name: m.format(ISO_DAY_MONTH),
              uv: 0,
              pv: 0
            })
          }
        }
        return data
      },
      onError: (returnData) => {
        return fallback
      }
    })

  return {data, refetch}
}

const getDefaultValue = (selectedDate: Date) => {
  const data = []
  const startDate = moment(selectedDate).subtract(BOOKING_STATISTICS_COUNT_DAYS, 'days')
  const endDate = moment(selectedDate).add(BOOKING_STATISTICS_COUNT_DAYS, 'days')
  for (let m = startDate; m.diff(endDate, 'days') < 0 || m.isSame(endDate); m.add(1, 'days')) {
    data.push({
      name: m.format(ISO_DAY_MONTH),
      uv: 0,
      pv: 0
    })
  }
  return data
}
