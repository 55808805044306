import React from 'react'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ServiceProviderBillingCount} from "../../types/ServiceProvider";
import {v4 as uuidv4} from "uuid";
import {EURO_FORMATTER} from "../../config/Utils";


type props = {
    serviceProvidersBillingCount?: ServiceProviderBillingCount[]
}

export const ServiceProvidersBillingTable = ({serviceProvidersBillingCount}: props) => {
    const {t} = useTranslation()

    function getTableRowPerChannelCount(serviceProviderBillingCount: ServiceProviderBillingCount) {
        return <TableRow key={uuidv4()}>
            <TableCell>
                {serviceProviderBillingCount.serviceProviderName}
            </TableCell>
            <TableCell>{serviceProviderBillingCount.totalBookingCount}</TableCell>
            <TableCell>{serviceProviderBillingCount.totalCost == 0 ? "" : EURO_FORMATTER.format(serviceProviderBillingCount.totalCost)}</TableCell>
        </TableRow>
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                        <TableRow>
                            <TableCell>{t('service_provider_name')}</TableCell>
                            <TableCell>{t('booking_count')}</TableCell>
                            <TableCell>{t('amount')}</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceProvidersBillingCount?.map(item => (
                            getTableRowPerChannelCount(item)
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}