import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Grid } from '@mui/material'
import { NetworkStatus } from 'components/network/NetworkStatus'
import { TemplateSelector } from 'components/network/TemplateSelector'
import { CustomAccordion } from 'components/CustomAccordion'
import { UploadFiles } from 'components/network/UploadFiles'
import HighlightDishes from 'components/network/HighlightDishes'
import useSubmitForReview from 'hooks/serviceProvider/useSubmitForReview'
import { useChannels } from 'hooks/serviceProvider/useChannels'


export const NetworkPage = () => {
  const {t} = useTranslation()
  const {SubmitForReview} = useSubmitForReview()
  const {isSPEditable} = useChannels()
  return (
    <>
      <Grid xs={12}>
        <Alert severity={'info'}>{t('before_review_info')}</Alert>
      </Grid>
      <CustomAccordion title={'network_status'} accordionName={'networkStatus'}>
        <NetworkStatus/>
      </CustomAccordion>

      <CustomAccordion title={'highlight_dishes'} accordionName={'highlightDishes'}>
        <HighlightDishes/>
      </CustomAccordion>

      <CustomAccordion title={'upload_menu_images_video'} accordionName={'uploadFiles'}>
        <UploadFiles/>
      </CustomAccordion>

      <CustomAccordion title={'configure_template'} accordionName={'templateSelector'}>
        <TemplateSelector/>
      </CustomAccordion>

      <Button style={{marginTop: 15, marginBottom: 15}} fullWidth variant={'contained'}
              onClick={() => SubmitForReview()}
              disabled={!isSPEditable}
      >
        {t('submit_for_review')}
      </Button>
    </>
  )
}