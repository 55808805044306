import { useMutation, useQueryClient } from 'react-query'
import { putServiceProviderStyles } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ServiceProviderStyle } from 'types/ServiceProvider'

export const useUpdateServiceProviderStyle = () => {
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {t} = useTranslation()

  const {mutate: UpdateServiceProviderStyle} = useMutation((payload: ServiceProviderStyle) => (putServiceProviderStyles(defaultProvider?.id, payload)), {
    onSuccess: async () => {
      await queryClient.resetQueries(['service-provider-styles', defaultProvider?.id])
      toast.success(`${t('service_provider_styles_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: async () => {
      toast.error(`${t('service_provider_styles_error')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdateServiceProviderStyle}
}