import React, { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { useMutation, useQueryClient } from 'react-query'
import { putSubmitForReview } from 'api/private/serviceProviders'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const useSubmitForReview = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const {mutate: SubmitForReview} = useMutation(() => putSubmitForReview(defaultProvider?.id),
    {
      onSuccess: () => {
        queryClient.resetQueries(['channels'])
        toast.success(`${t('submitted_for_review')}`, {position: toast.POSITION.TOP_RIGHT})
      }
    }
  )
  return {SubmitForReview}

}

export default useSubmitForReview;
