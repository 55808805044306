import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getJwtToken, isMemberAdminOrOwner } from 'config/auth'
import { useMember } from 'hooks/members/useMember'

export const AdminRoute = () => {
  const auth = getJwtToken() != null

  if (auth && isMemberAdminOrOwner()) {
    return <Outlet/>
  } else {
    return <Navigate to="/"/>
  }
}