import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'


export const Settings = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const currentTab = () => {
    if (location.pathname === '/settings/network') {
      return 0
    } else if (location.pathname === '/settings/edit-service-provider') {
      return 1
    } else if (location.pathname === '/settings/public-holidays') {
      return 2
    } else if (location.pathname === '/settings/availability') {
      return 3
    } else if (location.pathname === '/settings/plugin-customization') {
      return 4
    }
  }
  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={currentTab()} variant="fullWidth" centered>
          <Tab label={t("network")} onClick={() => navigate('/settings/network')}/>
          <Tab label={t("edit_service_provider_header")} onClick={() => navigate('/settings/edit-service-provider')}/>
          <Tab label={t("public_holidays")} onClick={() => navigate('/settings/public-holidays')}/>
          <Tab label={t("availability")} onClick={() => navigate('/settings/availability')}/>
          <Tab label={t("plugin_customization")} onClick={() => navigate('/settings/plugin-customization')}/>
        </Tabs>
      </Box>
      <Outlet/>
    </>
  )
}