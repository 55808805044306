import React, {useContext} from "react"
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {bookingSeats, bookingsTablesType, tableVipOptions} from 'config/Options'
import BookingContext from 'context/bookings/BookingContext'
import {BookingsFilters} from 'components/BookingFilters'
import {
  SELECT_PAGE_SIZE_WALK_IN,
  SELECT_PAGE_WALK_IN,
  SELECT_SEATS_ADD_WALK_IN,
  SELECT_TABLE_TYPE_ADD_WALK_IN,
  SELECT_TABLE_VIP_ADD_WALK_IN
} from 'context/bookings/BookingsReducer'
import WalkInRow from 'components/WalkInRow'
import {useRestaurantTablesAvailabilityWalkIn} from "hooks/restaurant/useRestaurantTablesAvailabilityWalkIn";


export const AddWalkIn = () => {
  const {t} = useTranslation();
  const {availableTables, isSuccess} = useRestaurantTablesAvailabilityWalkIn()

  const {bookings, dispatch} = useContext(BookingContext)
  const {seats, tableType, vip} = bookings.add_walk_in

  const handlePageChange = (page: number) => {
    dispatch({type: SELECT_PAGE_WALK_IN, payload: page})
  }

  const handlePageSizeChange = (size: string) => {
    dispatch({type: SELECT_PAGE_SIZE_WALK_IN, payload: Number(size)})
  }

  return (
    <>
      <Grid container spacing={2} my={1}>
        <Grid item>
          <BookingsFilters options={bookingSeats} translateLabel={false} defaultValue={seats} type={SELECT_SEATS_ADD_WALK_IN} label={'minimum_seats'}  disableClearable={true}/>
        </Grid>
        <Grid item>
          <BookingsFilters options={bookingsTablesType} translateLabel={true} defaultValue={tableType} type={SELECT_TABLE_TYPE_ADD_WALK_IN} label={'type'}/>
        </Grid>
        <Grid item sx={{flexGrow: 1}}>
          <BookingsFilters options={tableVipOptions} translateLabel={true} defaultValue={vip} type={SELECT_TABLE_VIP_ADD_WALK_IN} label={'vip'}/>
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{width: '100%', padding: 2}}>
          <Typography variant={'h5'} mb={2}>{t('available_tables_for_walk_in')}</Typography>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                <TableRow>
                  <TableCell>{t('table')}</TableCell>
                  <TableCell>{t('seats')}</TableCell>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('remaining_seats')}</TableCell>
                  <TableCell>{t('vip')}</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess && availableTables?.content.map((item, index) => <WalkInRow key={index} index={index} item={item}/>)}
              </TableBody>
            </Table>
            {availableTables && <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                component="div"
                labelRowsPerPage={t('rows_per_page')}
                count={availableTables?.totalElements}
                rowsPerPage={availableTables ? availableTables.size : 10}
                page={availableTables?.number || 0}
                onPageChange={(event: any, page: number) => handlePageChange(page)}
                onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
            />}
          </TableContainer>
        </Paper>
      </Grid>
    </>

  )
}