import React, {useCallback, useContext, useState} from 'react'
import {
    Box,
    Button,
    Grid,
    Grow,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import PluginContext from 'plugin/PluginContext'
import moment from 'moment/moment'
import {DATE_FORMAT, ISO_DATE_TIME_FORMAT, TIME_FORMAT} from 'config/const'
import {
    AccessTimeOutlined,
    DateRangeOutlined,
    EmailOutlined,
    PeopleOutline,
    PersonOutlined,
    PhoneIphoneOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {PluginStyles} from 'theme/Plugin.styles'
import {usePluginCreateBooking} from 'plugin/usePluginCreateBooking'
import i18n from 'i18next'
import ReCaptchaV3 from "../../components/ReCaptchaV3";
import {CreateBookingTypeUnauthorized} from "../../types/Bookings";


export const BookDetails = () => {
    const {t} = useTranslation()

    const {bookings, selectedDateTime, page, setPage, mainColor, secondaryColor, iconColor} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })


    const {seats, tableType, guestDetails, additionalOptions} = bookings.add_plugin_bookings

    const {AddBooking} = usePluginCreateBooking()

    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const handleClick = useCallback(() => {
        setPage({
            guestNumber: false,
            guestInfo: true,
            bookDetails: false,
            bookSuccess: false
        })

    }, [])

    const [values, setValues] = useState<CreateBookingTypeUnauthorized | null>(null)

    const handleSubmit = () => {
        const bookingValue = {
            "tableType": tableType,
            bookingTime: moment(selectedDateTime).format(ISO_DATE_TIME_FORMAT),
            guestName: guestDetails.guestName,
            guestMobile: guestDetails.guestMobile,
            guestEmail: guestDetails.email,
            numberOfGuests: seats,
            comment: '',
            channel: 'WEB',
            languageCode: getCurrentLng().toUpperCase(),
            additionalOptions: additionalOptions,
            recaptchaToken: ""
        }
        setValues(bookingValue)
    }

    const isLargeDevice: boolean = useMediaQuery("only screen and (min-width : 993px) and (max-width : 1200px)")

    return (
        <>
            <Grid container spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                <Grid container alignItems="center" direction="row" xs={5} sm={5} md={4.2} lg={1.5} xl={1.2}>
                    <DateRangeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Typography sx={{marginLeft: "5px"}}> {moment(selectedDateTime).format(DATE_FORMAT)} </Typography>
                </Grid>
                <Grid container alignItems="center" direction="row" xs={5} sm={2} md={3} lg={1.1} xl={0.8}>
                    <AccessTimeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Typography sx={{marginLeft: "5px"}}> {moment(selectedDateTime).format(TIME_FORMAT)} </Typography>
                </Grid>
                <Grid container alignItems="center" direction="row" xs={5} sm={1.2} md={1.9} lg={1} xl={0.6}>
                    <PeopleOutline fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Typography sx={{marginLeft: "5px"}}> {seats} </Typography>
                </Grid>
                <Grid container alignItems="center" direction="row" xs={5} sm={3} md={2.6} lg={1.2} xl={1}>
                    <TableRestaurantOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Typography sx={{marginLeft: "5px"}}> {t(tableType)} </Typography>
                </Grid>
                <Grid container alignItems="center" direction="row" xs={10} sm={5} md={4.2} lg={3} xl={2}>
                    <PersonOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Typography sx={{marginLeft: "5px"}}> {guestDetails.guestName} </Typography>
                </Grid>
                {guestDetails.email &&
                    <Grid container alignItems="center" direction="row" xs={10} sm={6.75} md={5} lg={3.5} xl={2.7}>
                        <EmailOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                        <Typography
                            style={{marginLeft: '5px', wordBreak: 'break-all'}}>{guestDetails.email}</Typography>
                    </Grid>
                }
                {guestDetails.guestMobile &&
                    <Grid container alignItems="center" direction="row" xs={5} sm={6.75} md={2.6} lg={2} xl={1.5}>
                        <PhoneIphoneOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                        <Typography
                            style={{marginLeft: '5px', wordBreak: 'break-all'}}>{guestDetails.guestMobile}</Typography>
                    </Grid>
                }

                <Grid container direction='row' justifySelf='right' justifyContent={'right'} xs={7} sm={4.5} md xl={2}>
                    <Grid item>
                        <Button sx={{
                            width: 100,
                            height: "40px",
                            backgroundColor: `${secondaryColor}`,
                            margin: '5px',
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }} onClick={handleClick}>{t('back')}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleSubmit} sx={{
                            width: 100,
                            height: "40px",
                            margin: '5px',
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            backgroundColor: `${secondaryColor}`,
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }}>{t('to_book')}</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"column"} xs sx={{padding:'5px'}}>
                <Box sx={{marginLeft: '7px', marginTop: '-5px'}}><ReCaptchaV3 values={values}
                                                                              mutationFN={AddBooking}
                                                                              showText={true}/></Box>
            </Grid>
        </>
    )
}
