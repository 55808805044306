import { useQuery } from 'react-query'
import { getPublicHolidays } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import moment from 'moment/moment'
import { DATE_FORMAT } from 'config/const'
import { PublicHolidaysType } from 'types/ServiceProvider'


export const usePublicHolidays = () => {
  const {defaultProvider} = useContext(ServiceProviderContext)

  const {data: publicHolidays = []} = useQuery<PublicHolidaysType[]>(['public-holidays', defaultProvider?.id],
    async () => await getPublicHolidays(defaultProvider?.id),
    {
      select: data => {
        data.sort((a: any, b: any) =>
          Date.parse(moment(a.date).format(DATE_FORMAT).split("-").reverse().join("-"))
          -
          Date.parse(moment(b.date).format(DATE_FORMAT).split("-").reverse().join("-")))

        return data
      },
      enabled: !!defaultProvider,
    }
  )

  return {publicHolidays}
}