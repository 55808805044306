import {staticAdditionalOptions} from "../config/Options";
import {Autocomplete, Chip, TextField} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {OptionsType} from "../types/General";
import {AccessibleOutlined} from "@mui/icons-material";

type props = {
    additionalOptions: OptionsType[]
    onChange: any
    disableUnderline?: boolean
}
export const AdditionalOptionsAutocomplete = ({additionalOptions, onChange, disableUnderline = false}: props) => {
    const {t} = useTranslation();
    const getIcon = (value: string | number) => {
        if (value === 'WHEELCHAIR_SUPPORT') {
            return <AccessibleOutlined/>
        }
    }
    return <Autocomplete
        options={staticAdditionalOptions}
        value={additionalOptions}
        multiple={true}
        limitTags={1}
        noOptionsText={t('no_options')}
        disableClearable={true}
        isOptionEqualToValue={(option, value) => {
            return option.value === value.value
        }}
        getOptionLabel={(type) => t(type.label.toLowerCase())}
        renderInput={(params) => <TextField
            {...params}
            variant={'standard'}
            size={'small'}
            sx={{width: 140}}
            label={''}
            autoComplete={'off'}
            InputProps={{ ...params.InputProps, disableUnderline: disableUnderline }}
        />}
        renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
                <Chip {...getTagProps({ index })} key={option.value} icon={getIcon(option.value)}/>
            ));
        }}
        filterOptions={(options, state) => options.filter(option =>
            !additionalOptions.some((val: any) => val.value === option.value) &&
            option.label.toLowerCase().includes(state.inputValue.toLowerCase())
        )}
        onChange={(e, newValue) => onChange(newValue)}/>
}