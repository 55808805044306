import { useQuery } from 'react-query'
import { getExternalServiceProviderStyles } from 'api/public/publicapi'
import { useParams } from 'react-router-dom'
import { ServiceProviderStyle } from 'types/ServiceProvider'

export const useServiceProviderStyles = (serviceProviderId: number | undefined) => {

  const {data: serviceProviderStyles} = useQuery<ServiceProviderStyle>(['service-provider-styles', serviceProviderId],
    () => getExternalServiceProviderStyles(serviceProviderId),
    {
      enabled: !!serviceProviderId
    }
  )

  return {serviceProviderStyles}
}