import React, {useState} from 'react';
import {Button, IconButton, TableCell, TableRow, Tooltip, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {RestaurantTablesContent} from 'types/Restaurant'
import {AddBookingDrawer} from 'components/drawers/AddBookingDrawer'
import {AccessibleOutlined, DoneOutlined, InfoOutlined} from '@mui/icons-material';

type props = {
    index: number
    item: RestaurantTablesContent
}

const AddBookingRow = ({index, item}: props) => {
    const {t} = useTranslation();
    const [openBooking, setOpenBooking] = useState(false)
    return (
        <TableRow key={item.id}>
            <TableCell>
                {item.number}
                {item.wheelchairSupport &&
                    <Tooltip title={`${t('wheelchair_support')}`} placement="top">
                        <IconButton style={{paddingBottom: '10px'}}>
                            <AccessibleOutlined color={'info'}/>
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
            <TableCell>{item.seats}</TableCell>
            <TableCell>{t(item.type)}</TableCell>
            <TableCell>{item.remainingSeats}</TableCell>
            <TableCell>
                {item.vip &&
                    <Tooltip title={`${t('vip_tooltip')}`} placement="top">
                        <DoneOutlined color={'success'}/>
                    </Tooltip>}
            </TableCell>
            <TableCell>
                {item.outsideOfAvailabilityHours ?
                    <Button variant={'availableWithWarningButton'} fullWidth onClick={() => setOpenBooking(true)}>
                        {t('book')}
                        <Tooltip title={
                            <Typography padding={0} variant={'body2'} dangerouslySetInnerHTML={
                                {__html: t('booking_outside_of_availability', {interpolation: {escapeValue: false}})}
                            }/>
                        } placement="top" enterTouchDelay={0}>
                            <InfoOutlined color={'info'} sx={{marginLeft: 1, cursor: 'pointer'}}/>
                        </Tooltip>
                    </Button>
                    :
                    <Button variant={'contained'} fullWidth sx={{backgroundColor: '#85E6CC'}} onClick={() => setOpenBooking(true)}>
                        {t('book')}
                    </Button>
                }
            </TableCell>
            {openBooking && <AddBookingDrawer table={item} openBooking={openBooking} setOpenBooking={setOpenBooking}/>}
        </TableRow>
    )
}

export default AddBookingRow