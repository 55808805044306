import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { MemberType } from 'types/Members'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useUpdateMemberConsentDetails } from 'hooks/members/useUpdateMemberConsentDetails'

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type props = {
  member?: MemberType
  open: any,
  setOpen: any
}

export const MemberConsentModal = ({member, open, setOpen}: props) => {
  const {t, i18n} = useTranslation()
  const [legal, setLegal] = useState<boolean>(false)
  const {EditMemberConsentDetails} = useUpdateMemberConsentDetails()
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const handleClose = () => {
    if (legal) {
      setOpen(false);
      EditMemberConsentDetails({id: member?.id})
    }
  };

  const onCheckBoxChange = (event: any) => {
    setLegal(event.target.checked)
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="member-consent-dialog"
      fullWidth={true}
    >
      <DialogTitle style={{textAlign: 'center'}}>{t("welcome_skiper_portal")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="member-consent-dialog">
          <FormControlLabel control={<Checkbox onChange={onCheckBoxChange} color="primary"/>}
                            label={
                              <>
                                <span>{t('agree_on_legal_terms_and_conditions_1')}</span>
                                <Link target="_blank" to={'/data-privacy?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_2')}</Link>
                                <span>{t('agree_on_legal_terms_and_conditions_3')}</span>
                                <Link target="_blank"
                                      to={'/terms-and-conditions-service-provider?lang=' + getCurrentLng()}>{t('agree_on_legal_terms_and_conditions_4')}</Link>
                              </>
                            }/>

        </DialogContentText>
      </DialogContent>
      <DialogActions style={{justifyContent: "center"}}>
        <Button type="submit" variant="contained" onClick={handleClose}>
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
