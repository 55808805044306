import { getUserId } from 'config/auth'
import { MemberType } from 'types/Members'
import { useQuery } from 'react-query'
import { getMember } from 'api/private/members'

export const useMember = () => {
  const userId = getUserId()

  const {data: member} = useQuery<MemberType>(['member', userId],
    () => getMember(userId))

  return {member}
}