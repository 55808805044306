import {Control, Controller} from 'react-hook-form'
import {Autocomplete, Box, FormControl, TextField} from '@mui/material'
import React from 'react'
import {OptionsType} from 'types/General'
import {useTranslation} from 'react-i18next'
import {PLUGIN_ICON_COLOR} from 'theme/Plugin.styles'

type props = {
    control: Control<any>
    name: string
    label: string
    defaultValue?: string
    options: OptionsType[]
    size?: 'small' | 'medium'
}


export const CustomTimePicker = ({
                                           control,
                                           name,
                                           label,
                                           defaultValue,
                                           options,
                                           size = 'medium',
                                       }: props) => {
    const {t} = useTranslation()
    return <Controller
        control={control}
        render={({field: {onChange, value}, fieldState: {error}}) => {
            return (
                <Box width={150} sx={{margin: 'auto'}}>
                <FormControl fullWidth={true} margin={'dense'}>
                    <Autocomplete
                        id={'subType'}
                        options={options}
                        getOptionLabel={(type) => type.label || ''}
                        disableClearable={false}
                        renderInput={(params =>
                            <TextField
                                {...params}
                                variant={'outlined'}
                                label={t(label)}
                                value={value || ''}
                                size={size}
                                autoComplete='off'
                                error={!!error}
                                helperText={error?.message}
                            />)}
                        isOptionEqualToValue={(option, value)=> {
                            return option === value
                        }}
                        onChange={(_, data) => onChange(data ? data.value : null)}
                        value={typeof value === 'string' && value ? options.find(o => o.value === value) : (value)}
                    />
                </FormControl>
                </Box>
            )
        }
        }
        defaultValue={defaultValue}
        name={name}/>

}