import moment from 'moment'
import {ISO_DATE_FORMAT} from 'config/const'
import {getTimeRoundedWith30Minutes} from 'config/Utils'


export const PLUGIN_SELECT_DATE = 'PLUGIN_SELECT_DATE'
export const PLUGIN_SELECT_TIME = 'PLUGIN_SELECT_TIME'
export const PLUGIN_SELECT_SEATS = 'PLUGIN_SELECT_SEATS'
export const PLUGIN_INCREASE_SEATS = 'PLUGIN_INCREASE_SEATS'
export const PLUGIN_DECREASE_SEATS = 'PLUGIN_DECREASE_SEATS'
export const PLUGIN_SELECT_TABLE_TYPE = 'PLUGIN_SELECT_TABLE_TYPE'

export const PLUGIN_SELECT_ADDITIONAL_OPTIONS = 'PLUGIN_SELECT_ADDITIONAL_OPTIONS'

export const PLUGIN_GUEST_DETAILS = 'PLUGIN_GUEST_DETAILS'

export const INITIAL_PLUGIN_STATE = {
  add_plugin_bookings: {
    startDate: moment(new Date()),
    startTime: getTimeRoundedWith30Minutes(moment(new Date())),
    seats: 2,
    additionalOptions: [],
    tableType: "INDOOR",
    status: 'ACTIVE',
    guestDetails: {
      guestName: '',
      guestMobile: null,
      email: '',
      legalAgreement: false
    }
  }
}

export const PluginReducer = (state = INITIAL_PLUGIN_STATE, action: any) => {
  switch (action.type) {
    case PLUGIN_SELECT_DATE:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          startDate: action.payload
        }
      }
    case PLUGIN_INCREASE_SEATS:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          seats: state.add_plugin_bookings.seats + 1
        }
      }
    case PLUGIN_DECREASE_SEATS:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          seats: state.add_plugin_bookings.seats - 1
        }
      }
    case PLUGIN_SELECT_SEATS:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          seats: action.payload
        }
      }
    case PLUGIN_SELECT_TABLE_TYPE:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          tableType: action.payload
        }
      }
    case PLUGIN_SELECT_ADDITIONAL_OPTIONS:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          additionalOptions: action.payload
        }
      }
    case PLUGIN_SELECT_TIME:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          startTime: action.payload
        }
      }
    case PLUGIN_GUEST_DETAILS:
      return {
        ...state,
        add_plugin_bookings: {
          ...state.add_plugin_bookings,
          guestDetails: action.payload
        }
      }
    default:
      return state;
  }
}