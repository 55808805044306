import {makeStyles} from '@mui/styles'
import {Theme, useMediaQuery} from '@mui/material'

export const PLUGIN_MAIN_COLOR = '#ffffff'
export const PLUGIN_SECONDARY_COLOR = '#6A8AB9'
export const PLUGIN_ICON_COLOR = '#000000'

export const defaultPluginProps = {
    mainColor: PLUGIN_MAIN_COLOR,
    secondaryColor: PLUGIN_SECONDARY_COLOR,
    iconColor: PLUGIN_ICON_COLOR,
}

export type PluginProps = {
    mainColor: string
    secondaryColor: string
    iconColor: string
}

export const PluginStyles = makeStyles<Theme, PluginProps>((props) => ({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        borderRadius: 20,
        backgroundColor: (props) => props.secondaryColor,
        padding: 2,
        width: 350,
        height: 550
    },
    horizontalMainWrapper: {
        backgroundColor: (props) => props.mainColor,
        flexDirection: 'row',
        padding: 2,
    },
    inputWrapper: {
        borderRadius: 20,
        backgroundColor: (props) => props.mainColor,
        width: 330,
        height: 550,
        paddingBottom: 10,
        paddingRight: 5
    },
    horizontalInputWrapper: {
        backgroundColor: (props) => props.mainColor,
    },
    legalAgreement: {
        color: '#7699CE',
    },
    logo: {
        marginTop: 5,
        height: 40,
        marginBottom: 5
    },
    myIcon: {
        padding: 5,
        backgroundColor: (props) => props.secondaryColor,
        marginLeft: -30,
        borderRadius: 100,
        color: (props) => props.iconColor
    },
    horizontalIcon: {
        padding: 5,
        margin: 1,
        backgroundColor: (props) => props.secondaryColor,
        borderRadius: 2,
    },
    numOfGuests: {
        color: (props) => props.iconColor
    },
    numOfGuestsButton: {
        border: '2px solid #77b9bc !important',
        minWidth: '15px !important',
        margin: '0 2px !important',
        borderRadius: 2,
        color: '#77b9bc'
    },
    dates: {
        backgroundColor: 'red !important'
    },
    languageSwitch: {
        height: '38px',
        marginTop: 15,
        marginLeft: 130,
    },
    horizontalLanguageSwitch: {
        height: '38px',
        marginTop: 10,
        marginRight: 5,
    },
    submitButtonForPluginDemo: {
        backgroundColor: (props) => props.secondaryColor,
        color: 'white',
        transition: 'all .4s ease',
        '&:hover': { // changes colors for button hover state
            opacity: 0.7,
            backgroundColor: (props) => props.secondaryColor,
        }
    },
    form: {
        width: '100%',
    }
}))