import { useQuery } from 'react-query'
import { FileType, UploadFileLanguageType, UploadFileTypes } from 'types/ServiceProvider'
import { getFile } from 'api/private/serviceProviders'
import { useServiceProvider } from 'hooks/useServiceProvider'


export const useFiles = (fileType: UploadFileTypes, lang?: UploadFileLanguageType) => {
  const {serviceProvider} = useServiceProvider()


  const {data: file} = useQuery<FileType>(['files', serviceProvider?.id, fileType, lang],
    async () => await getFile(serviceProvider?.id, fileType, lang),
    {
      enabled: !!serviceProvider?.id,
    }
  )

  return {file}
}