import { useMutation, useQueryClient } from 'react-query'
import { updateAvailability } from 'api/private/serviceProviders'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { OpeningHoursType } from 'types/ServiceProvider'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const useUpdateOpeningHours = () => {
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {t} = useTranslation()

  const {mutate: UpdateOpeningHours} = useMutation((payload: OpeningHoursType) => (updateAvailability(defaultProvider?.id, payload)), {
    onSuccess: async () => {
      await queryClient.resetQueries(['opening-hours'])
      await queryClient.resetQueries(['available-time-slots'])
      toast.success(`${t('opening_hours_update_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: async () => {
      toast.error(`${t('opening_hours_update_error')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdateOpeningHours}
}