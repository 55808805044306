import React from "react"
import {useTranslation} from 'react-i18next'
import {Box, Grid, Link, Typography} from "@mui/material"
import {CancelOutlined, TaskAltOutlined} from "@mui/icons-material";

type props = {
    isSuccess: boolean
    message: string
}

export const SuccessErrorBox = ({isSuccess, message}: props) => {

    const {t} = useTranslation()

    const bgColor = isSuccess ? "#8bc34a" : "#ff6a63"
    const getIcon = () => {
        if (isSuccess) {
            return <TaskAltOutlined sx={{color: bgColor, width: "120px", height: "120px"}}/>
        }
        return <CancelOutlined sx={{color: bgColor, width: "120px", height: "120px"}}/>
    }
    return (
        <>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" minHeight="calc(100vh - 200px)"
                  sx={{backgroundColor: "#fbfafd"}}>
                <Box sx={{
                    backgroundColor: 'white', height: '425px', width: '335px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;'
                }}>
                    <Box sx={{height: "10px", backgroundColor: bgColor}}/>
                    <Box sx={{
                        height: "50%",
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        "display": "flex",
                        "justifyContent": "center",
                        "alignItems": "center"
                    }}>
                        <Box>
                            {getIcon()}
                        </Box>
                    </Box>
                    <Box sx={{
                        textAlign: "center",
                        height: "20%"
                    }}>
                        {isSuccess &&
                            <Typography variant={'h4'}>{t('success')}</Typography>
                        }
                        {!isSuccess &&
                            <Typography variant={'h4'}>{t('error')}</Typography>
                        }
                    </Box>
                    <Box sx={{
                        height: "30%",
                        "display": "flex",
                        "justifyContent": "center",
                        "alignItems": "center",
                        textAlign: "center"
                    }}>
                        <Box>
                            {isSuccess &&
                                <Typography variant={'h6'}>{t(message)}</Typography>
                            }
                            {!isSuccess && message === null &&
                                <>
                                    <Typography variant={'h6'}>{t('something_went_wrong')}</Typography>

                                    <Typography variant={'h6'}>{t('please_reach_to_us')}
                                        <Link sx={{textDecoration: 'none'}} href="mailto:support@skiper.io">
                                            {t('support')}.
                                        </Link>
                                    </Typography>
                                </>
                            }
                            {!isSuccess && message !== null &&
                                <Typography variant={'h6'}>{t(message)}</Typography>
                            }
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}