import React, { useEffect, useState } from "react"
import { Outlet } from 'react-router-dom'
import { Container, Grid, Stack, Typography } from "@mui/material"
import { TopBar } from 'layouts/portal/TopBar'
import { Navigation } from 'layouts/portal/Navigation'
import { ServiceProvider } from 'context/ServiceProviderContext'
import { Booking } from 'context/bookings/BookingContext'
import { PublicHolidays } from 'context/PublicHolidaysContext'
import { OpeningHours } from 'context/OpeningHoursContext'
import Joyride, {STATUS} from 'react-joyride'
import { useMember } from 'hooks/members/useMember'
import { useTranslation } from 'react-i18next'
import { TourDefaultStyles } from 'portalTour/TourConfig'
import { isFirstLogin } from 'config/auth'
import { RestaurantTables } from 'context/restaurantTables/RestaurantTablesContext'
import { Members } from 'context/members/MembersContext'


export const Dashboard = () => {
  const {t} = useTranslation()
  const {member} = useMember()
  const [tourState, setState] = useState<boolean>(false)


  useEffect(() => {
    const showTour = localStorage.getItem('showTour');
    if (member?.consentGivenAt && isFirstLogin() && showTour === null) {
      setState(true);
    } else  {
      setState(false);
    }
  }, [member]);

  const handleJoyride = (data : any)  => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem('showTour', JSON.stringify(false));
    }
  };


  return <>
    <ServiceProvider>
      <Booking>
        <PublicHolidays>
          <RestaurantTables>
            <Members>
              <OpeningHours>
                <Joyride
                  continuous
                  run={tourState}
                  scrollToFirstStep
                  showSkipButton
                  hideCloseButton={true}
                  callback={handleJoyride}
                  locale={
                    {
                      skip: t('skip'),
                      next: t('next'),
                      back: t('back'),
                      last: t('finish')
                    }
                  }
                  disableOverlayClose={true}
                  steps={[
                    {
                      content: <>
                        <Typography variant={'h5'}>{t('welcome_skiper')}</Typography>
                        <Typography mt={4}>{t('welcome_tour')}</Typography>
                      </>,
                      styles: {options: {width: 600}},
                      placement: 'center',
                      target: 'body'
                    },
                    {
                      content: <Typography>{t('profile_edit')}</Typography>,
                      target: '.profile-icon'
                    },
                    {
                      content: <Typography>{t('sidebar_highlight')}</Typography>,
                      placement: 'right',
                      target: '.sidebar-highlight'
                    },
                    {
                      content: <Typography>{t('view_bookings_history')}</Typography>,
                      target: '.current-bookings'
                    },
                    {
                      content: <Typography>{t('creation_of_tables')}</Typography>,
                      target: '.configure-tables'
                    },
                    {
                      content: <Typography>{t('creation_of_members')}</Typography>,
                      target: '.configure-members'
                    },
                    {
                      content: <Typography>{t('adjusting_opening_hours_and_holidays')}</Typography>,
                      target: '.settings'
                    },
                    {
                      content: <Typography>{t('creation_and_switch_of_sp')}</Typography>,
                      target: '.add-service-provider'
                    }
                  ]}
                  styles={TourDefaultStyles}
                />
                <Stack direction={'row'} sx={{backgroundColor: '#F8F8F8 !important'}} className='root-stack'>
                  <Navigation/>
                  <Grid item xs={12} sx={{width: '100%'}}>

                    <TopBar/>
                    <Container>
                      <Outlet/>
                    </Container>
                  </Grid>
                </Stack>
              </OpeningHours>
            </Members>
          </RestaurantTables>
        </PublicHolidays>
      </Booking>
    </ServiceProvider>

  </>
}