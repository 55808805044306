import {useQuery} from 'react-query'
import {DailyUnavailabilityType} from 'types/ServiceProvider'
import {getDailyUnavailability} from 'api/private/serviceProviders'


export const useDailyUnavailability = (serviceProviderId: number | undefined) => {

    const {data: DailyUnavailability} = useQuery<DailyUnavailabilityType>(['daily-unavailability', serviceProviderId],
        async () => await getDailyUnavailability(serviceProviderId),
        {
            enabled: !!serviceProviderId,
            onSuccess: (data => {
                return data
            })
        }
    )

    return {DailyUnavailability}
}