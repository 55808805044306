import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useFiles } from 'hooks/serviceProvider/useFiles'
import { DeleteOutline } from '@mui/icons-material'
import CustomFileUploadButton from 'components/network/CustomFileUploadButton'
import useDeleteFile from 'hooks/serviceProvider/useDeleteFile'
import { useTranslation } from 'react-i18next'

const UploadVideo = () => {
  const {t} = useTranslation()
  const {file} = useFiles('SERVICE_PROVIDER_VIDEO')
  const {DeleteFile} = useDeleteFile('SERVICE_PROVIDER_VIDEO')

  return (
    <CustomFileUploadButton  key={file?.id} fileType={'SERVICE_PROVIDER_VIDEO'} label={'upload_video'} acceptedTypes={'video/*'}>
      <Stack direction={'row'} style={{width: '97%', height: '150px'}} alignItems={'center'}>
        {file ?
          <>
            <video src={file.fileUrl} width="235px" height='150px' style={{paddingLeft: 13, borderRadius: 5}} controls/>
            <IconButton edge={'end'} onClick={() => DeleteFile(file?.id)}>
              <DeleteOutline/>
            </IconButton>
          </>
          :
          <Box>
            <Typography pl={1} style={{boxSizing: 'border-box', width: '250px'}}>
              {t('video_not_uploaded')}
            </Typography>
          </Box>
        }
      </Stack>
    </CustomFileUploadButton>
  )
}

export default UploadVideo;
