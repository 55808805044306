import React from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { DATE_FORMAT, DATE_FORMAT_MASK } from 'config/const'
import { TextField } from '@mui/material'
import 'moment/locale/de'

type props = {
  label: string
  date: string
  disablePast?: boolean
  onChange: (newValue: Date | null) => void
}

export const CustomDatePicker = ({label, date, disablePast = false, onChange}: props) => {
  const {i18n} = useTranslation()
  return <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
    <DatePicker
      label={label}
      disablePast={disablePast}
      value={moment(date).toDate()}
      inputFormat={DATE_FORMAT}
      mask={DATE_FORMAT_MASK}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} style={{width: 200}} variant={'outlined'} size={'small'}/>}
    />
  </LocalizationProvider>
}