import {useQuery} from 'react-query'
import {getAllTags} from "../../api/public/publicapi";
import {Tag} from "../../types/Tags";

export const useAllTags = () => {

  const {data: AllTags} = useQuery<Tag[]>(['all-tags'],
    async () => await getAllTags(),
    {
      onSuccess: (data => {
        return data
      })
    }
  )
  return {AllTags}
}