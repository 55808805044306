import React, { useContext } from 'react'
import { Grow, Stack, Typography } from '@mui/material'
import PluginContext from 'plugin/PluginContext'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next'

export const BookSuccess = () => {

  const {page} = useContext(PluginContext)
  const {t} = useTranslation()


  return (
    <>
      <Grow in={page.bookSuccess} style={{transformOrigin: '0 0 0'}}
            {...(page.bookSuccess ? {timeout: 1000} : {})}>
        <Stack direction={'column'} sx={{paddingTop: 15, color: 'green', fontSize: 18}} alignItems={'center'}>
          <CheckCircleOutlineIcon sx={{fontSize: 40}}/>
          <Typography variant={'h5'} textAlign={'center'}>{t('your_booking_was_successful')}</Typography>
        </Stack>
      </Grow>
    </>
  )
}
