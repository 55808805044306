import {useMutation} from 'react-query'
import {createStripeCustomerPortalSession} from 'api/private/members'
import {useMember} from "./useMember";

export const useCreateStripeCustomerPortalSession = () => {
    const {member} = useMember()
    const {mutate: CreateStripeCustomerPortalSession} = useMutation(() => (createStripeCustomerPortalSession(member?.id)),
        {
            onSuccess: async (data) => {
                if (data.data) {
                    window.location.href = data.data
                }
            },
        })

    return {CreateStripeCustomerPortalSession}
}