import {privateAxiosClient} from 'config/AxiosClients'
import {RestaurantTablesContent, RestaurantTablesStateType} from 'types/Restaurant'
import moment from 'moment'
import {ISO_DATE_TIME_FORMAT} from "config/const";

const SERVICE_PROVIDERS = '/service-providers'
const RESTAURANT_TABLES = 'restaurant-tables'

export const getRestaurantTables = (serviceProviderId: number | null, restaurantTablesFilter: RestaurantTablesStateType) => {
    const params = {
        status: restaurantTablesFilter.status,
        type: restaurantTablesFilter.type,
        vip: restaurantTablesFilter.vip,
        page: restaurantTablesFilter.page,
        size: restaurantTablesFilter.perPage,
        sort: 'tableNumber,asc'
    }
    return privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}`, {params}).then(response => response.data)
}

export const getRestaurantTable = (serviceProviderId: number | null, tableId: number) => {
    return privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}/${tableId}`).then(response => response.data)
}

export const getAvailableRestaurantTable = (serviceProviderId: number | null, payload: any, sort = 'tableNumber,asc') => {

    const params = {
        bookingTime: moment(payload.startDate).format(ISO_DATE_TIME_FORMAT),
        minSeats: payload.numberOfSeats,
        tableType: payload.tableType,
        status: payload.status,
        bookingId: payload.bookingId,
        page: payload.page,
        size: payload.size,
        vip: payload.vip,
        channel: payload.channel,
        sort: sort
    }


    return privateAxiosClient.get(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}/search-available`,
        {params})
        .then(response => response.data)
}

export const postRestaurantTable = (serviceProviderId: number | null, payload: RestaurantTablesContent) => {
    return privateAxiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}`, payload)
}

export const putRestaurantTable = (serviceProviderId: number | null, payload: RestaurantTablesContent) => {
    const params = {
        number: payload.number,
        seats: payload.seats,
        minGuests: payload.minGuests,
        type: payload.type,
        fillable: payload.fillable,
        vip: payload.vip,
        wheelchairSupport: payload.wheelchairSupport
    }

    return privateAxiosClient.put(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}/${payload.id}`, params)
}


export const deactivateRestaurantTable = (serviceProviderId: number | null, tableId: number) => {
    const params = {
        restaurantTableId: tableId
    }
    return privateAxiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}/deactivate`, params)
}

export const activateRestaurantTable = (serviceProviderId: number | null, tableId: number) => {
    const params = {
        restaurantTableId: tableId
    }
    return privateAxiosClient.post(`${SERVICE_PROVIDERS}/${serviceProviderId}/${RESTAURANT_TABLES}/activate`, params)
}
