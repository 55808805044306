import {useQuery} from 'react-query'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {getTotalBookings} from 'api/private/bookings'
import {getEndTimeOfTheDayInUTC, getStartTimeOfTheDayInUTC} from "../../config/Utils";


export const useTotalBookings = () => {
  const fallback = {count: 0}
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)
  const payload = {
    startDate: getStartTimeOfTheDayInUTC(),
    endDate: getEndTimeOfTheDayInUTC()
  }

  const {data: totalBookings = fallback} = useQuery(['bookings-total', defaultServiceProviderId],
    () => getTotalBookings(defaultServiceProviderId, payload),
    {
      enabled: !!defaultServiceProviderId
    })

  return {totalBookings}
}