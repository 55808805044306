import {useQuery} from 'react-query'
import {getSpAvailableTimeslots} from 'api/public/publicapi'
import moment from "moment/moment";
import {ISO_DATE_FORMAT} from "../config/const";
import {SPAvailableTimeslots} from "../types/ServiceProvider";

export const useSpAvailableTimeslots = (serviceProviderId: number | undefined, startDate: string, tableType: string, numberOfGuests: number, additionalOptions: string[]) => {

  const payload = {
    date: moment(startDate).format(ISO_DATE_FORMAT),
    numberOfGuests: numberOfGuests,
    tableType: tableType,
    additionalOptions: additionalOptions?.join(',')
  }


  const fallback = {
    availableTimeslots: []
  }

  const {data: SPAvailableTimeslots, error, isSuccess} = useQuery<SPAvailableTimeslots, any>(
    ['get-sp-available-timeslots', serviceProviderId, payload],
    () => getSpAvailableTimeslots(serviceProviderId, payload),
    {
      enabled: !!serviceProviderId,
      cacheTime: 0
    })

  return {SPAvailableTimeslots, error, isSuccess}
}