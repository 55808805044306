import { useMutation, useQueryClient } from 'react-query'
import { deactivateRestaurantTable } from 'api/private/restaurants'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useDeactivateRestaurantTable = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {mutate: deactivateTables} = useMutation((id: number) => (deactivateRestaurantTable(defaultServiceProviderId, id)),
    {
      onSuccess: async () => {
        await queryClient.resetQueries(['restaurants'])
        toast.success(`${t('table_deactivate_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: async () => {
        toast.error(`${t('table_delete_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {deactivateTables}
}