import React, {useEffect, useState} from 'react'
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack} from '@mui/material'
import {useDesignTemplates} from "../../hooks/serviceProvider/useDesignTemplates";
import {useDesignColors} from "../../hooks/serviceProvider/useDesignColors";
import {useDesignLayouts} from "../../hooks/serviceProvider/useDesignLayouts";
import {useServiceProvider} from "../../hooks/useServiceProvider";
import {DesignColor, DesignLayout, DesignTemplate} from "../../types/ServiceProvider";
import {useTranslation} from "react-i18next";
import {useUpdateDesignTemplate} from "../../hooks/serviceProvider/useUpdateDesignTemplate";
// @ts-ignore

export const TemplateSelector = () => {
    const {t} = useTranslation();

    const {serviceProvider} = useServiceProvider()

    const {designTemplates} = useDesignTemplates()
    const {designLayouts} = useDesignLayouts()
    const {designColors} = useDesignColors()

    const {UpdateDesignTemplate} = useUpdateDesignTemplate()


    const [currentDesignLayoutId, setCurrentDesignLayoutId] = useState(1)
    const [currentDesignColorId, setCurrentDesignColorId] = useState(1)

    const [selectedDesignTemplate, setSelectedDesignTemplate] = useState<DesignTemplate | undefined>(undefined)

    useEffect(() => {
        const selectedDesignLayoutId = designTemplates?.find(a => a.id == serviceProvider?.designTemplateId)?.designLayoutId
        const selectedDesignColorId = designTemplates?.find(a => a.id == serviceProvider?.designTemplateId)?.designColorId
        setCurrentDesignLayoutId(selectedDesignLayoutId ? selectedDesignLayoutId : 1)
        setCurrentDesignColorId(selectedDesignColorId ? selectedDesignColorId : 1)
    }, [serviceProvider, designTemplates])

    useEffect(() => {
        const designTemplate = designTemplates?.find(a => a.designColorId == currentDesignColorId && a.designLayoutId == currentDesignLayoutId)
        setSelectedDesignTemplate(designTemplate)
    }, [designTemplates, currentDesignColorId, currentDesignLayoutId])

    const handleSelectLayoutChange = (event: any) => {
        setCurrentDesignLayoutId(event.target.value)
    }

    const handleSave = () => {
        if (selectedDesignTemplate) {
            UpdateDesignTemplate({
                "designTemplateId": selectedDesignTemplate.id
            })
        }
    }

    const handleSelectColorChange = (event: any) => {
        setCurrentDesignColorId(event.target.value)
    }

    return (
        <>
            {designTemplates && designColors && designLayouts &&
                <Grid container spacing={4}>
                    <Grid container item spacing={0} xs={8} height={100}>
                        <Grid item xs={6}>
                            <FormControl sx={{width: 250}}>
                                <InputLabel id="select-layout">{t('gallery_layout')}</InputLabel>
                                <Select
                                    id="select-layout"
                                    value={currentDesignLayoutId}
                                    label="Gallery Layout"
                                    fullWidth={true}
                                    onChange={handleSelectLayoutChange}
                                >
                                    {designLayouts?.map((a: DesignLayout) => {
                                        return <MenuItem key={a.id} value={a.id}>
                                            <Stack direction='row' alignItems={'center'}>
                                                <img alt={a.name} src={a.imageUrl} width={100}/>
                                                <Box sx={{marginLeft: '10px'}}>{t(a.name)}</Box>
                                            </Stack>
                                        </MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{width: 250}}>
                                <InputLabel id="select-color-palette">{t('template_color_palette')}</InputLabel>
                                <Select
                                    id="select-color-palette"
                                    value={currentDesignColorId}
                                    label="Template Color palette"
                                    fullWidth={true}
                                    onChange={handleSelectColorChange}
                                >
                                    {designColors?.map((a: DesignColor) => {
                                        return <MenuItem key={a.id} value={a.id}>
                                            <Stack direction='row' alignItems={'center'}>
                                                <img alt={a.name} src={a.imageUrl} width={50}/>
                                                <Box sx={{marginLeft: '10px'}}>{t(a.name.split("_")[2])}</Box>
                                            </Stack>
                                        </MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: '20px'}} alignItems={'center'}>
                            <Button variant={'contained'} fullWidth onClick={handleSave} type={'submit'}>{t('save')}</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} alignItems={'center'}>
                        {selectedDesignTemplate != undefined &&
                            <img alt={selectedDesignTemplate.id + " template"} src={selectedDesignTemplate.imageUrl} width="100%"/>}
                    </Grid>

                </Grid>
            }
        </>
    );
}