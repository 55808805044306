import {useMutation, useQueryClient} from 'react-query'
import {postServiceProvidersLogo} from 'api/private/serviceProviders'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

export const useUploadLogo = () => {
  const {t} = useTranslation()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {mutate: UploadLogo} = useMutation(
    payload => postServiceProvidersLogo(defaultProvider?.id, payload),
    {
      onSuccess: () => {
      queryClient.resetQueries(['images', defaultProvider?.id, 'SERVICE_PROVIDER_LOGO'])
        toast.success(`${t('logo_upload_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      },
      onError: () => {
        toast.error(`${t('logo_upload_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )
  return {UploadLogo}
}