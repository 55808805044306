import React, { useState } from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MemberType } from 'types/Members'
import { useDeleteMember } from 'hooks/members/useDeleteMember'
import { EditMemberDetailsDrawer } from 'components/drawers/EditMemberDetailsDrawer'
import { AdminPanelSettingsOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { UpdateMemberRoleDrawer } from 'components/drawers/UpdateMemberRoleDrawer'

type props = {
  index: number
  member: MemberType
}

const MemberTableRow = ({index, member}: props) => {
  const {t} = useTranslation();

  const {deactivateMember} = useDeleteMember()
  const [openEditTable, setOpenEditTable] = useState(false)
  const handleEdit = () => {
    setOpenEditTable(!openEditTable)
  }
  const [openUpdateMemberRole, setOpenUpdateMemberRole] = useState(false)
  const handleUpdateRole = () => {
    setOpenUpdateMemberRole(!openUpdateMemberRole)
  }
  return (
    <TableRow key={index}>
      <TableCell>{member.firstName} {member.lastName} </TableCell>
      <TableCell>{member.email}</TableCell>
      <TableCell>{t(member.memberRole)}</TableCell>
      <TableCell padding={'checkbox'}>
        <Tooltip title={`${t('update')}`} placement="top" onClick={handleEdit}>
          <IconButton disabled={member?.memberRole == 'OWNER'}>
            <EditOutlined/>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell padding={'checkbox'}>
        <Tooltip title={`${t('update_role')}`} placement="top" onClick={handleUpdateRole}>
          <IconButton disabled={member?.memberRole == 'OWNER'}>
            <AdminPanelSettingsOutlined/>
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell padding={'checkbox'}>
        <Tooltip title={`${t('delete')}`} placement="top" onClick={() => deactivateMember(member.id)}>
          <IconButton disabled={member?.memberRole == 'OWNER'} color={'error'}>
            <DeleteOutlined/>
          </IconButton>
        </Tooltip>
      </TableCell>
        {openEditTable && <EditMemberDetailsDrawer member={member} openEditMember={openEditTable} setOpenEditMember={setOpenEditTable}/>}
        {openUpdateMemberRole && <UpdateMemberRoleDrawer member={member} openUpdateMemberRole={openUpdateMemberRole} setOpenUpdateMemberRole={setOpenUpdateMemberRole}/>}

    </TableRow>
  )
}

export default MemberTableRow