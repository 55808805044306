import React, {Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from 'react'
import {
    Alert,
    Box,
    Button, Grid,
    Grow,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography, useMediaQuery
} from '@mui/material'
import {defaultTimeOptions} from 'config/Options'
import {PluginStyles} from 'theme/Plugin.styles'
import {useTranslation} from 'react-i18next'
import PluginContext from 'plugin/PluginContext'
import {
    PLUGIN_DECREASE_SEATS,
    PLUGIN_INCREASE_SEATS,
    PLUGIN_SELECT_ADDITIONAL_OPTIONS,
    PLUGIN_SELECT_DATE,
    PLUGIN_SELECT_TABLE_TYPE,
    PLUGIN_SELECT_TIME
} from 'plugin/PluginReducer'
import {DatePicker, LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {
    AccessTimeOutlined,
    AddOutlined,
    DateRangeOutlined, PeopleOutlined,
    RemoveOutlined,
    SettingsSuggestOutlined,
    TableRestaurantOutlined
} from '@mui/icons-material'
import {OptionsType} from 'types/General'
import {DATE_FORMAT, DATE_FORMAT_MASK, ISO_DATE_FORMAT, MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS} from 'config/const'
import {useParams} from 'react-router-dom'
import 'moment/locale/de'
import {useSpAvailableTimeslots} from "plugin/useSpAvailableTimeslots";
import {toast} from "react-toastify";
import {useServiceProviderPluginDetails} from "plugin/useServiceProviderPluginDetails";
import {AdditionalOptionsAutocomplete} from "../../components/AdditionalOptionsAutocomplete";
import moment from "moment";

type props = {
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>
}

export const TableDetails = ({setShowErrorMessage}: props) => {
    const {t, i18n} = useTranslation()
    const {bookings, dispatch, mainColor, secondaryColor, iconColor, setPage} = useContext(PluginContext)
    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })
    const {id} = useParams()
    const serviceProviderId = Number(id)
    const {startDate, startTime, seats, tableType, additionalOptions} = bookings.add_plugin_bookings

    const [selectedMonthStart, setSelectedMonthStart] = useState(moment(new Date()).startOf('month'))

    const {ServiceProviderPluginDetails} = useServiceProviderPluginDetails(serviceProviderId, selectedMonthStart, 'WEB')

    const {page} = useContext(PluginContext)

    const isSmallDevice = useMediaQuery("only screen and (max-width : 899px)");

    const handleClick = useCallback(() => {
        if (startTime && startTime.length > 0) {
            setPage({
                guestNumber: false,
                guestInfo: true,
                bookDetails: false,
                bookSuccess: false
            })
        } else {
            toast.error(`${t('available_tables_not_found')}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }, [startTime])

    const {
        SPAvailableTimeslots,
        error
    } = useSpAvailableTimeslots(serviceProviderId, startDate, tableType, seats, additionalOptions)

    const [dynamicTimeOptions, setDynamicTimeOptions] = useState<OptionsType[]>(defaultTimeOptions)

    const [isCalled, setIsCalled] = useState(false)

    function shouldDisableDate<TDate>(date: TDate) {
        if (moment(startDate).isSame(date, 'day')) {
            return false
        }
        if (ServiceProviderPluginDetails) {
            return !ServiceProviderPluginDetails.availableDates.includes(moment(date).format(ISO_DATE_FORMAT));
        }
        return false;
    }


    const handleAdditionalOptionsChange = (data: any) => {
        const wheelchair = data.filter((item: any) => item.value === 'WHEELCHAIR_SUPPORT')
        if (wheelchair.length > 0) {
            const additionalOptions = ['WHEELCHAIR_SUPPORT']
            dispatch({type: PLUGIN_SELECT_ADDITIONAL_OPTIONS, payload: additionalOptions})
        } else {
            dispatch({type: PLUGIN_SELECT_ADDITIONAL_OPTIONS, payload: []})
        }
    }

    useEffect(() => {
        if (ServiceProviderPluginDetails && !isCalled) {
            dispatch({type: PLUGIN_SELECT_DATE, payload: moment(ServiceProviderPluginDetails.availableDates[0])})
            setIsCalled(true)
        }
    }, [ServiceProviderPluginDetails, dispatch, isCalled])

    useEffect(() => {
        setSelectedMonthStart(moment(startDate))
    }, [startDate])

    useEffect(() => {
        if (!error) {
            if (SPAvailableTimeslots && SPAvailableTimeslots.availableTimeslots && SPAvailableTimeslots.availableTimeslots.length > 0) {
                const dynamicOptions: OptionsType[] = []
                SPAvailableTimeslots.availableTimeslots.forEach((item: string) => {
                    dynamicOptions.push({value: item, label: item})
                })
                dispatch({type: PLUGIN_SELECT_TIME, payload: dynamicOptions[0].value})
                setDynamicTimeOptions(dynamicOptions)
            } else {
                dispatch({type: PLUGIN_SELECT_TIME, payload: ''})
                setDynamicTimeOptions([])
            }
        }
    }, [SPAvailableTimeslots, error])

    if (error?.response?.data['errorCode'] === 'TRIAL_EXPIRED' || error?.response?.data['errorCode'] === 'SERVICE_PROVIDER_IS_CLOSED') {
        setShowErrorMessage(true)
        return <Box sx={{textAlign: 'center'}}>
            <Alert severity={'error'} sx={{
                textAlign: 'center',
                width: 303,
                marginTop: 25,
                '& .MuiAlert-message': {textAlign: "center", width: "inherit"}
            }}>
                {t('temporarily_closed')}
            </Alert>
        </Box>
    }

    return (
        <>
            <Grid container spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between">
                <Grid container item direction="row" xs={5.6} sm={2.6} md={2.2} lg={1.6} sx={{margin: "5px"}}>
                    <PeopleOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <IconButton className={classes.horizontalIcon}
                                    onClick={() => seats > MIN_NUMBER_OF_GUESTS && dispatch({type: PLUGIN_DECREASE_SEATS})}>
                            <RemoveOutlined/>
                        </IconButton>
                        <Typography variant={'body1'}>{seats}</Typography>
                        <IconButton className={classes.horizontalIcon}
                                    onClick={() => seats <= MAX_NUMBER_OF_GUESTS && dispatch({type: PLUGIN_INCREASE_SEATS})}>
                            <AddOutlined/>
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid container item direction="row" xs={5.7} sm={4} md={3.1} lg={2.2} sx={{margin: "5px"}}>
                    <TableRestaurantOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Select sx={{ml: "10px", width: 125}} value={tableType} size={'small'} variant={'standard'}
                            onChange={(e) => dispatch({
                                type: PLUGIN_SELECT_TABLE_TYPE,
                                payload: e.target.value
                            })}>
                        <MenuItem value={"INDOOR"}>{t('INDOOR')}</MenuItem>
                        <MenuItem value={"OUTDOOR"}>{t('OUTDOOR')}</MenuItem>
                    </Select>
                </Grid>
                <Grid container item direction="row" xs={5.6} sm={3.5} md={2.9} lg={2.1} sx={{margin: "5px"}}>
                    <DateRangeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Box mt={1} sx={{ml: "10px"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
                            <DatePicker
                                shouldDisableDate={(date) => {
                                    return shouldDisableDate(date);
                                }}
                                disablePast={true}
                                value={startDate}
                                onMonthChange={(month) => {
                                    setSelectedMonthStart(moment(month))
                                }}
                                closeOnSelect={true}
                                inputFormat={DATE_FORMAT}
                                mask={DATE_FORMAT_MASK}
                                onOpen={() => {
                                    setSelectedMonthStart(moment(startDate))
                                }}
                                onClose={() => {
                                    setSelectedMonthStart(moment(startDate))
                                }}
                                onChange={e => {
                                    dispatch({type: PLUGIN_SELECT_DATE, payload: e})
                                }}
                                renderInput={(params) => <TextField {...params} variant={'standard'}
                                                                    style={{width: 115}}
                                                                    size={'small'}/>}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid container item direction="row" xs={5.7} sm={2.6} md={2.1} lg={1.6} sx={{margin: "5px"}}>
                    <AccessTimeOutlined fontSize={'medium'} className={classes.horizontalIcon}/>
                    <Select sx={{ml: "10px", width: 70}} value={startTime} size={'small'} variant={'standard'}
                            onChange={(e) => {
                                dispatch({type: PLUGIN_SELECT_TIME, payload: e.target.value})
                            }}>
                        {dynamicTimeOptions.map(item => {
                            return (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                {ServiceProviderPluginDetails && ServiceProviderPluginDetails.additionalOptions && ServiceProviderPluginDetails.additionalOptions.includes('WHEELCHAIR_SUPPORT') &&
                    <Grid container item direction="row" xs={6.5} sm={4} lg={2.3} sx={{margin: "5px"}}
                          alignItems="center">
                        <SettingsSuggestOutlined sx={{mr: "10px"}} fontSize={'medium'}
                                                 className={classes.horizontalIcon}/>
                        <AdditionalOptionsAutocomplete onChange={handleAdditionalOptionsChange}
                                                       additionalOptions={additionalOptions.map((option: any) => (
                                                           {value: option, label: option}
                                                       ))}/>
                    </Grid>
                }
                {(ServiceProviderPluginDetails && ServiceProviderPluginDetails.additionalOptions && ServiceProviderPluginDetails.additionalOptions.includes('WHEELCHAIR_SUPPORT')) &&
                    <Grid container item xs={3} sm={3.5} lg={1} justifyContent={"right"} sx={{margin: "5px"}}>
                        <Button className={classes.submitButton} sx={{
                            width: 100,
                            height: "40px",
                            backgroundColor: `${secondaryColor}`,
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }} onClick={handleClick}>{t('next')}</Button>
                    </Grid>
                }
                {!(ServiceProviderPluginDetails && ServiceProviderPluginDetails.additionalOptions && ServiceProviderPluginDetails.additionalOptions.includes('WHEELCHAIR_SUPPORT')) &&
                    <Grid container item xs sm={3.5} md lg={1} justifyContent={"right"} sx={{margin: "5px"}}>
                        <Button className={classes.submitButton} sx={{
                            width: 100,
                            height: "40px",
                            backgroundColor: `${secondaryColor}`,
                            color: 'white !important',
                            transition: 'all .4s ease !important',
                            '&:hover': { // changes colors for button hover state
                                opacity: '0.7 !important',
                                backgroundColor: `${secondaryColor}`
                            }
                        }} onClick={handleClick}>{t('next')}</Button>
                    </Grid>
                }
            </Grid>
        </>
    )
}
