import React from "react"
import {Outlet} from 'react-router-dom'
// @ts-ignore
import logo from '../../logo.svg'
import 'react-toastify/dist/ReactToastify.min.css';
import {Header} from "./Header";
import {Footer} from "./Footer";


export const CustomerContainer = () => {

    return <>
            <Header/>
            <Outlet/>
            <Footer/>
    </>


}