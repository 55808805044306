import React from "react"
import {Grid, Typography} from '@mui/material'
import { useTranslation } from 'react-i18next'

type props = {
  mainText: string
  secondaryText: string
  icon?: string
}

export const StatisticsWidget = ({mainText, secondaryText, icon = ''}: props) => {

  const {t} = useTranslation()
  return (
    <>
      <Grid item xs={4} paddingTop={1} paddingBottom={1}>
        <Grid item xs={12} p={3} sx={{backgroundColor: 'white', height: '100%'}}>
        <Typography>
          {t(mainText)}
        </Typography>
        <Typography variant={'h3'}>
          {t(secondaryText)}
        </Typography>
        </Grid>
      </Grid>
    </>
  )
}