import React, { useMemo } from "react"
import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { MemberType, UpdatePasswordType } from 'types/Members'
import { updatePasswordSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useUpdatePassword } from 'hooks/members/useUpdatePassword'


type props = {
  openUpdatePassword: any
  setOpenUpdatePassword: any
  member: MemberType | undefined
}

export const UpdatePasswordDrawer = ({openUpdatePassword, setOpenUpdatePassword, member}: props) => {
  const {t} = useTranslation();
  const schema = useMemo(() => updatePasswordSchema(), [i18n.language]);

  const {UpdatePassword} = useUpdatePassword()
  const {handleSubmit, control, reset} = useForm<UpdatePasswordType>({defaultValues: {}, resolver: yupResolver(schema)})
  const toggleDrawer = () => {
    reset()
    setOpenUpdatePassword(!openUpdatePassword)
  }

  const submitHandler = (values: UpdatePasswordType) => {
    UpdatePassword({...values, id: member?.id})
    reset()
    setOpenUpdatePassword(!openUpdatePassword)
  }

  return (
    <Drawer open={openUpdatePassword} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h6'}>{t('update_password')}</Typography>
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} name={'currentPassword'} label={'current_password'} type="password" autoComplete={false}/>
            <TextFieldController control={control} name={'password'} label={'new_password'} type="password" autoComplete={false}/>
            <TextFieldController control={control} name={'confirmedPassword'} label={'repeat_new_password'} type="password" autoComplete={false}/>

            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('update')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>
  )
}