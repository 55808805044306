import {useMutation} from 'react-query'
import {postExternalBooking} from 'api/public/publicapi'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'
import {useContext} from 'react'
import PluginContext from 'plugin/PluginContext'
import {useParams} from 'react-router-dom'
import {CreateBookingTypeUnauthorized} from "../types/Bookings";

export const usePluginCreateBooking = () => {
  const {t} = useTranslation()
  const {id} = useParams()
  const serviceProviderId = Number(id)
  const {setPage} = useContext(PluginContext)

  const {mutate: AddBooking} = useMutation((payload: CreateBookingTypeUnauthorized) => (postExternalBooking(serviceProviderId, payload)),
    {
      onSuccess: async () => {
        setPage({
          guestNumber: false,
          guestInfo: false,
          bookDetails: false,
          bookSuccess: true
        })

      },
      onError: async () => {
        toast.error(`${t('booking_created_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {AddBooking}
}