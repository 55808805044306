import { Button, Popover } from '@mui/material'
import { TwitterPicker } from 'react-color'
import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography'
import {useTranslation} from "react-i18next";


type props = {
  color: string
  setColor: any
  label: string
}

export const ColorPickerField = ({color, setColor, label}: props) => {
  const {t} = useTranslation()

  const handleChange = (color:any) => {
    setColor(color.hex)
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? uuidv4() : undefined;

  function pickTextColorBasedOnBgColorSimple(bgColor: string) {

    const lightColor = '#FFFFFF'
    const darkColor = '#000000'
    if (!bgColor) return lightColor
    const  color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    const  r = parseInt(color.substring(0, 2), 16); // hexToR
    const  g = parseInt(color.substring(2, 4), 16); // hexToG
    const  b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  }


  return (
    <>
      <Button aria-describedby={id} style={{width: 300, backgroundColor: `${color}`}} variant="contained" onClick={handleClick}>
        <Typography color={`${pickTextColorBasedOnBgColorSimple(color)}`}>{t(label)}: {color}</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TwitterPicker triangle={'hide'} color={color} colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']} onChange={handleChange}/>
      </Popover>
    </>
  )
}