import React from 'react'
import {MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ServiceProviderBillingCount} from "../../types/ServiceProvider";
import { v4 as uuidv4 } from 'uuid';
import {getTooltipByChannel} from "../ToolTipByChannel";
import {EURO_FORMATTER} from "../../config/Utils";


type props = {
  serviceProvidersBillingCount?: ServiceProviderBillingCount[]
}
export const ServiceProvidersBillingTableWithChannels = ({serviceProvidersBillingCount}: props) => {

  const {t} = useTranslation()

  function getTableRowPerChannelCount(serviceProviderBillingCount: ServiceProviderBillingCount) {
    return serviceProviderBillingCount.bookingData.map((item, index) => (
        <TableRow key={uuidv4()}>
          <TableCell>
            {serviceProviderBillingCount.serviceProviderName}
          </TableCell>
          <TableCell>{getTooltipByChannel(item.bookingChannelName)}</TableCell>
          <TableCell>{item.bookingCount}</TableCell>
          <TableCell>{item.cost == 0 ? "" : EURO_FORMATTER.format(item.cost)}</TableCell>
        </TableRow>
    ));
  }

  return (
      <>
        <TableContainer>
          <Table>
            <TableHead sx={{backgroundColor: '#F8F8F8'}}>
              <TableRow>
                <TableCell>{t('service_provider_name')}</TableCell>
                <TableCell>{t('channel')}</TableCell>
                <TableCell>{t('booking_count')}</TableCell>
                <TableCell>{t('amount')}</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceProvidersBillingCount?.map(item => (
                getTableRowPerChannelCount(item)
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>

  )
}