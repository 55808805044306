import {useQuery} from 'react-query'
import {getDesignColors} from 'api/private/serviceProviders'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {DesignColor} from "../../types/ServiceProvider";


export const useDesignColors = () => {
    const {serviceProvider} = useServiceProvider()

    const {data: designColors} = useQuery<DesignColor[]>(['designColors'],
    async () => await getDesignColors(),
    {
      enabled: !!serviceProvider?.id,
      onSuccess: (data => {
        return data
      })
    }
  )

  return {designColors}
}