import React, {useContext} from 'react'
import {Box, Grid, Grow, Typography} from '@mui/material'
import PluginContext from 'plugin/PluginContext'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useTranslation} from 'react-i18next'

export const BookSuccess = () => {

    const {page} = useContext(PluginContext)
    const {t} = useTranslation()


    return (
        <>,
            <Grid container direction={'column'} justifyContent={'center'} sx={{color: 'green', fontSize: 18}}
                  alignItems={'center'}>
                <CheckCircleOutlineIcon sx={{fontSize: 40}}/>
                <Typography /*variant={'h5'}*/ textAlign={'center'}>{t('your_booking_was_successful')}</Typography>
            </Grid>
        </>
    )
}
