import React, { useContext, useMemo } from "react"
import {Alert, Button, Container, Divider, Stack, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { CreateWalkInType } from 'types/Bookings'
import BookingContext from 'context/bookings/BookingContext'
import moment from 'moment/moment'
import { walkInAddFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useCreateWalkIn } from 'hooks/bookings/useCreateWalkIn'
import {DATE_TIME_FORMAT, ISO_DATE_TIME_FORMAT} from 'config/const'


export const AddWalkInDrawer = ({openWalkIn, setOpenWalkIn, table}: any) => {
  const {t} = useTranslation();

  const {AddWalkIn} = useCreateWalkIn()
  const schema = useMemo(() => walkInAddFormSchema(), [i18n.language]);

  const {handleSubmit, control, reset} = useForm({defaultValues: table, resolver: yupResolver(schema)})

  const walkInDateTimeMoment = moment(new Date())

  const toggleDrawer = () => {
    reset()
    setOpenWalkIn(false)
  }

  const submitHandler = (values: CreateWalkInType) => {
    AddWalkIn({
      tableId: table.id,
      bookingTime: walkInDateTimeMoment.format(ISO_DATE_TIME_FORMAT),
      guestName: 'Walk In',
      numberOfGuests: values.numberOfGuests,
      channel: 'WALK_IN'
    })
    setOpenWalkIn(false)
  }

  return (
    <Drawer open={openWalkIn} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h4'}>{`${t('your_walk_in_details')}`}</Typography>
          <Typography variant={'h6'}>
            {table.vip && <span><b>{t('VIP')}</b>&nbsp;</span>}
            {`${t('table')} #${table.number}, ${t('seats').toLowerCase()} ${table.seats}, ${t('date')} ${walkInDateTimeMoment.format(DATE_TIME_FORMAT)}`}
          </Typography>
          {table.outsideOfAvailabilityHours &&
              <Alert sx={{mb: 2}} severity={'info'}>{t('booking_outside_of_availability')}</Alert>
          }
          <Divider variant="fullWidth"/>
          <Stack direction={'column'} my={2}>
            <TextFieldController control={control} name={'numberOfGuests'} type={'number'} label={'number_of_guests'}/>

            <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>


  )
}