import {useMutation, useQueryClient} from 'react-query'
import {createStripeSession} from 'api/private/members'
import {useTranslation} from 'react-i18next'
import {useMember} from "./useMember";

export const useCreateStripeSession = () => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {member} = useMember()
    const {mutate: CreateStripeSession} = useMutation(() => (createStripeSession(member?.id)),
        {
            onSuccess: async (data) => {
                await queryClient.resetQueries(['members']).then(() => {
                    queryClient.resetQueries(['member', member?.id])
                })
                if (data.data.redirectUrl) {
                    window.location.href = data.data.redirectUrl
                }
            },
        })

    return {CreateStripeSession}
}