import {useMutation, useQueryClient} from 'react-query'
import {updateAvailability, updateDailyUnavailability} from 'api/private/serviceProviders'
import {useContext} from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import {DailyUnavailabilityType} from 'types/ServiceProvider'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

export const useUpdateDailyUnavailability = () => {
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()
  const {t} = useTranslation()

  const {mutate: UpdateDailyUnavailability} = useMutation((payload: DailyUnavailabilityType) => (updateDailyUnavailability(defaultProvider?.id, payload)), {
    onSuccess: async () => {
      await queryClient.resetQueries(['daily-unavailability'])
      await queryClient.resetQueries(['available-time-slots'])
      toast.success(`${t('daily_unavailability_update_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: async () => {
      toast.error(`${t('daily_unavailability_update_error')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdateDailyUnavailability}
}