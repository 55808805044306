import React from 'react'

export const TourDefaultStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#242B43',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    zIndex: 10000,
  },
  buttonNext: {
    fontFamily: 'Montserrat',
    backgroundColor: '#85E6CC',
    border: 0,
    borderRadius: 4,
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '6px 16px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
  },
  buttonSkip: {
    fontFamily: 'Montserrat',
    borderRadius: 4,
    color: '#FF6a63',
  },
  buttonBack: {
    fontFamily: 'Montserrat',
  }
}