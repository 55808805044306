import { makeStyles } from '@mui/styles'

export const resetForgottenPasswordStyles = makeStyles({
  resetForgottenPasswordWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  resetForgottenPasswordText: {
    letterSpacing: '2.4px',
  },
  textField: {
    backgroundColor: '#F7F7F7',
  },
  resetForgottenPasswordButton: {
    marginTop: '10px !important' as "marginTop",
    borderRadius: '4px',
    height: '50px',
    fontSize: '16px !important' as "fontSize"
  },
  formWrapper: {
    width: 440
  }
})
