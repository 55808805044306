import {useQuery} from 'react-query'
import {getAvailabilityTimeslots} from "../api/private/serviceProviders";


export const useAvailableTimeslots = (serviceProviderId: number | undefined) => {

  const {data: AvailableTimeslots, refetch, error} = useQuery<any, any>(['available-time-slots', serviceProviderId],
    async () => await getAvailabilityTimeslots(serviceProviderId),
    {
      enabled: !!serviceProviderId,
      onSuccess: (data => {
        return data
      })
    }
  )

  return {AvailableTimeslots, error, refetch}
}