import React, { createContext, useReducer } from 'react'
import { INITIAL_PUBLIC_HOLIDAYS_STATE, PublicHolidaysReducer } from 'context/PublicHolidaysReducer'
import { PublicHolidaysAction, PublicHolidaysStateType } from 'types/ServiceProvider'

type PublicHolidaysContextType = {
  publicHolidays: PublicHolidaysStateType
  dispatch: (action: PublicHolidaysAction) => void
}


const PublicHolidaysContext = createContext<PublicHolidaysContextType>({} as PublicHolidaysContextType)

export const PublicHolidays = ({children}: any) => {
  const [publicHolidays, dispatch] = useReducer(PublicHolidaysReducer, INITIAL_PUBLIC_HOLIDAYS_STATE)


  return (
    <PublicHolidaysContext.Provider value={{publicHolidays, dispatch}}>
      {children}
    </PublicHolidaysContext.Provider>
  )
}

export default PublicHolidaysContext