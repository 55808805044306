import { useQuery } from 'react-query'
import { getImage } from 'api/private/serviceProviders'
import { useServiceProvider } from 'hooks/useServiceProvider'
import { ImageType } from 'types/ServiceProvider'


export const useImages = (fileType: string) => {
  const {serviceProvider} = useServiceProvider()

  const {data: images} = useQuery<ImageType[]>(['images', serviceProvider?.id, fileType],
    async () => await getImage(serviceProvider?.id, fileType),
    {
      enabled: !!serviceProvider?.id,
      onSuccess: (data => {
        return data
      })
    }
  )

  return {images}
}