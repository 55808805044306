import React, {useEffect, useMemo, useState} from 'react'
import {Alert, Box, Button, Grid, Tab, Tabs} from '@mui/material'
import {useForm, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {ServiceProviderType} from "../../../types/ServiceProvider";
import {useUpdateServiceProvider} from "../../../hooks/useUpdateServiceProvider";
import CheckboxController from "../../form/CheckboxController";
import {OpeningHours} from "./OpeningHours";
import {DailyUnavailability} from "./DailyUnavailability";
import {TextFieldController} from "../../form/TextFieldController";
import {AutocompleteController} from "../../form/AutocompleteController";
import {OptionsType} from "../../../types/General";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {availabilitySchema} from "../../../config/YupSchemaValidations";
import i18n from "i18next";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AdvanceReservationTimeUnit: OptionsType[] = [
    {value: 'M', label: 'adv_minutes'},
    {value: 'H', label: 'adv_hours'}
]


export const Availability = () => {
    const defaultValues:ServiceProviderType = {
        "id": 0,
        "averageTimePerCustomer": 0,
        "description": "",
        "descriptionEn": "",
        "imageUrl": "",
        "logoUrl": "",
        "name": "",
        "paymentMethodIds": [],
        "phoneNumber": "",
        "pricingRange": 0,
        "registrationNumber": "",
        "websiteUrl": "",
        "designTemplateId": 0,
        "paymentMethods": [],
        "instagramAccount": "",
        "fbAccount": "",
        "bookingEmailsEnabled": false,
        "blockSameDayBookings": false,
        "bookingNotificationEmail": "",
        "tags": [],
        "address": {
            "country": "",
            "state": "",
            "city": "",
            "streetName": "",
            "streetNumber": "",
            "postalCode": ""
        },
        "serviceProviderStatus": "OPEN",
        "wheelchairSupport": false,
        "advanceReservationTime": 0,
        "advanceReservationTimeUnit": "M"
    }
    const {t} = useTranslation()
    const {serviceProvider} = useServiceProvider()

    const schema = useMemo(() => availabilitySchema(), [i18n.language]);
    const serviceProviderWithServiceProviderStatus = useMemo(() => ({
        ...serviceProvider,
        serviceProviderStatus: serviceProvider?.serviceProviderStatus === 'CLOSED'
    }), [serviceProvider])

    const {UpdateServiceProvider} = useUpdateServiceProvider(true)

    const {
        handleSubmit: handleSubmitSPP,
        control: controlSPP,
        reset: resetSPP,
    } = useForm<ServiceProviderType>({defaultValues: defaultValues,
        values: serviceProviderWithServiceProviderStatus as ServiceProviderType,resolver: yupResolver(schema)})
    const submitSPPHandler = (values: ServiceProviderType) => {
        UpdateServiceProvider({
                ...values,
                serviceProviderStatus: values.serviceProviderStatus ? 'CLOSED' : 'OPEN'
            }
        )
    }


    useEffect(() => {
        if (serviceProvider) {
            resetSPP({
                ...serviceProvider,
                serviceProviderStatus: serviceProvider?.serviceProviderStatus === 'CLOSED'
            })
        }
    }, [serviceProvider])


    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <form onSubmit={handleSubmitSPP(submitSPPHandler)}>
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <Alert sx={{mt: 2}} severity={'info'}>{t('advance_reservation_time_info')}</Alert>
                        <Grid container direction={'row'} alignItems={'center'}>
                            <Grid item xs={2}>
                                <TextFieldController type={'number'} control={controlSPP}
                                                     name={'advanceReservationTime'}
                                                     label={t('advance_reservation_time')}/>
                            </Grid>
                            <Grid item xs={2} sx={{ml: 3}}>
                                <AutocompleteController control={controlSPP}
                                                        name={'advanceReservationTimeUnit'}
                                                        label={''}
                                                        options={AdvanceReservationTimeUnit}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid  item>
                        <Alert sx={{mt: 2}} severity={'info'}>{t('block_same_day_bookings_info')}</Alert>
                        <CheckboxController control={controlSPP} name={'blockSameDayBookings'}
                                            label={'block_same_day_bookings_enabled'}/>
                    </Grid>
                    <Grid item>
                        <Alert sx={{mt: 2}} severity={'info'}>{t('close_service_provider_enabled_info')}</Alert>
                        <CheckboxController control={controlSPP} name={'serviceProviderStatus'}
                                            label={'close_service_provider_enabled'}/>
                    </Grid>
                </Grid>
                <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('submit')}</Button>
            </form>

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChange} centered={true} aria-label="availability tabs">
                    <Tab label={t('opening_hours')} {...a11yProps(0)} />
                    <Tab label={t('daily_unavailability')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <OpeningHours value={tabValue} index={0}/>

            <DailyUnavailability value={tabValue} index={1}/>
        </>
    )
}