import { PublicHolidaysAction, PublicHolidaysType } from 'types/ServiceProvider'


export const PUBLIC_HOLIDAYS = 'PUBLIC_HOLIDAYS'


export const INITIAL_PUBLIC_HOLIDAYS_STATE = {
  publicHolidays: [] as PublicHolidaysType[]
}

export const PublicHolidaysReducer = (state = INITIAL_PUBLIC_HOLIDAYS_STATE, action: PublicHolidaysAction) => {
  switch (action.type) {
    case PUBLIC_HOLIDAYS:
      return {
        ...state,
        publicHolidays: action.payload
      }
    default:
      return state;
  }
}