import {useQuery} from 'react-query'
import {getAllPaymentMethods} from "../../api/public/publicapi";
import {PaymentMethod} from "../../types/PaymentMethods";

export const useAllPaymentMethods = () => {

  const {data: AllPaymentMethods} = useQuery<PaymentMethod[]>(['all-paymentmethods'],
    async () => await getAllPaymentMethods(),
    {
      onSuccess: (data => {
        return data
      })
    }
  )
  return {AllPaymentMethods}
}