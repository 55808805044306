import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getJwtToken } from 'config/auth'
import { useMember } from 'hooks/members/useMember'
import { useTranslation } from 'react-i18next'
import { useDateDiff } from 'hooks/helpers/useDateDiff'
import { toast } from 'react-toastify'

export const TrialExpiredRoute = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const auth = getJwtToken() != null
  const {member} = useMember()
  const {dataDiff} = useDateDiff(member?.planExpiryDate)
  useEffect(() => {
    if (auth && dataDiff.isExpired) {
      toast.error(`${t('free_trial_expired')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      navigate('billing')
    }
  }, [auth, member])

  return <Outlet/>
}