import React from 'react';
import { Button, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { useFiles } from 'hooks/serviceProvider/useFiles'
import { DeleteOutline } from '@mui/icons-material'
import CustomFileUploadButton from 'components/network/CustomFileUploadButton'
import useDeleteFile from 'hooks/serviceProvider/useDeleteFile'
import { useTranslation } from 'react-i18next'
import { UploadFileLanguageType } from 'types/ServiceProvider'

type props = {
  lang: UploadFileLanguageType
}

const UploadMenu = ({lang}: props) => {
  const {t} = useTranslation()
  const {file} = useFiles('SERVICE_PROVIDER_MENU', lang)
  const {DeleteFile} = useDeleteFile('SERVICE_PROVIDER_MENU')

  return (
    <CustomFileUploadButton fileType={'SERVICE_PROVIDER_MENU'} lang={lang} label={lang === 'EN' ? 'upload_menu_en' : 'upload_menu_de'}
                            acceptedTypes={'application/pdf'}>
      <List dense>
        <ListItem secondaryAction={
          file && <IconButton edge={'end'} onClick={() => DeleteFile(file?.id)}>
                <DeleteOutline/>
            </IconButton>
        }>
          {file ?
            <ListItemText
              primary={<Button variant={'outlined'} fullWidth size={'small'} style={{color: 'black'}}>{t('preview_menu')}</Button>}
              onClick={() => window.open(file?.fileUrl, '_blank')}
              style={{cursor: 'pointer'}}
            />
            :
            <ListItemText primary={t('menu_not_uploaded')}/>
          }
        </ListItem>
      </List>
    </CustomFileUploadButton>
  );
};

export default UploadMenu;
