import moment from 'moment'
import {ISO_DATE_FORMAT, MIN_NUMBER_OF_GUESTS} from 'config/const'
import {BookingAction, BookingStateType} from 'types/Bookings'
import {getTimeRoundedWith30Minutes} from 'config/Utils'


export const SELECT_DATE_ADD_BOOKINGS = 'SELECT_DATE_ADD_BOOKINGS'
export const SELECT_TIME_ADD_BOOKINGS = 'SELECT_TIME_ADD_BOOKINGS'
export const SELECT_SEATS_ADD_BOOKINGS = 'SELECT_SEATS_ADD_BOOKINGS'
export const SELECT_TABLE_TYPE_ADD_BOOKINGS = 'SELECT_TABLE_TYPE_ADD_BOOKINGS'
export const SELECT_TABLE_VIP_ADD_BOOKINGS = 'SELECT_TABLE_VIP_ADD_BOOKINGS'
export const SELECT_PAGE_ADD_BOOKINGS = 'SELECT_PAGE_ADD_BOOKINGS'
export const SELECT_PAGE_SIZE_ADD_BOOKINGS = 'SELECT_PAGE_SIZE_ADD_BOOKINGS'

export const SELECT_SEATS_ADD_WALK_IN = 'SELECT_SEATS_ADD_WALK_IN'
export const SELECT_TABLE_TYPE_ADD_WALK_IN = 'SELECT_TABLE_TYPE_ADD_WALK_IN'
export const SELECT_TABLE_VIP_ADD_WALK_IN = 'SELECT_TABLE_VIP_ADD_WALK_IN'
export const SELECT_PAGE_WALK_IN = 'SELECT_PAGE_WALK_IN'
export const SELECT_PAGE_SIZE_WALK_IN = 'SELECT_PAGE_SIZE_WALK_IN'

export const SELECT_DATE_CURRENT_BOOKINGS = 'SELECT_DATE_CURRENT_BOOKINGS'
export const SELECT_START_TIME_CURRENT_BOOKINGS = 'SELECT_START_TIME_CURRENT_BOOKINGS'
export const SELECT_END_TIME_CURRENT_BOOKINGS = 'SELECT_END_TIME_CURRENT_BOOKINGS'
export const SELECT_SEATS_CURRENT_BOOKINGS = 'SELECT_SEATS_CURRENT_BOOKINGS'
export const SELECT_STATUS_CURRENT_BOOKINGS = 'SELECT_STATUS_CURRENT_BOOKINGS'
export const SELECT_VIP_CURRENT_BOOKINGS = 'SELECT_VIP_CURRENT_BOOKINGS'
export const SELECT_NAME_CURRENT_BOOKINGS = 'SELECT_NAME_CURRENT_BOOKINGS'
export const SELECT_TABLE_TYPE_CURRENT_BOOKINGS = 'SELECT_TABLE_TYPE_CURRENT_BOOKINGS'
export const SELECT_TABLE_NUMBER_CURRENT_BOOKINGS = 'SELECT_TABLE_NUMBER_CURRENT_BOOKINGS'
export const SELECT_PAGE_CURRENT_BOOKINGS = 'SELECT_PAGE_CURRENT_BOOKINGS'
export const SELECT_PAGE_SIZE_CURRENT_BOOKINGS = 'SELECT_PAGE_SIZE_CURRENT_BOOKINGS'


export const INITIAL_BOOKING_STATE = {
  add_bookings: {
    startDate: moment(new Date()).format(ISO_DATE_FORMAT),
    startTime: getTimeRoundedWith30Minutes(moment(new Date())),
    seats: MIN_NUMBER_OF_GUESTS,
    tableType: '',
    vip: undefined,
    page: 0,
    perPage: 10
  },
  add_walk_in: {
    seats: MIN_NUMBER_OF_GUESTS,
    tableType: '',
    vip: undefined,
    page: 0,
    perPage: 10
  },
  currentBookings: {
    startDate: moment(new Date()).format(ISO_DATE_FORMAT),
    startTime: '00:00',
    endTime: '23:30',
    seats: undefined,
    tableNumber: undefined,
    guestName: '',
    vip: undefined,
    tableType: '',
    status: '',
    page: 0,
    perPage: 10
  }
}

export const BookingsReducer = (state: BookingStateType = INITIAL_BOOKING_STATE, action: BookingAction) => {
  switch (action.type) {
    case SELECT_DATE_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          startDate: action.payload
        }
      }
    case SELECT_TIME_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          startTime: action.payload
        }
      }
    case SELECT_SEATS_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          seats: action.payload
        }
      }
    case SELECT_TABLE_TYPE_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          tableType: action.payload
        }
      }
    case SELECT_TABLE_VIP_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          vip: action.payload
        }
      }
    case SELECT_PAGE_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          page: action.payload
        }
      }
    case SELECT_PAGE_SIZE_ADD_BOOKINGS:
      return {
        ...state,
        add_bookings: {
          ...state.add_bookings,
          perPage: action.payload
        }
      }
    case SELECT_SEATS_ADD_WALK_IN:
      return {
        ...state,
        add_walk_in: {
          ...state.add_walk_in,
          seats: action.payload
        }
      }
    case SELECT_TABLE_TYPE_ADD_WALK_IN:
      return {
        ...state,
        add_walk_in: {
          ...state.add_walk_in,
          tableType: action.payload
        }
      }
    case SELECT_TABLE_VIP_ADD_WALK_IN:
      return {
        ...state,
        add_walk_in: {
          ...state.add_walk_in,
          vip: action.payload
        }
      }
    case SELECT_PAGE_WALK_IN:
      return {
        ...state,
        add_walk_in: {
          ...state.add_walk_in,
          page: action.payload
        }
      }
    case SELECT_PAGE_SIZE_WALK_IN:
      return {
        ...state,
        add_walk_in: {
          ...state.add_walk_in,
          perPage: action.payload
        }
      }
    case SELECT_DATE_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          startDate: action.payload
        }
      }
    case SELECT_START_TIME_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          startTime: action.payload
        }
      }
    case SELECT_END_TIME_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          endTime: action.payload
        }
      }
    case SELECT_SEATS_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          seats: action.payload
        }
      }
    case SELECT_STATUS_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          status: action.payload
        }
      }
      case SELECT_VIP_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          vip: action.payload
        }
      }
    case SELECT_NAME_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          guestName: action.payload
        }
      }
    case SELECT_TABLE_TYPE_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          tableType: action.payload
        }
      }
    case SELECT_TABLE_NUMBER_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          tableNumber: action.payload
        }
      }
    case SELECT_PAGE_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          page: action.payload
        }
      }
    case SELECT_PAGE_SIZE_CURRENT_BOOKINGS:
      return {
        ...state,
        currentBookings: {
          ...state.currentBookings,
          perPage: action.payload
        }
      }
    default:
      return state;
  }
}