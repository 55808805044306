import React, { useContext, useMemo } from "react"
import {Alert, Box, Button, Container, Divider, Stack, Typography} from '@mui/material'
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextFieldController } from 'components/form/TextFieldController'
import { useCreateBooking } from 'hooks/bookings/useCreateBooking'
import {CreateBookingType, DownloadReservationCardsType} from 'types/Bookings'
import BookingContext from 'context/bookings/BookingContext'
import moment from 'moment/moment'
import { bookingAddFormSchema } from 'config/YupSchemaValidations'
import i18n from 'i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import {ISO_DATE_TIME_FORMAT} from "../../config/const";
import {PhoneNumberController} from "../form/PhoneNumberController";
import {UpdateMemberRoleType} from "../../types/Members";
import {AutocompleteController} from "../form/AutocompleteController";
import {memberRoles, printerTypes} from "../../config/Options";
import {useDownloadReservationCards} from "../../hooks/bookings/useDownloadReservationCards";


export const GenerateReservationCardsDrawer = ({openGenerateReservationCards, setOpenGenerateReservationCards, bookings}: any) => {
  const {t} = useTranslation();
  const bookingIds = bookings.map((a: any) => a.id)

  const toggleDrawer = () => {
    setOpenGenerateReservationCards(!openGenerateReservationCards)
  }
  const {handleSubmit, control, reset, formState: {errors}} = useForm<DownloadReservationCardsType>({defaultValues: {bookingIds: bookingIds, type: 'A4'}})

  const {DownloadReservationCards} = useDownloadReservationCards()
  const submitHandler = (values: DownloadReservationCardsType) => {
    DownloadReservationCards(values)
    setOpenGenerateReservationCards(!openGenerateReservationCards)
  }

  return (
    <Drawer open={openGenerateReservationCards} onClose={toggleDrawer}>
      <Container maxWidth={'sm'} sx={{paddingY: 2}}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Typography variant={'h4'}>{`${t('generate_reservation_cards')}`}</Typography>
          <Box my={2}>
            <Typography variant={'subtitle1'}>
              {t('generate_and_download_text')}
            </Typography>
            <Alert severity={'info'}>{t('print_reservation_card_info')}</Alert>
          </Box>
          <Stack direction={'column'} my={2}>
            <AutocompleteController control={control} name={'type'} defaultValue={'A4'} label={'select_printer_type'} options={printerTypes}/>
              <Button variant={'contained'} sx={{mt: 2}} type={'submit'}>{t('generate_and_download')}</Button>
          </Stack>
        </form>

      </Container>
    </Drawer>


  )
}