import { useMutation } from 'react-query'
import { LoginInputType } from 'types/Auth'
import { setJwtToken } from 'config/auth'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { login } from 'api/public/publicapi';
import {hideGoogleRecaptchaBadge} from "../config/Utils";

export const useLogin = () => {
  const navigate = useNavigate()
  const {mutate: Login} = useMutation(
    (payload: LoginInputType) => {
      return login(payload);
    },
    {
      onSuccess: (data) => {
        setJwtToken(data)
        hideGoogleRecaptchaBadge()
        navigate("/")
      },
      onError: () => {
        toast.error("Email/Password incorrect !", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  )

  return {Login}
}