import React from "react"
// @ts-ignore
import logo from '../../logo.svg'
import 'react-toastify/dist/ReactToastify.min.css';
import {Box, Link, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {footerStyles} from "../../theme/FooterStyles";
import Toolbar from "@mui/material/Toolbar";
import i18n from "i18next";

export const Footer = () => {
    const classes = footerStyles()
    const {t} = useTranslation()
    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';
    return <>
        <footer className="footer">
            <Toolbar className={classes.toolBar}>
                <Typography variant={"subtitle2"}>{t('copy_right')}</Typography>

                <Box color="inherit" className={classes.divider}/>

                <Link target="_blank" sx={{textDecoration: 'none'}}
                      href={'/imprint?lang=' + getCurrentLng()}>
                    <Typography variant={"subtitle2"}>{t('imprint')}</Typography>

                </Link>


            </Toolbar>
        </footer>
    </>


}
