import {useQuery} from 'react-query'
import {getServiceProvider} from 'api/private/serviceProviders'
import {useMember} from 'hooks/members/useMember'


export const useServiceProvider = () => {
  const {member} = useMember()
  const {data: serviceProvider, error} = useQuery(['service-provider', member?.defaultServiceProviderId],
    async () => await getServiceProvider(member?.defaultServiceProviderId),
    {
      enabled: !!member && member?.defaultServiceProviderId != undefined,
      select: data => data.data
    }
  )
  return {serviceProvider, error}
}