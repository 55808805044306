import {useQuery} from 'react-query'
import {OpeningHoursType} from 'types/ServiceProvider'
import {getAvailability} from 'api/private/serviceProviders'


export const useOpeningHours = (serviceProviderId: number | undefined) => {

    const {data: OpeningHours} = useQuery<OpeningHoursType>(['opening-hours', serviceProviderId],
        async () => await getAvailability(serviceProviderId),
        {
            enabled: !!serviceProviderId,
            onSuccess: (data => {
                return data
            })
        }
    )

    return {OpeningHours}
}