import { useMutation } from 'react-query'
import { ActivateMemberInputType } from 'types/Auth'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { activateMember } from 'api/public/publicapi';
import { useTranslation } from 'react-i18next'
import React from 'react'

export const useActivateMember = (setShowResetToken: React.Dispatch<React.SetStateAction<boolean>>) => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {mutate: ActivateMember} = useMutation((payload: ActivateMemberInputType) => {
      return activateMember(payload);
    },
    {
      onSuccess: async () => {
        toast.success(`${t('activate_member_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
        navigate("/")
      },
      onError: async (error: any) => {
        if (error?.response?.data['errorCode'] === 'INVALID_RESET_TOKEN') {
          setShowResetToken(true)
          toast.error(`${t('activate_member_error_expired')}`, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(`${t('activate_member_error')}`, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }
    }
  )

  return {ActivateMember}
}