import { makeStyles } from '@mui/styles'

export const forgotPasswordStyles = makeStyles({
  forgotPasswordWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  forgotPasswordText: {
    letterSpacing: '2.4px',
  },
  textField: {
    backgroundColor: '#F7F7F7',
  },
  forgotPasswordButton: {
    marginTop: '10px !important' as "marginTop",
    borderRadius: '4px',
    height: '50px',
    fontSize: '16px !important' as "fontSize"
  },
  formWrapper: {
    width: 440
  }
})
