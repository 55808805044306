import React, {useEffect, useState} from "react"
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material"
import {
    AccessTimeOutlined,
    AddOutlined,
    DateRangeOutlined,
    PeopleOutline,
    PhoneIphoneOutlined,
    PlaceOutlined,
    RemoveOutlined,
    RestaurantOutlined,
    SettingsSuggestOutlined,
    TableRestaurantOutlined
} from "@mui/icons-material";
import {useBookingUnauthorized} from "hooks/bookings/useBookingUnauthorized";
import {useServiceProviderStyles} from "plugin/useServiceProviderStyles";
import {PLUGIN_ICON_COLOR, PLUGIN_MAIN_COLOR, PLUGIN_SECONDARY_COLOR, PluginStyles} from "theme/Plugin.styles";
// @ts-ignore
import logo from 'skiperio-logo.png'
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DATE_FORMAT, DATE_FORMAT_MASK, ISO_DATE_FORMAT, MAX_NUMBER_OF_GUESTS, MIN_NUMBER_OF_GUESTS} from "config/const";
import moment from "moment";
import {getTimeRoundedWith30Minutes, hideGoogleRecaptchaBadgeForPlugin} from "config/Utils";
import {OptionsType} from "types/General";
import {defaultTimeOptions} from "config/Options";
import {SuccessErrorBox} from "./SuccessErrorBox";
import {UseUpdateBookingDetailsUnauthorized} from "hooks/bookings/useUpdateBookingDetailsUnauthorized";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import 'moment/locale/de'
import ReCaptchaV3 from "../components/ReCaptchaV3";
import {BookingUpdateTypeUnauthorized} from "types/Bookings";
import {useSpAvailableTimeslots} from "plugin/useSpAvailableTimeslots";
import {useServiceProviderPluginDetails} from "../plugin/useServiceProviderPluginDetails";
import {AdditionalOptionsAutocomplete} from "components/AdditionalOptionsAutocomplete";

export const UpdateBooking = () => {
    const {t, i18n} = useTranslation()
    const [searchParams] = useSearchParams();
    const {
        booking,
        isSuccess,
        isLoading
    } = useBookingUnauthorized(Number(searchParams.get("id")), searchParams.get("token") as string)

    const {UpdateBookingDetailsUnauthorized, isUpdateBookingSuccess} = UseUpdateBookingDetailsUnauthorized()


    const {serviceProviderStyles} = useServiceProviderStyles(booking?.serviceProviderId)
    const [mainColor, setMainColor] = useState<string>(PLUGIN_MAIN_COLOR)
    const [secondaryColor, setSecondaryColor] = useState<string>(PLUGIN_SECONDARY_COLOR)
    const [iconColor, setIconColor] = useState<string>(PLUGIN_ICON_COLOR)


    const classes = PluginStyles({
        mainColor: `${mainColor}`,
        secondaryColor: `${secondaryColor}`,
        iconColor: `${iconColor}`
    })
    const [values, setValues] = useState<BookingUpdateTypeUnauthorized | null>(null)
    const [numberOfGuests, setNumberOfGuests] = useState<number>(booking ? booking.numberOfGuests : MIN_NUMBER_OF_GUESTS)
    const [tableType, setTableType] = useState<string>(booking ? booking.tableType : "INDOOR")
    const [bookingDate, setBookingDate] = useState(moment(booking?.bookingTime).format(ISO_DATE_FORMAT))
    const [bookingTime, setBookingTime] = useState(getTimeRoundedWith30Minutes(moment(booking?.bookingTime)))
    const [additionalOptions, setAdditionalOptions] = useState<OptionsType[]>([])
    const {
        SPAvailableTimeslots,
        error,
        isSuccess: isSuccessTimeslots
    } = useSpAvailableTimeslots(booking?.serviceProviderId, bookingDate, tableType, numberOfGuests,
        additionalOptions?.map(item => item.value as string))

    const [dynamicTimeOptions, setDynamicTimeOptions] = useState<OptionsType[]>(defaultTimeOptions)

    const [selectedMonthStart, setSelectedMonthStart] = useState(moment(new Date()).startOf('month'))

    const {ServiceProviderPluginDetails} = useServiceProviderPluginDetails(booking?.serviceProviderId, selectedMonthStart,'WEB')

    function shouldDisableDate<TDate>(date: TDate) {
        if (moment(bookingDate).isSame(date, 'day')) {
            return false
        }
        if (ServiceProviderPluginDetails) {
            return !ServiceProviderPluginDetails.availableDates.includes(moment(date).format(ISO_DATE_FORMAT));
        }
        return false;
    }

    useEffect(() => {
        setSelectedMonthStart(moment(bookingDate))
    }, [bookingDate])

    const [isCalled, setIsCalled] = useState(false)

    useEffect(() => {
        if (ServiceProviderPluginDetails && !isCalled) {
            setBookingDate(moment(ServiceProviderPluginDetails.availableDates[0]).format(ISO_DATE_FORMAT))
            setIsCalled(true)
        }
    }, [ServiceProviderPluginDetails, isCalled])


    useEffect(() => {
        hideGoogleRecaptchaBadgeForPlugin()
        if (booking?.id) {
            setNumberOfGuests(booking.numberOfGuests)
            setTableType(booking.tableType)
            setBookingDate(moment(booking.bookingTime).format(ISO_DATE_FORMAT))
            setBookingTime(getTimeRoundedWith30Minutes(moment(booking?.bookingTime)))
            if (booking.wheelchairSupport) {
                setAdditionalOptions([{value: 'WHEELCHAIR_SUPPORT', label: t('wheelchair_support')}])
            } else {
                setAdditionalOptions([])
            }
        }
    }, [booking])

    useEffect(() => {
        if (serviceProviderStyles && serviceProviderStyles.mainColor) {
            setMainColor(serviceProviderStyles.mainColor)
            setSecondaryColor(serviceProviderStyles.secondaryColor)
            setIconColor(serviceProviderStyles.iconColor)
        }
    }, [serviceProviderStyles])

    useEffect(() => {
        const dynamicOptions: OptionsType[] = []
        SPAvailableTimeslots?.availableTimeslots.forEach((item: string) => {
            dynamicOptions.push({value: item, label: item})

        })
        if (!SPAvailableTimeslots?.availableTimeslots.includes(bookingTime)) {
            if (dynamicOptions.length > 0) {
                setBookingTime(dynamicOptions[0].value as string)
            } else {
                setBookingTime('')
            }
        }

        setDynamicTimeOptions(dynamicOptions)
    }, [isSuccessTimeslots])

    const handleAdditionalOptionsChange = (data: any) => {
        const wheelchair = data.filter((item: any) => item.value === 'WHEELCHAIR_SUPPORT')
        if (wheelchair.length > 0) {
            setAdditionalOptions([{value: 'WHEELCHAIR_SUPPORT', label: t('wheelchair_support')}])
        } else {
            setAdditionalOptions([])
        }
    }

    const handleClick = () => {
        if (booking?.id && bookingTime !== '') {
            setValues({
                id: booking?.id,
                bookingTime: `${bookingDate}T${bookingTime}`,
                numberOfGuests: numberOfGuests,
                tableType: tableType,
                additionalOptions: additionalOptions?.map(item => item.value as string),
                token: searchParams.get("token") as string,
                recaptchaToken: ""
            })
        }
    }

    if (error?.response?.data['errorCode'] === 'TRIAL_EXPIRED' || error?.response?.data['errorCode'] === 'SERVICE_PROVIDER_IS_CLOSED') {
        return <>
            <Grid container direction="column" alignItems="center" justifyContent="center" minHeight="100vh"
                  sx={{backgroundColor: "#fbfafd"}}>
                <Box className={classes.mainWrapper}>
                    <Box className={classes.inputWrapper} alignItems={'end'}>
                        <Box sx={{textAlign: 'center'}}>
                            <Alert severity={'error'} sx={{
                                textAlign: 'center',
                                width: 303,
                                marginTop: 30,
                                '& .MuiAlert-message': {textAlign: "center", width: "inherit"}
                            }}>
                                {t('temporarily_closed')}
                            </Alert>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    }

    return (
        <>
            {isLoading &&
                <></>
            }
            {!isLoading && booking?.status == 'ACTIVE' &&
                <Grid container direction="column" alignItems="center" justifyContent="center" minHeight="100vh"
                      sx={{backgroundColor: "#fbfafd"}}>

                    <Box className={classes.mainWrapper} height={630}>
                        <Box className={classes.inputWrapper} height={630} alignItems={'end'}>
                            <Stack direction={'row'}>
                                <Box component="img" alt="Skiper Logo" src={'https://www.portal.skiper.io/textlogo.png'}
                                     className={classes.logo}/>
                            </Stack>
                            {!isUpdateBookingSuccess &&
                                <>
                                    <Stack direction={'column'}>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow color={'primary'}>
                                                    <TableCell/>
                                                    <TableCell/>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <RestaurantOutlined fontSize={'medium'}
                                                                            className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Typography> {booking?.serviceProviderName} </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <PhoneIphoneOutlined fontSize={'medium'}
                                                                             className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Typography>{booking?.serviceProviderPhone}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <PlaceOutlined fontSize={'medium'} className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Typography>{booking?.serviceProviderAddress}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Table size={'small'}>
                                            <TableHead>
                                                <TableRow color={'primary'} sx={{marginBottom: '10px'}}>
                                                    <TableCell/>
                                                    <TableCell/>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <PeopleOutline fontSize={'medium'} className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 200, paddingLeft: 0}}>
                                                        <Typography
                                                            variant={'body2'}><strong>{t('number_of_guests')}</strong></Typography>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Stack direction={'row'} justifyContent={'center'}
                                                               alignItems={'center'}>
                                                            <IconButton className={classes.myIcon}
                                                                        onClick={() => numberOfGuests > MIN_NUMBER_OF_GUESTS && setNumberOfGuests(numberOfGuests - 1)}>
                                                                <RemoveOutlined/>
                                                            </IconButton>
                                                            <Typography variant={'body1'}>{numberOfGuests}</Typography>
                                                            <IconButton className={classes.myIcon}
                                                                        onClick={() => numberOfGuests <= MAX_NUMBER_OF_GUESTS && setNumberOfGuests(numberOfGuests + 1)}>
                                                                <AddOutlined/>
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <TableRestaurantOutlined fontSize={'medium'}
                                                                                 className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 200, paddingLeft: 0}}>
                                                        <Typography
                                                            variant={'body2'}><strong>{t('table_preference')}</strong></Typography>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Select value={tableType} fullWidth size={'small'}
                                                                variant={'standard'}
                                                                onChange={(e) => setTableType(e.target.value)}>
                                                            <MenuItem value={"INDOOR"}>{t('INDOOR')}</MenuItem>
                                                            <MenuItem value={"OUTDOOR"}>{t('OUTDOOR')}</MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                                {ServiceProviderPluginDetails && ServiceProviderPluginDetails.additionalOptions && ServiceProviderPluginDetails.additionalOptions.includes('WHEELCHAIR_SUPPORT') &&
                                                    <TableRow>
                                                        <TableCell
                                                            style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                            <SettingsSuggestOutlined fontSize={'medium'}
                                                                                     className={classes.myIcon}/>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{borderBottom: "none", width: 200, paddingLeft: 0}}>
                                                            <Typography
                                                                variant={'body2'}><strong>{t('additional_options')}</strong></Typography>
                                                        </TableCell>
                                                        <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                            <AdditionalOptionsAutocomplete
                                                                onChange={handleAdditionalOptionsChange}
                                                                additionalOptions={additionalOptions}/>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <DateRangeOutlined fontSize={'medium'}
                                                                           className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 200, paddingLeft: 0}}>
                                                        <Typography
                                                            variant={'body2'}><strong>{t('date')}</strong></Typography>
                                                    </TableCell>
                                                    <TableCell padding={'none'} style={{borderBottom: "none"}}>
                                                        <Box mt={1}>

                                                            <LocalizationProvider dateAdapter={AdapterMoment}
                                                                                  locale={i18n.language}>
                                                                <DatePicker
                                                                    disablePast={true}
                                                                    value={bookingDate}
                                                                    inputFormat={DATE_FORMAT}
                                                                    mask={DATE_FORMAT_MASK}
                                                                    shouldDisableDate={(date) => {
                                                                        return shouldDisableDate(date);
                                                                    }}
                                                                    onMonthChange={(month) => {
                                                                        setSelectedMonthStart(moment(month))
                                                                    }}
                                                                    onOpen={() => {
                                                                        setSelectedMonthStart(moment(bookingDate))
                                                                    }}
                                                                    onClose={() => {
                                                                        setSelectedMonthStart(moment(bookingDate))
                                                                    }}
                                                                    PaperProps={{
                                                                        sx: {marginTop: '-230px', marginLeft: '-180px'}
                                                                    }}
                                                                    onChange={e => {
                                                                        e != null && setBookingDate((moment(e).format(ISO_DATE_FORMAT)))
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params}
                                                                                                        variant={'standard'}
                                                                                                        style={{width: 130}}
                                                                                                        size={'small'}/>}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 10, paddingRight: 0}}>
                                                        <AccessTimeOutlined fontSize={'medium'}
                                                                            className={classes.myIcon}/>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{borderBottom: "none", width: 200, paddingLeft: 0}}>
                                                        <Typography
                                                            variant={'body2'}><strong>{t('time')}</strong></Typography>
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}} style={{borderBottom: "none"}}>
                                                        <Select value={bookingTime} fullWidth size={'small'}
                                                                variant={'standard'}
                                                                onChange={(e) => {
                                                                    setBookingTime(e.target.value)
                                                                }}>
                                                            {dynamicTimeOptions.map(item => {
                                                                return (
                                                                    <MenuItem key={item.value}
                                                                              value={item.value}>{item.label}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Stack>
                                    <Box sx={{marginLeft: '7px', marginTop: '5px'}}><ReCaptchaV3 values={values}
                                                                                                 mutationFN={UpdateBookingDetailsUnauthorized}
                                                                                                 showText={true}/></Box>
                                    <Button className={classes.submitButton} sx={{
                                        width: 80,
                                        backgroundColor: `${secondaryColor}`,
                                        color: 'white !important',
                                        float: 'right',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                        transition: 'all .4s ease !important',
                                        '&:hover': { // changes colors for button hover state
                                            opacity: '0.7 !important',
                                            backgroundColor: `${secondaryColor}`
                                        }
                                    }} onClick={handleClick}>{t('update')}</Button>
                                </>
                            }
                            {isUpdateBookingSuccess &&
                                <Stack direction={'column'} sx={{paddingTop: 15, color: 'green', fontSize: 18}}
                                       alignItems={'center'}>
                                    <CheckCircleOutlineIcon sx={{fontSize: 40}}/>
                                    <Typography variant={'h5'}
                                                textAlign={'center'}>{t('your_booking_was_successful')}</Typography>
                                </Stack>
                            }
                        </Box>
                    </Box>
                </Grid>
            }
            {!isLoading && booking?.status != 'ACTIVE' &&
                <SuccessErrorBox isSuccess={false} message='booking_successfully_canceled'/>
            }
        </>
    )
}