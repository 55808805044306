import { useQuery } from 'react-query'
import { getRestaurantTables } from 'api/private/restaurants'
import {RestaurantTables, RestaurantTablesStateType} from 'types/Restaurant'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import RestaurantTablesContext from 'context/restaurantTables/RestaurantTablesContext'

export const useRestaurantTables = (restaurantTablesFilter: RestaurantTablesStateType) => {

  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {data: restaurantTables, refetch} = useQuery<RestaurantTables>(['restaurants', [defaultServiceProviderId, restaurantTablesFilter]],
    async () => await getRestaurantTables(defaultServiceProviderId, restaurantTablesFilter),
    {
      enabled: !!defaultServiceProviderId
    })

  return {restaurantTables, refetch}
}