import { useMutation, useQueryClient } from 'react-query'
import { putHighlightDishes } from 'api/private/serviceProviders'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'
import { useTranslation } from 'react-i18next'

export const useUpdateHighlightDishes = () => {
  const {t} = useTranslation()
  const {defaultProvider} = useContext(ServiceProviderContext)
  const queryClient = useQueryClient()

  const {mutate: UpdateHighlightDishes} = useMutation((payload: any) => (putHighlightDishes(defaultProvider?.id, payload)), {
    onSuccess: () => {
      queryClient.resetQueries(['highlight-dishes', defaultProvider?.id])
      toast.success(`${t('highlight_dishes_success')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: () => {
      toast.error(`${t('highlight_dishes_error')}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  return {UpdateHighlightDishes}
}