import { useMutation } from 'react-query'
import { ResetForgottenPasswordInputType } from 'types/Auth'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { resetForgottenPassword } from 'api/public/publicapi';
import { useTranslation } from 'react-i18next'
import React from 'react'

export const useResetForgottenPassword = (setShowResetToken: React.Dispatch<React.SetStateAction<boolean>>) => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {mutate: ResetForgottenPassword} = useMutation(
    (payload: ResetForgottenPasswordInputType) => {
      return resetForgottenPassword(payload);
    },
    {
      onSuccess: () => {
        toast.success(`${t('reset_password_success')}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        navigate("/")
      },
      onError: async (error: any) => {
        if (error?.response?.data['errorCode'] === 'INVALID_RESET_TOKEN') {
          setShowResetToken(true)
          toast.error(`${t('reset_password_error_expired')}`, {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(`${t('reset_password_error')}`, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }
    }
  )

  return {ResetForgottenPassword}
}