import {useQuery} from 'react-query'
import {BookingTypeUnauthorized} from "../../types/Bookings";
import {getBookingUnauthorized} from "../../api/public/publicapi";

export const useBookingUnauthorized = (bookingId: number | undefined, token: string | undefined) => {

    const {data: booking, isSuccess, isLoading} = useQuery<BookingTypeUnauthorized>(['booking', bookingId],
        () => getBookingUnauthorized(bookingId, token),
        {
            enabled: !!bookingId && !!token,
        }
    )
    return {booking, isSuccess, isLoading}
}