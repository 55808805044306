import { useMutation, useQueryClient } from 'react-query'
import {CancelBooking, DownloadReservationCardsType} from 'types/Bookings'
import {cancelBooking, downloadReservationCards} from 'api/private/bookings'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import React, { useContext } from 'react'
import ServiceProviderContext from 'context/ServiceProviderContext'

export const useDownloadReservationCards = () => {
  const {t} = useTranslation()
  const {defaultServiceProviderId} = useContext(ServiceProviderContext)

  const {mutate: DownloadReservationCards} = useMutation((payload: DownloadReservationCardsType) => (downloadReservationCards(defaultServiceProviderId, payload)),
    {
      onError: async () => {
        toast.error(`${t('download_reservation_cards_error')}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    })

  return {DownloadReservationCards}
}