import React, {useContext, useEffect, useState} from "react"
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {useTranslation} from 'react-i18next'
import {bookingSeats, bookingsTablesType, defaultTimeOptions, tableVipOptions} from 'config/Options'
import {useRestaurantTablesAvailability} from 'hooks/restaurant/useRestaurantTablesAvailability'
import BookingContext from 'context/bookings/BookingContext'
import {BookingsFilters} from 'components/BookingFilters'
import {
  SELECT_DATE_ADD_BOOKINGS,
  SELECT_PAGE_ADD_BOOKINGS,
  SELECT_PAGE_SIZE_ADD_BOOKINGS,
  SELECT_SEATS_ADD_BOOKINGS,
  SELECT_TABLE_TYPE_ADD_BOOKINGS,
  SELECT_TABLE_VIP_ADD_BOOKINGS,
  SELECT_TIME_ADD_BOOKINGS
} from 'context/bookings/BookingsReducer'
import moment from 'moment'
import {DATE_FORMAT, DATE_FORMAT_MASK, ISO_DATE_FORMAT} from 'config/const'
import {OptionsType} from 'types/General'
import {getDynamicTimeOptionsByDate} from 'config/Utils'
import AddBookingRow from 'components/AddBookingRow'
import {useServiceProvider} from 'hooks/useServiceProvider'
import {useAvailableTimeslots} from 'plugin/useAvailableTimeslots'
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";

export const AddBooking = () => {
  const {t, i18n} = useTranslation();
  const {availableTables, isSuccess} = useRestaurantTablesAvailability()
  const {serviceProvider} = useServiceProvider()

  const {AvailableTimeslots} = useAvailableTimeslots(serviceProvider?.id)
  const {bookings, dispatch} = useContext(BookingContext)
  const {startDate, startTime, seats, tableType, vip} = bookings.add_bookings

  const [dynamicTimeOptions, setDynamicTimeOptions] = useState<OptionsType[]>(defaultTimeOptions)
  const [selectedTimes, setSelectedTimes] = useState<OptionsType>({value: startTime, label: startTime})

  const handlePageChange = (page: number) => {
    dispatch({type: SELECT_PAGE_ADD_BOOKINGS, payload: page})
  }

  const handlePageSizeChange = (size: string) => {
    dispatch({type: SELECT_PAGE_SIZE_ADD_BOOKINGS, payload: Number(size)})
  }

  useEffect(() => {
    if (startDate && AvailableTimeslots) {
      const timeOptions = getDynamicTimeOptionsByDate(startDate, AvailableTimeslots)
      if (timeOptions.length > 0) {
        dispatch({type: SELECT_TIME_ADD_BOOKINGS, payload: timeOptions[0].value})
      }
      setSelectedTimes(timeOptions[0])
      setDynamicTimeOptions(timeOptions)
    }
  }, [startDate, AvailableTimeslots])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.language}>
            <DatePicker
                label={t('date')}
                disablePast={true}
                value={moment(startDate).toDate()}
                inputFormat={DATE_FORMAT}
                mask={DATE_FORMAT_MASK}
                onChange={(newValue) => dispatch({type: SELECT_DATE_ADD_BOOKINGS, payload: moment(newValue).format(ISO_DATE_FORMAT)})}
                renderInput={(params) => <TextField {...params} style={{width: 200}} variant={'outlined'} size={'small'}/>}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item>
          <Box display={'inline-block'} mr={2}>
            {selectedTimes && <Autocomplete
                options={dynamicTimeOptions}
                defaultValue={selectedTimes}
                value={selectedTimes}
                onChange={(_, data) => {
                  dispatch({type: SELECT_TIME_ADD_BOOKINGS, payload: data?.value})
                  setSelectedTimes(data)
                }}
                disableClearable={true}
                renderInput={(params =>
                  <TextField
                    {...params}
                    variant={'outlined'}
                    size={'small'}
                    sx={{width: 200}}
                    label={t(`${'time'}`)}
                    autoComplete={'off'}
                  />)}/>}

          </Box>
        </Grid>

      </Grid>
      <Grid container spacing={2} my={1}>
        <Grid item>
          <BookingsFilters options={bookingSeats} translateLabel={false} defaultValue={seats} type={SELECT_SEATS_ADD_BOOKINGS} label={'minimum_seats'} disableClearable={true}/>
        </Grid>
        <Grid item>
          <BookingsFilters options={bookingsTablesType} translateLabel={true} defaultValue={tableType} type={SELECT_TABLE_TYPE_ADD_BOOKINGS} label={'type'}/>
        </Grid>
        <Grid item sx={{flexGrow: 1}}>
          <BookingsFilters options={tableVipOptions} translateLabel={true} defaultValue={vip} type={SELECT_TABLE_VIP_ADD_BOOKINGS} label={'vip'}/>
        </Grid>
      </Grid>

      <Grid container>
        <Paper sx={{width: '100%', padding: 2}}>
          <Typography variant={'h5'} mb={2}>{t('available_tables')}</Typography>
          <TableContainer>
            <Table>
              <TableHead sx={{backgroundColor: '#F8F8F8'}}>
                <TableRow>
                  <TableCell>{t('table')}</TableCell>
                  <TableCell>{t('seats')}</TableCell>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('remaining_seats')}</TableCell>
                  <TableCell>{t('vip')}</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess && availableTables?.content.map((item, index) => <AddBookingRow key={index} index={index} item={item}/>)}
              </TableBody>
            </Table>
            {availableTables && <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                component="div"
                labelRowsPerPage={t('rows_per_page')}
                count={availableTables?.totalElements}
                rowsPerPage={availableTables ? availableTables.size : 10}
                page={availableTables?.number || 0}
                onPageChange={(event: any, page: number) => handlePageChange(page)}
                onRowsPerPageChange={(event) => handlePageSizeChange(event.target.value)}
            />}
          </TableContainer>
        </Paper>
      </Grid>
    </>

  )
}